import React  from 'react';
import { Card, CardBody } from '@windmill/react-ui';
import {WarningIcon, SevereIcon} from '../../icons'
import $ from 'jquery'


function ErrorsCard(props) {

    const errors = props.errors ? props.errors : []

    function showMessage(id) {
        if($("#"+id).is(":visible")){
            $("#"+id).hide()
        }else{
            $("#"+id).show()
        }
    }

    if (errors.length === 0 ) {
        return (
            <>
                <Card className="mt-4 ">
                    <CardBody className="overflow-auto" style={{ maxHeight: '20rem' }}>
                    <p className="mb-4 font-semibold text-gray-600 dark:text-gray-300">{props.card_title}</p>
                        <div className='text-gray-600 dark:text-gray-300'>
                        <span role="img" aria-label="green check mark">✅</span> No Errors or Warnings
                        </div>
                    </CardBody>
                </Card>
            
            </>
        )
    }
    

    return (
      <>
        <Card className="mt-4 ">
            <CardBody className="overflow-auto" style={{ maxHeight: '20rem' }}>
            <p className="mb-4 font-semibold text-gray-600 dark:text-gray-300">{props.card_title}</p>
                <div className=''>
                    {errors.map((error, i) => (
                        <div  key={i}
                            className={ error.level === 'SEVERE' ? "cursor-pointer p-2 mb-2 text-sm font-semibold rounded-lg shadow-md focus:outline-none text-red-600 dark:text-red-100 bg-red-100 dark:bg-red-600" : "cursor-pointer p-2 mb-2 text-sm font-semibold rounded-lg shadow-md focus:outline-none text-yellow-600 dark:text-yellow-100 bg-yellow-100 dark:bg-yellow-600"}
                            >
                                <div className={"flex items-center justify-between"} onClick={() => showMessage(error.timestamp + i)}>
                                    <div className="flex items-center">
                                        {error.level === 'SEVERE' ? <SevereIcon /> : <WarningIcon />}
                                        <span className="ml-2">Level: {error.level}</span>
                                    </div>
                                    <div className="mr-6">
                                        <span>
                                            Source: {error.source}
                                        </span>
                                    </div>
                                </div>
                                <div style={{ display: 'none' }} id={error.timestamp + i} className={'whitespace-normal mt-2 p-8 cursor-text overflow-auto'}>
                                    {error.message}
                                </div>
                        </div>
                    ))}
                </div>
            </CardBody>
        </Card>
    
      </>
    
  )
}

export default ErrorsCard;