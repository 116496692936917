import React, { useState } from 'react';
import { Input, Button } from '@windmill/react-ui';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import axios from "axios";
import toast from 'react-hot-toast';
import Spinner from '../Buttons/Spinner'
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'




function PasswordModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const password_url = `${BACKEND_URL}/v1/auth/reset-password`

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);



  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      handleAddSite(evt)
    }
  }

  const updatePassword = (evt) => {
    evt.preventDefault();
    setLoading(true)
    var data = {'password': password,}
    axios.post(password_url, data)
      .then((r) => {
          setPassword('')
          setPassword2('')
          setLoading(false)
          closeModal();
          props.callBack && props.callBack();
          toast.success('Password Updated!');
      }).catch((err) => {
          console.log(err)
          toast.error('Sorry, please try a longer password')
          setLoading(false)
      })
}


  return (
    <>

      <div>
        <span className='cursor-pointer text-sm text-blue-600 hover:no-underline underline' onClick={openModal}>Update Password</span>
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Update Password'}>
        <ModalBody>
          <div className='my-4 px-10'>

            <div className="relative text-gray-500 w-full my-6">
              <input
                onKeyDown={handleKeyDown}
                className="overflow-hidden rounded-md p-2 block pr-20 w-full  mt-1 text-sm text-black dark:text-gray-300 dark:border-l-gray-600 dark:bg-gray-700 bg-gray-100 form-input outline-none"
                value={password}
                type={show?'text':"password"}
                onChange={e => setPassword(e.target.value)}
                placeholder='••••••••••••'
              />
              <button onClick={() => {setShow(!show)}} className="z-20 absolute top-2 right-0 px-4 text-sm font-medium leading-5 border border-transparent rounded-r-md active:border-transparent focus:outline-none cursor-pointer">
                {show ?
                  <EyeOffIcon className='h-5 w-5'/>
                  :
                  <EyeIcon className='h-5 w-5'/>
                } 
              </button>
            </div>

            <div className="relative text-gray-500 w-full my-6">
              <input
                onKeyDown={handleKeyDown}
                className="overflow-hidden rounded-md p-2 block pr-20 w-full mt-1 text-sm text-black dark:text-gray-300 dark:border-l-gray-600 dark:bg-gray-700 bg-gray-100 form-input outline-none"
                value={password2}
                type={show?'text':"password"}
                placeholder='••••••••••••'
                onChange={e => setPassword2(e.target.value)}
              />
              <button onClick={() => {setShow(!show)}} className="z-20 absolute top-2 right-0 px-4 text-sm font-medium leading-5 border border-transparent rounded-r-md active:border-transparent focus:outline-none cursor-pointer">
                {show ?
                  <EyeOffIcon className='h-5 w-5'/>
                  :
                  <EyeIcon className='h-5 w-5'/>
                } 
              </button>
            </div>

          </div>
          
          
        </ModalBody>
        <ModalFooter>

          <div className="hidden sm:block">
            <Button 
              layout="outline" 
              onClick={updatePassword} 
              type="submit" 
              disabled={(loading || password !== password2 || password.length < 7)}
            >
            <Spinner display={loading}/>
              Submit
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button 
              block 
              size="large" 
              layout="outline" 
              onClick={updatePassword} 
              type="submit" 
              disabled={(loading || password !== password2 || password.length < 7)}
            >
            <Spinner display={loading}/>
              Submit
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default PasswordModal


