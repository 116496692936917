import React, { useState, useEffect } from 'react';
import { Button, Input } from '@windmill/react-ui';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import { XCircleIcon } from '@heroicons/react/outline'
import toast from 'react-hot-toast';
import axios from "axios";
import store from '../../store';
import Spinner from '../Buttons/Spinner'
import billingSlice from '../../store/slices/billing';




function CancelSubModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const accountUrl = `${BACKEND_URL}/v1/billing/subscription/cancel`
  const infoUrl = `${BACKEND_URL}/v1/billing/info`

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plan, setPlan] = useState(null);
  const [cancel, setCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true)

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }


  useEffect(() => {
    axios.post(infoUrl)
      .then((r) => {
        setPlan(r.data.plan)
        store.dispatch(billingSlice.actions.setBilling(r.data));
        setPageLoading(false)
      }).catch((e) => {
        console.log(e);
        setPageLoading(false)
      });
  },[infoUrl])


  const cancelSub = async () => {
    setIsLoading(true)
    await axios.post(accountUrl)
        .then((r) => {
            store.dispatch(billingSlice.actions.setBilling(r.data));
            toast.success('Billing Canceled')
            setIsLoading(false);
        })
        .then(()=>{
          closeModal()
          props.callBack && props.callBack();
          window.location.reload();
        })
        .catch((err) => {
            console.log(err);
            setIsLoading(false);
            toast.error('Please contact support')
        });
  }

  const ButtonType = () => {
    if(props.type==='icon'){
      return(
        <div>
          <Button onClick={openModal} layout="link" size="icon" aria-label="Cancel Subscription" disabled={plan.name === 'free'}>
            <XCircleIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
        </div>
        
      )
    }else if(props.type==='button'){
      return (
        <Button layout="outline" iconLeft={XCircleIcon} onClick={openModal} className='text-red-600 dark:text-red-600 border-red-600 dark:border-red-600' disabled={plan.name === 'free' || plan.name === 'enterprise'}>
            <span>Cancel Subscription</span>
        </Button>
      )
    } else if(props.type==='dropdown'){
      return (
        <DropdownItem tag="a" onClick={openModal} className="z-40 relative">
          <XCircleIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Cancel Subscription</span>
        </DropdownItem>
      )
    }
  }


  if (pageLoading){
    return null
  }


  return (
    <>

      {ButtonType()}

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Cancel Subscription'}>
        <ModalBody>
          <p>
            Are you sure you want to cancel your subscription? 
            This will revet your account back to a free plan and 
            permenantly <span className='font-bold'>DELETE </span> 
            all of your Sites and associated data.
          </p>
          <div className='mt-4'>
            <Input 
              className='form-input rounded'
              placeholder='type "cancel" to continue'
              onChange={(e) => {
                if (e.target.value === 'cancel'){
                  setCancel(true)
                } else{
                  setCancel(false)
                }
              }}
            />
          </div>
          
        </ModalBody>
        <ModalFooter>
          <div className="hidden sm:block">
            <Button layout="outline" onClick={cancelSub} disabled={isLoading || !cancel}>
              <Spinner display={isLoading}/>
              Confirm Cancel
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={cancelSub} disabled={isLoading || !cancel}>
              <Spinner display={isLoading}/>
              Confirm Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CancelSubModal
