import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { Label, Input, Button } from '@windmill/react-ui'
import axios from "axios";
import Spinner from '../components/Buttons/Spinner'






function ResetPassword() {


  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState('none');
  const [fail, setFail] = useState('none');
  const [failTwo, setFailTwo] = useState('none');
  const [form, setForm] = useState('block');
  const [title, setTitle] = useState('block');
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = `${BACKEND_URL}/v1/auth/reset-password`
  const payload = {'password': password}
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token')
  const headers = {'Authorization': `Bearer ${token}`}


  const handleSubmit = (evt) => {
      evt.preventDefault();
      if (password.length >= 8) {
        setIsLoading(true)
        axios.post(`${url}`, payload, {headers: headers, skipAuthRefresh: true })
        .then((res) => {
          setForm('none');
          setTitle('none');
          setSuccess('block');
        }).catch((err) => {
          if (err.response.status === 417) {
            setFailTwo('block')
            setIsLoading(false)
          } else {
            setForm('none');
            setTitle('none');
            setFail('block')
            setIsLoading(false)
          }
        });
      } else { 
        setFailTwo('block');
      }
      
    }


  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-100 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-md mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto">

          <main className="flex items-center justify-center p-6 sm:p-12">
            <div className="w-full">
              <h1 id='title' style={{display: title  }} className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Reset password
              </h1>

              <form id='password-form' onSubmit={handleSubmit}  style={{display: form  }}>

                <Label>
                  <span>New Password</span>
                  <Input className="rounded-md p-2 mt-1" 
                    type="password"
                    placeholder="••••••••••" 
                    value={password}
                    onChange={e => setPassword(e.target.value)}/>
                </Label>

                <div id="fail-two-message" className="mb-4 text-sm font-semibold text-gray-700 dark:text-gray-200" style={{display: {failTwo} }}>
                  <br></br>
                  Password needs to be at least 8 characters.
                  <br></br>
                </div>


                <Button type="submit" block className="mt-4">
                  <Spinner display={isLoading}/>
                  Reset password
                </Button>

              </form>
              <h3 id='success-message' style={{display: success }} className="mb-4 text-l font-semibold text-gray-700 dark:text-gray-200">
                Sweet, seems like it worked! Now you just need to  <Link
                  className="text text-blue-600 dark:text-blue-400 hover:underline"
                  to="/login">login.
                </Link>
              </h3>
              <h3 id='fail-message' style={{display: fail }} className="mb-4 text-l font-semibold text-gray-700 dark:text-gray-200">
                Opps, seems like that link may be expired <span role='img' aria-label="opps-emoji">😬</span>. Try requesting a new one  <Link
                  className="text text-blue-600 dark:text-blue-400 hover:underline"
                  to="/forgot-password">here.
                </Link>
              </h3>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
