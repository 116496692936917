import React from 'react'
import { Card, CardBody } from '@windmill/react-ui'

function InfoCard({ title, value, children: icon, progress }) {
  return (
    <Card>
      {(progress !== null && progress !== undefined)&&

        <div className="w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700">
          <div className="bg-blue-600 h-1 rounded-full" style={{width: `${progress}%`}}></div>
        </div>

      }
      <CardBody className="flex items-center">
        {icon}
        <div>
          <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">{title}</p>
          <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">{value}</p>
        </div>
      </CardBody>
    </Card>
  )
}

export default InfoCard
