import React, {useState, useEffect} from 'react'
import PageTitle from '../components/Typography/PageTitle'
import IssueCard from '../components/Cards/IssueCard';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Loader from '../components/Buttons/Loader';
import BackButton from '../components/Buttons/BackButton';
import DeleteIssueModal from '../components/Modals/DeleteIssueModal';
import store from '../store';
import metricsSlice from '../store/slices/metrics';
import IDCard from '../components/Cards/IDCard';





function IssueDetail() {
    
    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const metricsUrl = `${BACKEND_URL}/v1/ops/metrics/home`
    const issueUrl = `${BACKEND_URL}/v1/ops/issue`;
    const resourceUrl = `${BACKEND_URL}/v1/ops`;
    const [issue, setIssue] = useState(null);
    const [site, setSite] = useState(null);
    const [page, setPage] = useState(null);
    const [width, setWidth] = useState(window.innerWidth)
    const { id } = useParams();
    const navigate = useNavigate();


    // get current issues count
    const getMetrics = async () => {
        await axios.get(metricsUrl)
            .then((r) => {
                store.dispatch(metricsSlice.actions.setMetrics(r.data))
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const loadData = async () => {
        // get issue info
        if (id){
            await axios.get(`${issueUrl}/${id}`)
            .then((r) => {
                setIssue(r.data)
                var _issue = r.data
                axios.get(`${resourceUrl}/${_issue.affected.type}/${_issue.affected.id}`)
                    .then((r) => {
                        if (_issue.affected.type === 'site'){
                            setSite(r.data)
                        } else if (_issue.affected.type === 'page'){
                            setPage(r.data)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
            
                    });
            })
            .catch((err) => {
                console.log(err)
    
            });
        }

        // get metrics
        await getMetrics();
        
    }

    useEffect(() => {
        loadData();
        setWidth(window.innerWidth)
        // eslint-disable-next-line 
    },[])


    const urlLength = () => {
        var text_size = 'text-2xl'
        var len = issue.affected.str.length
        if (len >= 50 ){
          var text_size = 'text-xl'
        }else if (len > 36 && len < 50){
          var text_size = 'text-xl'
        }else if (len < 50){
          var text_size = 'text-2xl'
        }
        return text_size
    }
    


    const navBack = () => {
        window.location.href = '/issue'
    }


    if (issue === null) {
        return <Loader item={'Issue'}/>
    }
    //${getWidth() < 774 ? 'overflow-auto' : 'overflow-y-clipped'}

    return (
        <>
            <div className='md:flex justify-between'>
                <PageTitle>
                    <div className='flex justify-start '>
                        <div>
                            <span className='text-center px-2 py-1 rounded-full text-[11px] text-orange-700 bg-orange-100 dark:text-white dark:bg-orange-600 font-semibold'>
                                ISSUE
                            </span>
                        </div>
                        <h1 className='font-semibold text-gray-400 dark:text-gray-500 text-[.9rem] ml-3 mt-1'>
                            {new Date(issue.time_created).toLocaleDateString()}
                        </h1>
                    </div>
                    {issue.affected && 
                        <h3 
                            onClick={() => navigate(`/${issue.affected.type}/${issue.affected.id}`)} 
                            className={`hover:underline cursor-pointer max-w-lg ${urlLength()}`}
                        >
                            {issue.affected.str}
                        </h3> 
                    }
                    <div className='mt-1'>
                        <IDCard resource={'issue'} id={issue.id}/>
                    </div>
                    <div className='flex justify-start mt-2'>
                        <div className='md:hidden block -mt-[.3rem]'>
                            <DeleteIssueModal type='icon' issue={issue} callBack={navBack}/>
                        </div>
                    </div>
                </PageTitle> 
                <div className='mt-8 md:block hidden ml-1'>
                    <DeleteIssueModal type='button' issue={issue} callBack={navBack}/>
                </div>
            </div>
            
            <BackButton text={'back to issues'} link={'/issue'}/>
            
            <div className={`mb-8`}>

                <IssueCard callBack={loadData} issue={issue} site={site} page={page}/>
                
            </div>
        </>
    )
}

export default IssueDetail