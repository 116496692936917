import React, { useState, useEffect } from 'react'
import { Button } from '@windmill/react-ui'
import ChartCard from './ChartCard'
import { Line } from 'react-chartjs-2'
import ChartLegend from './ChartLegend'
import axios from 'axios'
import Loader from '../Buttons/Loader'

function LineChart(props) {

    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const [testdata, setTestData] = useState([]);
    const [healthdata, setHealthData] = useState([]);
    const [scandata, setScanData] = useState([]);
    const [testLabels, setTestLabels] = useState([]);
    const [scanLabels, setScanLabels] = useState([]);
    const [showScans, setShowScans] = useState(true);
    const getTestsUrl = `${BACKEND_URL}/v1/ops/test?lean=true&page_id=${props.page.id}`;
    const getScansUrl = `${BACKEND_URL}/v1/ops/scan?lean=true&page_id=${props.page.id}`;


    const getScanData = async () => {
      await axios.get(getScansUrl).then((r) => {
        var healths = r.data.results.map(x => {
          let health = '--'
          if (x.lighthouse.scores.average && x.yellowlab.scores.globalScore) {
            health = (Math.round(((x.lighthouse.scores.average + x.yellowlab.scores.globalScore) / 2)) * 100) / 100
          }else if (x.lighthouse.scores.average) {
            health = (Math.round((x.lighthouse.scores.average)) * 100) / 100
          }else if (x.yellowlab.scores.globalScore) {
            health = (Math.round((x.yellowlab.scores.globalScore)) * 100) / 100
          }
          return health;
        });
        var xlabels = r.data.results.map(x => new Date(x.time_created).toLocaleDateString());
        setScanData(healths.reverse());
        setScanLabels(xlabels.reverse());
      });
    }


    const getTestData = async () => {
      await axios.get(getTestsUrl).then((r) => {
        var tests = r.data.results.map(x =>  ((Math.round(x.score, 2))* 100) / 100);
        var healths = r.data.results.map(x => {
          let health = '--'
          if (x.lighthouse_delta && x.yellowlab_delta) {
            health = (Math.round(((x.lighthouse_delta.scores.current_average + x.yellowlab_delta.scores.current_average) / 2)) * 100) / 100
          }else if (x.lighthouse_delta) {
            health = (Math.round((x.lighthouse_delta.scores.current_average)) * 100) / 100
          }else if (x.yellowlab_delta) {
            health = (Math.round((x.yellowlab_delta.scores.current_average)) * 100) / 100
          }
          return health;
        });
        var xlabels = r.data.results.map(x => new Date(x.time_created).toLocaleDateString());
        setTestData(tests.reverse());
        setHealthData(healths.reverse());
        setTestLabels(xlabels.reverse());
      });
    }


    useEffect(() => {
      getScanData();
      getTestData();
    },[])


    const testLegends = [
      { title: 'Tests', color: 'bg-teal-600' },
      { title: 'Health', color: 'bg-blue-600' },
    ]

    const scanLegends = [
      { title: 'Health', color: 'bg-blue-600' },
    ]

    const testChartData = {
        data: {
          labels: testLabels,
          datasets: [
            {
              label: 'Test Score',
              backgroundColor: '#0694a2',
              borderColor: '#0694a2',
              data: testdata,
              fill: false,
            },
            {
              label: 'Health Score',
              fill: false,
              backgroundColor: '#1c64f2',
              borderColor: '#1c64f2',
              data: healthdata,
            },
          ],
        },
        options: {
          tension: .2,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            mode: 'nearest',
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Date',
              },
            },
            y: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Score',
              },
            },
          },
        },
        
    }


    const scanChartData = {
      data: {
        labels: scanLabels,
        datasets: [
          {
            label: 'Health Score',
            fill: false,
            backgroundColor: '#1c64f2',
            borderColor: '#1c64f2',
            data: scandata,
          },
        ],
      },
      options: {
        tension: .2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          mode: 'nearest',
          intersect: false,
        },
        scales: {
          x: {
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Date',
            },
          },
          y: {
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Score',
            },
          },
        },
      },
      legend: {
        display: false,
      },
    }



      if (scanLabels===null || healthdata===null || scandata===null || testLabels===null){
        return <Loader item={'Scores'}/>
      }

    return (
    <>
        <ChartCard title="Scores Over Time">
          <div className="flex justify-end -mt-10 mb-4">
            <div className="mx-2">
                <Button
                    size="small"
                    layout={showScans ? 'primary' : 'outline'}
                    onClick={() => setShowScans(!showScans)}
                >
                  Scans
                </Button>
            </div>
            <div className="mx-2">
                <Button
                    size="small"
                    layout={showScans ? 'outline' : 'primary'}
                    onClick={() => setShowScans(!showScans)}
                >
                  Tests
                </Button>
            </div>
          </div>
          {showScans ?
            <div>
              <Line {...scanChartData} />
              <ChartLegend legends={scanLegends} />
            </div>
            :
            <div>
              <Line {...testChartData} />
              <ChartLegend legends={testLegends} />
            </div>
          }
        </ChartCard>
    </>
    )
}

export default LineChart
