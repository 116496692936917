import './Toggle.style.css';
import React from "react";
import PropTypes from "prop-types";


/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

const Toggle = (props) => {
  function handleKeyPress(e){
    if (e.keyCode !== 32) return;

    e.preventDefault();
    props.onChange(!props.checked)
  }

  return (
    <div className={"toggle-switch" + (props.small ? " small-switch" : "")}>
      <input
        type="checkbox"
        name={props.name}
        className="toggle-switch-checkbox"
        id={props.id}
        checked={props.checked}
        onChange={e => props.onChange(e.target.checked)}
        disabled={props.disabled}
        />
        {props.id ? (
          <label className="toggle-switch-label"
                 htmlFor={props.id}
                 tabIndex={ props.disabled ? -1 : 1 }
                 onKeyDown={ (e) => { handleKeyPress(e) }}>
            <span
              className={
                props.disabled
                  ? "toggle-switch-inner toggle-switch-disabled"
                  : "toggle-switch-inner"
              }
              data-yes={props.optionLabels && props.optionLabels[0]}
              data-no={props.optionLabels && props.optionLabels[1]}
              tabIndex={-1}
            />
            <span
              className={
                props.disabled
                ? "toggle-switch-switch toggle-switch-disabled"
                : "toggle-switch-switch"
              }
              tabIndex={-1}
            />
          </label>
        ) : null}
      </div>
    );
}

export default Toggle;