import React from 'react'


function TestTypesCard(props) {




    return(
    
    <>

        <div className='flex justify-start mt-6'>
            <div className="mr-4">
                <h3 className="text-lg font-semibold text-gray-600 dark:text-gray-300">Types: </h3>
            </div>
            <div className="flex justify-start flex-wrap">
                { props.types &&

                    props.types.map((type, i) => (

                        <div className="mt-1 mr-4" key={i}>
                            <span className={`mt-6 text-xs font-semibold py-1 px-2 rounded-xl text-gray-700 bg-gray-200 dark:text-gray-100 dark:bg-gray-700`}>
                                {type}
                            </span>
                        </div>
                    ))

                }
            </div>

        </div>
        
    </>
    
    
    )
}

export default TestTypesCard