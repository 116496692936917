import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom'

// eslint-disable-next-line
import { Card, CardBody, Button } from '@windmill/react-ui';
import DeletePageModal from "../Modals/DeletePageModal"
import ScheduleModal from "../Modals/ScheduleModal";
import TestModal from "../Modals/TestModal";
import ScanModal from "../Modals/ScanModal";
import Dropdown from '../Buttons/Dropdown/Dropdown';

// eslint-disable-next-line
import {OptionsIcon, ReportIcon} from '../../icons'


function PageActionsCard(props) {

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const navigate = useNavigate()

    function openModal() {
        setIsMenuOpen(true);
    }
    
    function closeModal() {
        setIsMenuOpen(false)
    }

    


    return (
      <>
        <Card >
            <div className='flex justify-between ml-4 mr-4 mt-4'>
                <div>
                    <p className="mb-4 font-semibold text-gray-600 dark:text-gray-300">Actions</p>
                </div>
                <div className="text-center relative">
                    
                    {/* <button
                        className="align-middle rounded-md focus:outline-none focus:shadow-outline-blue text-gray-600 dark:text-gray-400"
                        onClick={(e) => {
                            e.stopPropagation()
                            if(isMenuOpen){
                                closeModal();
                            }else{
                                openModal();
                            }
                        }}
                    >
                        <OptionsIcon className="w-5 h-5 " aria-hidden="true"/>
                    </button> */}
                    {/* <Dropdown
                        classes="-ml-52"
                        isOpen={isMenuOpen}
                        onClose={() => setIsMenuOpen(false)}
                    >
                        <DropdownItem tag="a" onClick={() => {navigate(`/page/${props.page.id}/report`)}} className="z-40 relative">
                            <ReportIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                            <span>Build Report</span>
                        </DropdownItem>
                        
                        <DeletePageModal page={props.page} type='dropdown'/>
                        
                    </Dropdown> */}

                    

                </div>
            </div>
            <CardBody>
                <div className='grid xs:gap-2 gap-6 mb-8 grid-cols-2'>
                    <div>
                        <TestModal type='button' page={props.page}/>
                    </div>
                    <div>
                        <ScanModal type='button' page={props.page}/>
                    </div>
                    <div>
                        <ScheduleModal scope={'page'} object={props.page} type='button' callBack={props.getSchedules}/>
                    </div>
                    <div>
                        {/* <TestcaseModal page={props.page} type='button'/> */}
                        <Button className='w-[9rem]' layout='outline' iconRight={ReportIcon} size='regular' onClick={() => {navigate(`/page/${props.page.id}/report`)}}>
                            <span>Build Report</span>
                        </Button>
                    </div>
                </div>
            </CardBody>
        </Card>
    
      </>
    
  )
}

export default PageActionsCard;