

const ModalBody = (props) => {


    return (
        <div className="p-4 md:p-5 space-y-4 text-left">
            {props.children}
        </div>
    )
}


export default ModalBody