import React, { useState, useEffect } from 'react';
import { Button } from '@windmill/react-ui';
import { SlackIcon } from '../../icons';
import axios from "axios";

function SlackButton(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const post_url = BACKEND_URL + '/v1/auth/slack'

  const [url, setUrl] = useState('');
  const [showBtn, setShowBtn] = useState(true);


  useEffect(() => {
    axios.post(post_url)
    .then((r) => {
      setUrl(r.data.url)
      setShowBtn(false)
    }).catch((e) => {
      setShowBtn(true)
    })
    },[post_url])


  const addSlack = (evt) => {
    evt.preventDefault();
    window.location.replace(url);
  }



  return (
    <>

        <Button layout="outline" onClick={addSlack} iconLeft={SlackIcon} size='regular' disabled={showBtn}>
            <span>Add to Slack</span>
        </Button>

    </>
  )
}

export default SlackButton