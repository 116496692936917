
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MetricsResponse } from "../../types";

type State = {
  metrics: MetricsResponse | null;
};

const initialState: State = { metrics: null };

const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {
    setMetrics(state: State, action: PayloadAction<MetricsResponse>) {
      state.metrics = action.payload;
    },
    setLogout(state: State) {
      state.metrics = null;
    },
    
  },
});

export default metricsSlice;

  