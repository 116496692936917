import React, { useState, useEffect, useCallback } from 'react'
import {Table, TableHeader, TableCell, TableBody, TableRow, TableFooter,
  TableContainer, Badge, Pagination} from '@windmill/react-ui'
import axios from 'axios'
import Loader from '../Buttons/Loader';

const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;

function LogsTable(props) {

  const [logs, setLogs] = useState(props.logs ? props.logs : null)
  const getLogsUrl = `${BACKEND_URL}/v1/ops/log`;

  const getData = useCallback(() => {
    axios.get(getLogsUrl).then((r) => {
      setLogs(r.data);
      props.getDisplayed(r.data.results[0])
    });
    // eslint-disable-next-line
  }, [getLogsUrl])
  
  useEffect(() => {
    getData();
    // eslint-disable-next-line 
  }, [getData]);


  function logDetail(log) { 
    props.getDisplayed({...log})
  }

  // setup pages control for table
  const pageTable = 1

  // pagination setup
  const resultsPerPage = 10

  // pagination change control
  function onPageChangeTable(p) {
    if(p){
      var offSet = (p-1)*5
      var paginatedUrl = `${BACKEND_URL}/v1/ops/log?limit=10&offset=${offSet}`
      axios.get(paginatedUrl)
        .then((r) => {
          setLogs(r.data);
      })
    }
    
  }

  function getCode(code) {
    var badge = 'neutral';
    parseInt(code);
    if(code!==null){
        if (code >= 200 && code <= 299) {
          badge = 'success';
        }else if (code >= 300 && code <= 399) {
          badge = 'primary';
        }else if (code >= 400 && code <= 499) {
          badge = 'warning';
        }else if (code >= 500 && code <= 599) {
          badge = 'danger';
        }
        
    }
    return <Badge type={badge}>{code}</Badge>
  }



  function getDate(log) {
    if(log.time_created){
        var date = new Date(log.time_created).toLocaleDateString();
        var time = new Date(log.time_created).toLocaleTimeString('en-US');
        var formatedDateTime = `${date} ${time}`
        return formatedDateTime
    }else{
        return '––';
    }
    
  }

  if (logs===null){
   return <Loader item={'Logs'}/>
  }


  return (
    <>
      <TableContainer className="mb-8 dark:bg-gray-800 whitespace-nowrap">
        <Table >
          <TableHeader>
            <tr className='dark:bg-gray-800 bg-white'>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Path</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {logs.results.slice((pageTable - 1) * resultsPerPage, pageTable * resultsPerPage).map((log, i) => (
              <TableRow key={i} onClick={() => logDetail(log)} className="hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer">
                <TableCell>
                  {getCode(log.status)}
                </TableCell>
                <TableCell>
                  {log.request_type}
                </TableCell>
                <TableCell>
                  <span className="text-sm">{getDate(log)}</span>
                </TableCell>
                <TableCell>
                  {log.path}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={logs.count}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}




export default LogsTable

