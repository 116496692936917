
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TeamResponse } from "../../types";

type State = {
  team: TeamResponse | null;
};

const initialState: State = { team: null };

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeam(state: State, action: PayloadAction<TeamResponse>) {
      state.team = action.payload;
    },
    setLogout(state: State) {
      state.team = null;
    },
    
  },
});

export default teamSlice;

  