import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { TrashIcon } from '../../icons';
import siteDataSlice from "../../store/slices/siteData";
import axios from "axios";
import toast from 'react-hot-toast';
import store from '../../store';
import { Button } from '@windmill/react-ui';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import Spinner from '../Buttons/Spinner'


function DeleteSiteModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/site/'

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const handleDeleteSite = (evt) => {
    evt.preventDefault();
    setIsLoading(true);

    if (props.ids) {
      axios.post(`${BACKEND_URL}/v1/ops/sites/delete`, {'ids': props.ids})
        .then(() => {
          refreshData();
          if (props.callBack){props.callBack()}
        }).catch((e) => {
          if (e.response) {
            toast.error(e.response.data.reason)
            setIsLoading(false);
          }
        });

    }else{
      axios.delete(`${url}${props.site.id}`)
        .then(() => {
          refreshData();
        }).catch((e) => {
          if (e.response) {
            toast.error(e.response.data.reason)
            setIsLoading(false);
          }
        });
    }
  }

  const refreshData = () => {
    setIsLoading(false);
    setIsModalOpen(false);
    axios.get(`${BACKEND_URL}/v1/ops/site`)
      .then((r) => {
          store.dispatch(siteDataSlice.actions.setSiteData(r.data));
      });
      toast.success(`Site${props.ids ? 's' : ''} deleted`);
    if(window.location.pathname!=='/'){
      navigate('/');
    }
  }

  const ButtonType = () => {
    if(props.type==='icon'){
      return(
        <div>
          <Button onClick={openModal} layout="link" size="icon" aria-label="Delete">
            <TrashIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
        </div>
        
      )
    }else if(props.type==='button'){
      return (
        <Button layout="outline" iconRight={TrashIcon} onClick={openModal}>
            <span>Delete Site</span>
        </Button>
      )
    } else if(props.type==='dropdown'){
      return (
        <DropdownItem tag="a" onClick={openModal} className="z-40 relative">
          <TrashIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Delete Site</span>
        </DropdownItem>
      )
    }
  }



  return (
    <>

      <div>{ButtonType()}</div>

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Delete Site'}>
        <ModalBody>

            {props.site ? 
              <p>Are you sure you want to permanently delete "{props.site.site_url}" from your account?</p>
            :
              <p>Are you sure you want to permanently delete {props.ids.length} sites from your account?</p>
            }

        </ModalBody>
        <ModalFooter>

          <div className="hidden sm:block">
            <Button layout="outline" onClick={handleDeleteSite} disabled={isLoading}>
              <Spinner display={isLoading}/>
              Confirm Delete
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={handleDeleteSite} disabled={isLoading}>
            <Spinner display={isLoading}/>
              Confirm Delete
            </Button>
          </div>
        
        </ModalFooter>
      </Modal>
    </>
  )
}

export default DeleteSiteModal
