

const Dropdown = (props) => {

    return (
        <div className={`${props.isOpen ? '' : 'hidden'} z-[44] absolute ${props.classes}`}>
            <ul className="ring-1 ring-gray-100 dark:ring-0 shadow-lg w-56 rounded-lg bg-white dark:bg-gray-700 z-40 absolute mt-1 px-2 py-2 text-sm text-gray-700 dark:text-gray-200">
                {props.children}
            </ul>
        </div>
    )
}

export default Dropdown