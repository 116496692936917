import React from "react";
import { Routes, Route, Navigate } from 'react-router';
import { useSelector } from "react-redux";

const ProtectedRoute = (props) => {
  const auth = useSelector((state) => state.auth);

  console.log('protected')

  if (auth.account) {
    if (props.path === "/login") {
      return <Navigate to={"/"} />;
    }
    return <Routes><Route {...props}/></Routes>;
  } else if (!auth.account) {
    return <Navigate to={"/login"} />;
  } else {
    return <div>Not found</div>;
  }
};

export default ProtectedRoute;