import React, { useState, useEffect } from 'react';
import { Input, Button, Label, Card, CardBody, } from '@windmill/react-ui';
import { ExternalLink } from '../../icons';
import axios from "axios";
import toast from 'react-hot-toast';
import Spinner from '../Buttons/Spinner'



function MigrateCard(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/beta/wordpress/migrate/delay'


  const [proc, setProcess] = useState(props.process ? props.process : null)
  const [login_url, setLoginUrl] = useState('')
  const [admin_url, setAdminUrl] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email_address, setEmailAddress] = useState('')
  const [destination_url, setDestinationUrl] = useState('')
  const [sftp_address, setSftpAddress] = useState('')
  const [dbname, setDbname] = useState('')
  const [sftp_username, setSftpUsername] = useState('')
  const [sftp_password, setSftpPassword] = useState('')
  const [wait_time, setWaitTime] = useState(30)
  const [driver, setDriver] = useState('puppeteer')
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setProcess(props?.process)
    console.log(props.process)
    // eslint-disable-next-line
  }, [props?.process])  



  const handleBeginMigration = (e) => {
    e.preventDefault();
    const data = {
      "login_url": login_url, 
      "admin_url": admin_url, 
      "username": username, 
      "password": password,
      "email_address": email_address,
      "destination_url": destination_url,
      "sftp_address": sftp_address,
      "dbname": dbname,
      "sftp_username": sftp_username,
      "sftp_password": sftp_password,
      "wait_time": wait_time,
      "driver": driver,
      "site_id": props.site
    }

    setIsLoading(true);

    axios.post(`${url}`, data)
    .then((res) => {
      if (res.data) {
        props.callBack();
        setIsLoading(false);
        toast.success('Migration Started');
      }
    })
    .catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    })
  }


  const navToPage = (url) => {
    window.open(
      url, "_blank");
  }




  return (
    <>

      

      <Card >
        <CardBody> 

          <div className={`${proc ?  (proc.time_completed ? 'hidden' : 'block') : 'hidden'} text-center mx-auto`}>
            <h1 className='text-4xl font-bold dark:text-gray-200 text-gray-700 mt-12'>
              {proc && (proc.progress ? proc.progress+'%' : '')}
            </h1>
            <p className='text-sm font-semibold dark:text-gray-200 text-gray-700 mt-2 mb-4'>
             {proc && (proc.progress ? 'Migration Progress' : 'Installing & configuring dependencies...') }
            </p>
            <div className={`flex justify-center mt-8 mb-10 ${proc ?  (proc.info_url ? 'block' : 'hidden') : 'hidden'}`}>
              <h1 
                className='hover:underline cursor-pointer text-xl font-semibold dark:text-gray-200 text-gray-700'
                onClick={() => navToPage(proc.info_url)}
              >
                View Process Live 
              </h1>
              <div 
                className='ml-1 mt-2 text-sm cursor-pointer '
                onClick={() => navToPage(proc.info_url)}
              >
                <ExternalLink className='h-5 w-5 dark:text-gray-200 text-gray-700'/>
              </div>
            </div>
          </div>

          <div className={`grid gap-6 grid-cols-2 overflow-auto mb-12 ${proc ? (proc.time_completed ? 'block' : 'hidden') : ''}`} style={{ maxHeight: '25rem' }}>
            
            <div className='mb-4 mt-6'>
              <Label className="mb-2">Driver</Label>
              <Label radio>
                <Input 
                  type="radio" 
                  value="selenium" 
                  disabled={true}
                  checked={driver === 'selenium' ? true : false} 
                  onChange={(e) => {
                    setDriver(e.target.value)
                  }}
                />
                <span className="ml-2">Selenium</span>
              </Label>
              <Label className="sm:ml-6 ml-2" radio>
                <Input 
                  type="radio" 
                  value="puppeteer" 
                  checked={driver === 'puppeteer' ? true : false} 
                  onChange={(e) => {
                    setDriver(e.target.value)
                  }}
                />
                <span className="ml-2">Puppeteer</span>
              </Label>
            </div>
            <div className='mb-4 mt-6'>
              <Label className="mt-4">
                <span>Wait Time (sec)</span>
                <Input
                  type='number' 
                  onChange={e => setWaitTime(e.target.value)} 
                  value={wait_time} 
                  placeholder={60}/>
              </Label>
            </div>

            <div>
              <Label className="mt-4">
                <span>Login Url</span>
                <Input 
                  type='text'
                  onChange={e => setLoginUrl(e.target.value)} 
                  value={login_url} 
                  placeholder={'https://example.com/wp-login.php'}/>
              </Label>

              <Label className="mt-4">
                <span>Admin Url</span>
                <Input 
                  onChange={e => setAdminUrl(e.target.value)} 
                  value={admin_url} 
                  placeholder={'https://example.com/wp-admin'}/>
              </Label>

            </div>
            <div>

              <Label className="mt-4">
                <span>WP Username</span>
                <Input 
                  type='text'
                  onChange={e => setUsername(e.target.value)} 
                  value={username} 
                  placeholder={'username'}/>
              </Label>
              
              <Label className="mt-4">
                <span>WP Password</span>
                <Input 
                  type='text'
                  onChange={e => setPassword(e.target.value)} 
                  value={password} 
                  placeholder={'••••••••••'}/>
              </Label>

            </div>

            <div>

              <Label className="mt-4">
                <span>Email Address</span>
                <Input 
                  type='email'
                  onChange={e => setEmailAddress(e.target.value)} 
                  value={email_address} 
                  placeholder={'email'}/>
              </Label>
              
              <Label className="mt-4">
                <span>Destination Url</span>
                <Input 
                  type='text'
                  onChange={e => setDestinationUrl(e.target.value)} 
                  value={destination_url} 
                  placeholder={'https://your-app.cloudwaysapps.com'}/>
              </Label>

              <Label className="mt-4">
                <span>SFTP Username</span>
                <Input 
                  type='text'
                  onChange={e => setSftpUsername(e.target.value)} 
                  value={sftp_username} 
                  placeholder={'master_123456'}/>
              </Label>
            </div>

            <div>

              <Label className="mt-4">
                <span>SFTP Address</span>
                <Input 
                  type='text'
                  onChange={e => setSftpAddress(e.target.value)} 
                  value={sftp_address} 
                  placeholder={'12.34.567.89'}/>
              </Label>
              
              <Label className="mt-4">
                <span>Database Name</span>
                <Input 
                  type='text'
                  onChange={e => setDbname(e.target.value)} 
                  value={dbname} 
                  placeholder={'db-pg12345'}/>
              </Label>
              
              <Label className="mt-4">
                <span>SFTP Password</span>
                <Input 
                  type='text'
                  onChange={e => setSftpPassword(e.target.value)} 
                  value={sftp_password} 
                  placeholder={'•••••••••'}/>
              </Label>

            </div>

          </div>

          <div className={`${proc ? (proc.time_completed ? 'block' : 'hidden') : ''}`}>
            <div className="hidden sm:block">
              <Button layout="outline" onClick={handleBeginMigration}>
              <Spinner display={isLoading}/>
                Start Migration
              </Button>
            </div>
            <div className="block w-full sm:hidden">
              <Button block size="large" layout="outline" onClick={handleBeginMigration}>
              <Spinner display={isLoading}/>
                Start Migration
              </Button>
            </div>
          </div>
        
        </CardBody>
        
      

      </Card>
    </>
  )
}

export default MigrateCard


