import PageTitle from '../components/Typography/PageTitle'
import { useNavigate } from 'react-router-dom'; 
import IssueCard from '../components/Cards/IssueCard';
import axios from 'axios'
import BackButton from '../components/Buttons/BackButton';
import store from '../store';
import metricsSlice from '../store/slices/metrics';
import IDCard from '../components/Cards/IDCard'




function IssueCreate() {
    
    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const metricsUrl = `${BACKEND_URL}/v1/ops/metrics/home`

    const navigate = useNavigate();

    // get current issues count
    const getMetrics = async () => {
        await axios.get(metricsUrl)
            .then((r) => {
                store.dispatch(metricsSlice.actions.setMetrics(r.data))
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const callBack = async (id) => {
        // get metrics
        await getMetrics()
            .then(() => {
                // nav to new issue
                if (id){navigate(`/issue/${id}`)}
            })
            .catch(() => {
                console.log(e)
            })
        
    }


    return (
        <>
            <div className='flex justify-between'>
                <PageTitle>
                    <div className="flex">
                        <div>
                            <div className='flex justify-start '>
                                <div>
                                    <span className='text-center px-2 py-1 rounded-full text-[11px] text-orange-700 bg-orange-100 dark:text-white dark:bg-orange-600 font-semibold '>
                                        ISSUE
                                    </span>
                                </div>
                                <h1 className='font-semibold text-gray-400 dark:text-gray-500 text-[.9rem] ml-3 mt-1'>
                                    {new Date().toLocaleDateString()}
                                </h1>
                            </div>
                            <h3 className='hover:underline cursor-pointer'>https://example.com</h3>
                            <div className='mt-1'>
                                <IDCard resource={'issue'} id={'--'}/>
                            </div>
                        </div>
                    </div>
                </PageTitle> 
            </div>
            
            <BackButton text={'back to issues'} link={'/issue'}/>
            
            <div className="mb-8">

                <IssueCard callBack={() => callBack()} issue={null} site={null} page={null}/>
                
            </div>
        </>
    )
}

export default IssueCreate