import React, { useState } from 'react';
import { Input, Button, Label } from '@windmill/react-ui';
import axios from "axios";
import toast from 'react-hot-toast';
import { Copy } from '../../icons';
import Spinner from '../Buttons/Spinner'
import Toggle from '../Toggle/Toggle';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'



function ReTestModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/test'
  const [window_size, setWindowSize] = useState(props.configs ? props.configs.window_size : '1920,1080');
  const [driver, setDriver] = useState(props.configs ? props.configs.driver : 'selenium');
  const [device, setDevice] = useState(props.configs ? props.configs.device : 'desktop')
  const [mask_ids, setMaskIds] = useState(props.configs ? props.configs.mask_ids : '')
  const [interval, setInterval] = useState(props.configs ? props.configs.interval : 5)
  const [min_wait_time, setMinTime] = useState(props.configs ? props.configs.min_wait_time : 10)
  const [max_wait_time, setMaxTime] = useState(props.configs ? props.configs.max_wait_time : 60)
  const [disableAnimations, setDisableAnimation] = useState(props.configs ? props.configs.disable_animations : false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfigs, setShowConfigs] = useState(false)
  const [isLoading, setIsLoading] = useState(false);



  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }


  const formatScore = (score) => {
    var new_score = Math.round(score * 100) / 100
    return new_score
  }


  const handleCreateTest = (evt) => {
    evt.preventDefault();
    
    const configs = {
      "window_size": window_size,
      "driver": driver,
      "device": device,
      "mask_ids": mask_ids,
      "interval": interval,
      "max_wait_time": max_wait_time,
      "min_wait_time": min_wait_time,
      "timeout": 300,
      "disable_animations": disableAnimations
    }

    const data = {
      "site_id": props.test.site,
      "type": ['vrt'],
      "pre_scan": props.test.pre_scan,
      "index": props.image_pair.index,
      "configs": configs,
    }

    setIsLoading(true);

    axios.post(url, data)
    .then((res) => {
      if (res.data) {
        setIsLoading(false);
        setIsModalOpen(false);
        toast.success('Test started');

      }
    })
    .catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    })
  }


  const ButtonType = () => {
    if(props.type==='icon'){
      return(
          <Button onClick={openModal} layout="link" size="icon" aria-label="Test">
            <Copy className="w-5 h-5" aria-hidden="true" />
          </Button>
      )
    }else if(props.type==='button'){
      return (
        <div>
          <Button onClick={openModal} iconRight={Copy} layout='outline'>
            Create Test
          </Button>
        </div>
      )
    }else if(props.type==='text'){
      return (
        <span onClick={openModal} className="cursor-pointer">
            { props.text ? props.text : 'Re-run Test' }
        </span>
      )
    } else if(props.type==='hidden'){
      return (
        <div onClick={openModal} className="w-full h-full"></div>
      )
    }
  }



  return (
    <>

      {ButtonType()}

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Image Info'}>
      
        <ModalBody>
    
          <div className="text-gray-700 dark:text-gray-400 flex justify-evenly cursor-text">
            <div>
              <Label className="text-sm">Image Score:</Label>
              {props.image_pair.score ?
                <h1 className="text-2xl font-bold mt-2">{`${formatScore(props.image_pair.score)} %`}</h1>
                :
                <p className='text-xs mt-2'>Could not compare images due to improper configurations</p>
              }
            </div>
            <div>
              <Label className="text-sm">Image Index:</Label>
              <h1 className="text-2xl font-bold mt-2">{props.image_pair.index}</h1>
            </div>
          </div>

          <div className={`${!showConfigs && 'hidden'}`}>

            <Label className="text-sm font-semibold mt-8">Configurations:</Label>

            <div className='grid gap-6 grid-cols-2'>
              <div className='mb-4 mt-6'>
                <Label className="mb-2">Driver</Label>
                <Label radio>
                  <Input 
                    type="radio" 
                    value="selenium" 
                    checked={driver === 'selenium' ? true : false} 
                    onChange={(e) => {
                      setDriver(e.target.value)
                    }}
                  />
                  <span className="ml-2">Selenium</span>
                </Label>
                <Label className="sm:ml-6 ml-2" radio>
                  <Input 
                    type="radio" 
                    value="puppeteer" 
                    checked={driver === 'puppeteer' ? true : false} 
                    onChange={(e) => {
                      setDriver(e.target.value)
                    }}
                  />
                  <span className="ml-2">Puppeteer</span>
                </Label>
              </div>
              <div className='mb-4 mt-6'>
                <Label className="mb-2">Device</Label>
                <Label radio>
                  <Input 
                    type="radio" 
                    value="desktop" 
                    checked={device === 'desktop' ? true : false} 
                    onChange={(e) => {
                      setDevice(e.target.value)
                    }}
                  />
                  <span className="ml-2">Desktop</span>
                </Label>
                <Label className="sm:ml-6 ml-2" radio>
                  <Input 
                    type="radio" 
                    value="mobile" 
                    checked={device === 'mobile' ? true : false} 
                    onChange={(e) => {
                      setDevice(e.target.value)
                    }}
                  />
                  <span className="ml-2">Mobile</span>
                </Label>
              </div>
            </div>

            <div className='grid gap-6 mb-8 grid-cols-2 ' >
              <div>

                <Label className="mt-4">
                  <span>Interval (sec)</span>
                  <Input 
                    className='rounded-md p-2 '
                    onChange={e => setInterval(e.target.value)} 
                    value={interval}
                    type='number'  
                    placeholder={5}/>
                </Label>

                <Label className="mt-4">
                  <span>Window Size</span>
                  <Input 
                    className='rounded-md p-2 '
                    onChange={e => setWindowSize(e.target.value)} 
                    value={window_size} 
                    placeholder={'1920,1080'}
                    disabled={true}
                    />
                </Label>

              </div>
              <div>

                <Label className="mt-4">
                  <span>Min Wait Time (sec)</span>
                  <Input 
                    className='rounded-md p-2 '
                    onChange={e => setMinTime(e.target.value)} 
                    value={min_wait_time}
                    type='number'  
                    placeholder={10}/>
                </Label>

                <Label className="mt-4">
                <span>Max Wait Time (sec)</span>
                  <Input 
                    className='rounded-md p-2 '
                    onChange={e => setMaxTime(e.target.value)} 
                    value={max_wait_time}
                    type='number'  
                    placeholder={60}/>
                </Label>
              </div>
            </div>

            <div className='flex justify-start'>
              <Label className="mt-4">
                <span>Element ID's to Mask (seperated by commas)</span>
                <Input 
                  className='rounded-md p-2 '
                  onChange={e => setMaskIds(e.target.value)} 
                  value={mask_ids}
                  type='text'  
                  placeholder={'example-id-1, example-id-2'}/>
              </Label>

              <Label className="mt-4 ml-8">
                  <span>Disable Animations</span>
                  <div className='flex justify-center mt-2'>
                    <Toggle id='scan-disable-animations' small checked={disableAnimations} onChange={() => setDisableAnimation(!disableAnimations)} />
                  </div>
              </Label>
            </div>
            

          </div>
          
        
        </ModalBody>
        
        
        
        
        <ModalFooter>

          {showConfigs && 
            <div className="block w-full mb-1">
              <Button block size="large" layout="outline" onClick={handleCreateTest}>
              <Spinner display={isLoading}/>
                {'Re-Test this image'}
              </Button>
            </div>
          }
          
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ReTestModal


