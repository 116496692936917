import React, { useState } from 'react'
import { Button } from '@windmill/react-ui'
import axios from "axios";
import Spinner from '../Buttons/Spinner';
import store from '../../store';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import billingSlice from '../../store/slices/billing';
import toast from 'react-hot-toast';






function BillingOnboarding(props) {


    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const setupUrl = `${BACKEND_URL}/v1/billing/subscription/setup`
    const finishUrl = `${BACKEND_URL}/v1/billing/subscription/complete`
    const infoUrl = `${BACKEND_URL}/v1/billing/info`

    const [cardLoading, setCardLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

   
    const validateCard = () => {
        if (!stripe || !elements) {return;}
        const cardElement = elements.getElement(CardElement);
        cardElement.on('change', function(event) {
            if (event.complete) {
            setCardLoading(false);
            } else if (event.error) {
            setCardLoading(true);
            }
        });
    }

    const getBillingInfo = async () => {
        await axios.post(infoUrl, props.configs)
            .then((r) => {
            store.dispatch(billingSlice.actions.setBilling(r.data));
            }).catch((e) => {
            console.log(e);
            });
    } 
  
    const addBillingMethod = async (event) => {
        
        event.preventDefault();
        setLoading(true);


        if (!stripe || !elements) {return;}
        const cardElement = elements.getElement(CardElement);

        await axios.post(setupUrl, props.setupData, props.configs)
        .then((r) => {
            const client_secret = r.data.client_secret
            stripe.confirmCardPayment(client_secret, {
                payment_method: {
                    card: cardElement,
                }
            }).then((res) => {
                var paymentMethod = res.paymentIntent.payment_method;
                var finishData = {'payment_method': paymentMethod};
                axios.post(finishUrl, finishData, props.configs)
                    .then((r) => {
                        getBillingInfo();
                        setLoading(false);
                        cardElement.clear();
                        setCardLoading(true);
                        toast.success('Billing method added');
                        props.callBack();
                    }).catch((err) => {
                        console.log(err);
                        setLoading(false);
                        toast.error('Please contact support')
                    });
            }).catch((e) => {
                console.log(e);
                setLoading(false);
                toast.error('Please contact support')
            });
        })
        .catch((e) => {
            console.log(e);
            setLoading(false);
        });

    };
    
    
    
    return(

        <form onSubmit={addBillingMethod}>
            <div className='ring-1 ring-gray-700 dark:ring-gray-400 rounded-lg py-2 px-2'>
                <CardElement 
                    onChange={validateCard}
                    options={{
                    style: {
                        base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                        },
                        invalid: {
                        color: '#9e2146',
                        },
                    },
                    }}
                />
            </div>
            <div className='flex justify-end mt-3'>
                <Button layout="outline" size="small" type="submit" disabled={cardLoading || loading}>
                    <Spinner display={loading}/>
                    Submit
                </Button>
            </div>
        </form>
        
    )
}



export default BillingOnboarding