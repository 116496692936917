import React, { useState, useEffect } from 'react';
import { Input, Button, Label, Card, CardBody, } from '@windmill/react-ui';
import { PresentationChartBarIcon, 
  PresentationChartLineIcon, CheckIcon } from '@heroicons/react/outline';
import axios from "axios";
import toast from 'react-hot-toast';
import { BlockPicker } from 'react-color';
import Spinner from '../Buttons/Spinner'
// import { Copy } from '../../icons';

function ReportCard(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/report'

  


  const [report, setReport] = useState(props.report ? props.report : null)
  const [text_color, setTextColor] = useState(props.report ? props.report.info.text_color : '#24262d')
  const [highlight_color, setHighlightColor] = useState(props.report ? props.report.info.highlight_color : '#ffffff')
  const [background_color, setBackgroundColor] = useState(props.report ? props.report.info.background_color : '#e1effd')
  const [report_type, setReportType] = useState(props.report ? props.report.type : ['lighthouse', 'yellowlab']);
  const [isLoading, setIsLoading] = useState(false);
  const [bg, setBg] = useState('none')
  const [text, setText] = useState('none')
  const [high, setHigh] = useState('none')

  useEffect(() => {
    setReport(props?.report)
    // eslint-disable-next-line
  }, [props?.report])  


  const [reportTypes, setReportTypes] = useState([
    {
      value: "lighthouse", 
      name: "Lighthouse Metrics",
      checked: report_type.includes('lighthouse'),
    },
    {
      value: "yellowlab", 
      name: "YellowLab Metrics",
      checked: report_type.includes('yellowlab'),
    },
    // {
    //   value: "crux", 
    //   name: "CRUX",
    //   checked: report_type.includes('crux'),
    // },
  ])


  const getIcon = (value) => {
    let icon = null
    switch(value) {
      case 'lighthouse':
        icon = <PresentationChartBarIcon className='h-6 w-6'/>
        break;
      case 'yellowlab':
        icon = <PresentationChartLineIcon className='h-6 w-6'/>
        break;
    }
    return icon
  }


  const handleReportType = (value) => {
    console.log(value)
    if (report_type.includes(value)){
      var list = [...report_type]
      var newList = list.filter(i => i !== value)
      setReportType([...newList])
    }else{
      setReportType([...report_type, value])
    }
  }

  const handleCreateReport = (e) => {
    e.preventDefault();
    const data = {
      "page_id": props.page_id,
      "report_id": report ? report.id : null,
      "type": report_type,
      "text_color": text_color,
      "background_color": background_color,
      "highlight_color": highlight_color,
    }

    setIsLoading(true);

    axios.post(`${url}`, data)
    .then((r) => {
      if (r.data) {
        props.callBack();
        setIsLoading(false);
        if (r.data.success){
          toast.success(r.data.message);
        } else {
          toast.error(r.data.message);
        }

      }
    })
    .catch((e) => {
      if (e.response) {
        toast.error('Unknown Error')
        setIsLoading(false);
      }
    })
  }




  return (

      <Card >
        <CardBody className="max-h-[18rem]"  //min-h-[23rem]
          onClick={() => {
            if (text==='block'){setText('none')}
            if (bg==='block'){setBg('none')}
            if (high==='block'){setHigh('none')}
          }}
        >        
          <div className="flex justify-between">
            <div>
              <Label className="text-sm font-semibold">Report Type:</Label>
                <div className='flex justify-between'>
                  {reportTypes.map((type, i) => (
                    <div className='mr-4' key={i}>
                      { report_type.includes(type.value) &&
                        <div className={`rounded-full bg-green-300 text-green-700 p-1 relative top-6 w-5 h-5 -mt-5`}>
                          <CheckIcon className='h-3 w-3'/>
                        </div>
                      }
                      <div
                        key={i} 
                        onClick={() => handleReportType(type.value)}
                        className={`cursor-pointer mt-2 h-24 w-20 rounded-lg border-2 mx-auto text-center text-xs flex flex-col justify-evenly ${report_type.includes(type.value) ? 'text-gray-600 dark:text-gray-300 border-blue-600' : 'text-gray-600 dark:text-gray-400 border-gray-600 dark:border-gray-400'}`}
                      >
                        <div className='mx-auto'>{getIcon(type.value)}</div>
                        <span>{type.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
            </div>
            <div className="hidden sm:block p-4">
              <Button layout="outline" onClick={handleCreateReport} disabled={isLoading}>
                <Spinner display={isLoading}/>
                {report ? 'Update' : 'Generate'}
              </Button>
            </div>
              
          </div>
          <Label className="text-sm font-semibold mt-6">Styling:</Label>
          <div className='grid gap-4 mb-8 grid-cols-3' >
            <div className=''>
              <Label className="mt-4 ">
                <span
                  >Font Color</span>
                <Input 
                  className='rounded-md p-2 cursor-pointer'
                  onClick={() => {
                    setText(text === 'block' ? 'none': 'block')
                    setBg('none')
                    setHigh('none')

                  }}
                  onChange={e => setTextColor(e.target.value)} 
                  value={text_color} 
                  placeholder='#000000'
                  name="text_color"/>
                <div style={{ display: text }} className='absolute z-50'>
                  <BlockPicker 
                    onChange={e => setTextColor(e.hex)}
                    color={text_color} 
                  />
                </div>
              </Label>
            </div>
            <div>
              <Label className="mt-4 ">
                <span>Highlight Color</span>
                <Input
                  className='rounded-md p-2 cursor-pointer'
                  onClick={() => {
                    setHigh(high === 'block' ? 'none': 'block')
                    setBg('none')
                    setText('none')

                  }} 
                  onChange={e => setHighlightColor(e.target.value)} 
                  value={highlight_color} 
                  placeholder='#000000'
                  name="highlight_color"/>
                <div style={{ display: high }} className='absolute z-50'>
                  <BlockPicker
                    onChange={e => setHighlightColor(e.hex)}
                    color={highlight_color} 
                  />
                </div>
              </Label>
            </div>
            <div>
              <Label className="mt-4 " 
                >
                  <span>Background Color</span>
                  <Input
                    className='rounded-md p-2 cursor-pointer' 
                    onClick={() => {
                      setBg( bg === 'block' ? 'none': 'block')
                      setText('none')
                      setHigh('none')

                    }} 
                    onChange={e => setBackgroundColor(e.target.value)} 
                    value={background_color} 
                    placeholder='#000000'
                    />
                    <div style={{ display: bg, }} className='absolute z-50'>
                      <BlockPicker
                        onChange={(e) => {
                          setBackgroundColor(e.hex)
                        }}
                        color={background_color}
                      />
                    </div>
                </Label>
            </div>
          </div>

          
        
        </CardBody>
        
      </Card>
  )
}

export default ReportCard


