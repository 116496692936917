import {
  createBrowserRouter, RouterProvider, Routes, Route,
} from "react-router-dom";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ProtectedRoute from "./routes/ProtectedRoute";
import Layout from './containers/Layout';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import GoogleConfirm from './pages/GoogleConfirm';
import NewTeam from './pages/NewTeam';
import Onboard from './pages/Onboard';





// Router singleton created
const router = createBrowserRouter([
  { path: "*", element: <Root /> },
]);


function Root() {
  return (
    
    <Routes>
      <Route exact path="/login" element={<Login/>} />
      <Route exact path="/google-confirm" element={<GoogleConfirm/>} />
      <Route exact path="/register" element={<Register/>} />
      <Route exact path="/forgot-password" element={<ForgotPassword/>} />
      <Route exact path="/reset-password" element={<ResetPassword/>} />
      <Route exact path="/account/new" element={<NewTeam/>}/>
      <Route exact path="/account/join" element={<NewTeam/>}/>
      <Route exact path="/onboard" element={<Onboard/>}/>
      <Route 
        path="/*" 
        element={<ProtectedRoute path="/*" element={<Layout/>} />}
      />
    </Routes>
     
  )
}

// RouterProvider added
function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  );
}

export default App
