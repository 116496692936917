import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import visa from '../../assets/img/cards/visa.png'
import mastercard from '../../assets/img/cards/mastercard.png'
import amex from '../../assets/img/cards/american_express.png'
import discover from '../../assets/img/cards/discover.png'
import citibank from '../../assets/img/cards/citibank.png'








const BillingMethodCard = () => {


    // connecting billing to redux store
    const card = useSelector(state => state.billing.billing.card);

    const [cardIcon, setCardIcon] = useState(null);

    useEffect(() => {
      if (card){
        getCardIcon(card.brand)
      };
    }, [card])


    const getCardIcon = useCallback((brand)=>{
        if (brand==='mastercard'){
          setCardIcon(mastercard);
        }else if(brand==='visa'){
          setCardIcon(visa);
        }else if(brand==='amex'){
          setCardIcon(amex);
        }else if(brand==='discover'){
          setCardIcon(discover);
        }else if(brand==='citibank'){
          setCardIcon(citibank);
        }
      }, []);
    


    return(
        <div className="bg-white rounded-lg dark:bg-gray-800 max-h-40">
            <div className="p-6 sm:p-12">
                <p className="mb-4 font-semibold text-gray-600 dark:text-gray-300 text-lg">Billing Method:</p>
                <div className='flex justify-between'>
                    <div className="flex justify-start">
                        <div>
                            { cardIcon && 
                                <img alt="card-icon" className='w-10 h-10 mr-4'src={cardIcon}></img> 
                            }
                        </div>
                        <div className="my-auto">
                            <p className="text-gray-600 dark:text-gray-300">
                                { card ?  '•••• •••• •••• '+card.last_four : 'No billing info on file'}
                            </p>
                        </div>
                    </div>
                    <div className="my-auto">
                        <p className="text-gray-600 dark:text-gray-300 font-semibold text-sm">
                            { card &&  'expires: '+card.exp_month+' / '+card.exp_year}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default BillingMethodCard