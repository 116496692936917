import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer,persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./slices/auth";
import billingSlice from "./slices/billing";
import siteDataSlice from "./slices/siteData";
import teamSlice from "./slices/team";
import memberSlice from "./slices/member";
import metricsSlice from "./slices/metrics";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  billing: billingSlice.reducer,
  siteData: siteDataSlice.reducer,
  team: teamSlice.reducer,
  member: memberSlice.reducer,
  metrics: metricsSlice.reducer,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    version: 1,
    storage: storage,
  },
  rootReducer
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;

export default store;