import React from 'react';
import ReTestModal from '../Modals/ReTestModal';



function ImageContainer(props) {


    const images = props.images ? props.images : null
    const scan_number = props.scan_number


    return (

            <div className='overflow-hidden'>

                {/* <h1
                    className='text-lg text-center text-gray-600 dark:text-gray-100 mb-8 mt-2'
                >
                    scan_{scan_number} 
                </h1> */}

                {images ? images.map((image_pair, i)=>(

                    <div 
                        key={i}
                        className=" cursor-pointer m-auto text-center"
                        style={{
                            height: `${props.height}px`,
                            maxWidth: `${props.width}px`,
                            backgroundImage: `url(${ scan_number === 1 ? (props.view_type === 'diff' ? image_pair.pre_img_diff.url : image_pair.pre_img.url) : (props.view_type === 'diff' ? image_pair.post_img_diff.url : image_pair.post_img.url)})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: `${props.width}px ${props.height}px`,
                            backgroundPosition: scan_number === 1 ? 'left' : 'right',
                        }} 
                        
                    >
                        <div className="m-auto w-full h-full">
                            <ReTestModal 
                                type='hidden' 
                                configs={props.configs}
                                test={props.test}
                                image_pair={image_pair}
                            />
                        </div>
                        
                    </div>

                    
                )) :

                    <h1 
                        className='text-xl text-center font-semibold text-gray-600 dark:text-gray-100'
                    >
                        No images for this test
                    </h1>  
            
                }
        
    
            </div>
    )

}


export default ImageContainer