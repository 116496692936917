import React, {useState} from 'react'
import LogsTable from '../components/Tables/LogsTable'
import JsonCard from '../components/Cards/JsonCard'
import PageTitle from '../components/Typography/PageTitle'



function Logs() {

    const [displayed, setDisplayed] = useState(null)

    function getDisplayed(log){
        setDisplayed({...log})
    }

    return (
        <>
        <PageTitle>API Logs</PageTitle>
        <div className="grid gap-6 md:grid-cols-2 grid-cols-1">
            <div>
                <LogsTable getDisplayed={getDisplayed}/>
            </div>    
            <div>
                {displayed && <JsonCard card_title="Details" log={displayed}/> }
            </div>

        </div>
        </>
    )
}

export default Logs