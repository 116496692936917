import React, {useState, useEffect} from 'react';
import { Card, CardBody, Button } from '@windmill/react-ui';



function JsonCard(props) {

    const [request, setRequest] = useState('block')
    const [response, setResponse] = useState('none')
    const [reqButton, setReqButton] = useState('primary')
    const [resButton, setResButton] = useState('outline')
    const [log, setLog] = useState(props.log)
    

    useEffect(() => {
        setLog(props?.log)
        // eslint-disable-next-line
    }, [props?.log])



    function getRequest(){
        setRequest('block')
        setResButton('outline')
        setReqButton('primary')
        setResponse('none')
    }

    function getResponse(){
        setRequest('none')
        setResButton('primary')
        setReqButton('outline')
        setResponse('block')
    }

    return (
        <>
        <Card className="">
            <CardBody className="">
                <p className="mb-2 font-semibold text-gray-600 dark:text-gray-300">{props.card_title}</p>
                <div className="flex justify-arround mb-3">
                    <div className="m-4">
                        <Button
                            size="small"
                            layout={reqButton}
                            onClick={() => getRequest()}
                        >
                            Request
                        </Button>
                    </div>
                    <div className="m-4">
                        <Button
                            size="small"
                            layout={resButton}
                            onClick={() => getResponse()}
                        >
                            Response
                        </Button>
                    </div>
                </div>
                <div style={{ display: request }} className="max-h-[58vh] p-4 overflow-auto text-xs whitespace-pre-wrap text-gray-600 dark:text-gray-100">
                    {log ? <pre><code className="text-gray-600 dark:text-gray-100">{JSON.stringify(log.request_payload, null, 2)}</code></pre> : null}
                </div>
                <div style={{ display: response }} className="max-h-[58vh] p-4 overflow-auto text-xs whitespace-pre-wrap text-gray-600 dark:text-gray-100">
                    {log ? <pre><code className="text-gray-600 dark:text-gray-100">{JSON.stringify(log.response_payload, null, 2)}</code></pre> : null}
                </div>
            </CardBody>
        </Card>
        
        </>
    )
}

export default JsonCard