
const ModalFooter = (props) => {


    return (

        <div className="flex justify-end p-4 md:p-5 rounded-b">
            {props.children}
        </div>
            
    )
}


export default ModalFooter