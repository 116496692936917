import React, { useState } from 'react';
import {PlayIcon, PauseIcon} from '@heroicons/react/outline'
import axios from "axios";
import toast from 'react-hot-toast';
import { Button } from '@windmill/react-ui';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import Spinner from '../Buttons/Spinner'





function UpdateScheduleModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/schedules/update'

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const handleUpdate = () => {

    setIsLoading(true);

    let data = {
      'ids': props.ids, 
      'updates': {'status': props.markAs}
    }

    axios.post(url, data)
      .then(() => {
        refreshData();
      }).catch((e) => {
        if (e.response) {
          toast.error(e.response.data.reason)
          setIsLoading(false);
        }
      });
  }

  const refreshData = () => {
    setIsLoading(false);
    setIsModalOpen(false);
    props.callBack && props.callBack();
    toast.success(`Schedule${props.ids ? 's' : ''} updated`);
  }
    

  const ButtonType = () => {
    if(props.type==='icon'){
      return(
        <div>
          <Button onClick={openModal} layout="link" size="icon" aria-label="Update Schedule">
            {props.markAs === 'Active' ? 
              <PlayIcon className="w-5 h-5" aria-hidden="true" />
            : 
              <PauseIcon className="w-5 h-5" aria-hidden="true" />
            }
          </Button>
        </div>
      )
    }else if(props.type==='button'){
      return (
        <Button layout="outline" iconRight={props.markAs === 'Active' ? PlayIcon : PauseIcon} onClick={openModal}>
          <span>Mark {props.markAs.toLowerCase()}</span>
        </Button>
      )
    } else if(props.type==='dropdown'){
      return (
        <DropdownItem tag="a" onClick={openModal} className="z-40 relative">
          {props.markAs === 'Active' ? 
            <PlayIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          : 
            <PauseIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          } 
          <span>Mark {props.markAs.toLowerCase()}</span>
        </DropdownItem>
      )
    }
  }



  return (
    <>

      {ButtonType()}

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Delete Issue'}>
        <ModalBody>
          <p>Are you sure you want to mark {props.ids.length} Schedule{props.ids.length > 1 ? 's' : ''} as {props.markAs.toLowerCase()}?</p>
        </ModalBody>
        <ModalFooter>
          <div className="hidden sm:block">
            <Button layout="outline" onClick={handleUpdate} disabled={isLoading}>
              <Spinner display={isLoading}/>
              Confirm Update
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={handleUpdate} disabled={isLoading}>
            <Spinner display={isLoading}/>
              Confirm Update
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default UpdateScheduleModal
