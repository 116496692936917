import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom';
import {TableHeader, TableCell, TableBody, TableRow, TableFooter,
  TableContainer, Pagination, Badge, Input} from '@windmill/react-ui'
import Table from './Table'
import axios from 'axios'
import Tooltip from '../Tooltip/Tooltip';
import Loader from '../Buttons/Loader';
import DeleteIssueModal from '../Modals/DeleteIssueModal';
import UpdateIssueModal from '../Modals/UpdateIssueModal'
import store from '../../store';
import metricsSlice from '../../store/slices/metrics';



function IssuesTable(props) {

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const getIssueUrl = `${BACKEND_URL}/v1/ops/issue`;
  const searchIssueUrl = BACKEND_URL + '/v1/ops/issue/search?query='
  const metricsUrl = `${BACKEND_URL}/v1/ops/metrics/home`;

  const [issues, setIssues] = useState(props.issues ? props.issues : null)
  const [issueIds, setIssueIds] = useState([]);
  const [collecting, setCollecting] = useState(false);
  const [allIssues, setAllIssues] = useState(false)
  const [query, setQuery] = useState('')

  // get current issues count
  const getMetrics = async () => {
    await axios.get(metricsUrl)
        .then((r) => {
            store.dispatch(metricsSlice.actions.setMetrics(r.data))
        })
        .catch((e) => {
            console.log(e)
        })
  }

  const getData = () => {
    axios.get(getIssueUrl).then((r) => {
      setIssues(r.data);
      getMetrics();
    });
    // eslint-disable-next-line
  }
  

  useEffect(() => {
    getData();
    // eslint-disable-next-line 
  }, []);


  const search = async () => {
    if (query !== '') {
      await axios.get(`${searchIssueUrl}${query}`)
          .then((r) => {
            setIssues(r.data)
          })
          .catch((e) => {
              console.log(e)
          })
    }
  }

  // setup pages control for table
  const pageTable = 1

  // pagination setup
  const resultsPerPage = 10

  // pagination change control
  function onPageChangeTable(p) {
    if(p){
      var offSet = (p-1)*5
      // var paginatedUrl = `${BACKEND_URL}/v1/ops/issue?limit=10&offset=${offSet}`
      var paginatedUrl = issues.next ? (query.length > 0 ? `${issues.next.split('offset')[0]}offset=${offSet}&query=${query}` : `${issues.next.split('offset')[0]}offset=${offSet}` ) : `${BACKEND_URL}/v1/ops/issue?limit=10&offset=${offSet}`
      axios.get(paginatedUrl)
        .then((r) => {
          setIssues(r.data);
      })
    }
    
  }

  function getDate(issue) {
    if(issue.time_created){
        var date = new Date(issue.time_created).toLocaleDateString();
        var time = new Date(issue.time_created).toLocaleTimeString('en-US');
        var formatedDateTime = `${date} ${time}`
        return formatedDateTime
    }else{
        return '––';
    }
  }



  const handleCheckBox = (id) => {
    setAllIssues(false)
    if (issueIds.includes(id)){
      let ids = [...issueIds]
      let newIds = ids.filter(i => i !== id)
      setIssueIds([...newIds])
    }else{
      setIssueIds([...issueIds, id])
    }
  }


  const handleSelectAll = async () => {
    if (issueIds.length === 0){
      // get all sites in account
      let offSet = 0
      let allIds = []
      let next = true
      setCollecting(true)
      
      while (next){
        let paginatedUrl = `${BACKEND_URL}/v1/ops/issue?limit=100&offset=${offSet}`
        await axios.get(paginatedUrl)
          .then((r) => {
            for (let i in r.data.results){
              allIds.push(r.data.results[i].id)
            }
            if (r.data.next){
              offSet = offSet + 100
            }else{next = false}
        }).catch(err => console.log(err))
      }
      
      setIssueIds([...allIds])
      setAllIssues(true)
      setCollecting(false)

    }else{
      deleteCallBack();
    }
  }


  const deleteCallBack = () => {
    setIssueIds([])
    setAllIssues(false)
    getData();
  }


  if (issues===null){
   return <Loader item={'Issues'}/>
  }


  return (
    <>
      {issueIds.length > 0 &&
        <div className='flex justify-start mb-6'>
          <div className='mr-4'>
            <DeleteIssueModal ids={issueIds} type='button' callBack={deleteCallBack}/>
          </div>
          <div className='mr-4'>
            <UpdateIssueModal ids={issueIds} type='button' callBack={deleteCallBack} markAs='closed'/>
          </div>
          <div className=''>
            <UpdateIssueModal ids={issueIds} type='button' callBack={deleteCallBack} markAs='open'/>
          </div>
        </div>
      }
      <TableContainer className="mb-8 dark:bg-gray-800 bg-white">
        <div className='flex justify-between w-full py-4 px-3'>
          <Input
            className='max-w-md h-8 rounded outline-none border-0 bg-gray-100 dark:bg-gray-900'
            value={query}
            placeholder='search by title, resource, or details'
            onChange={(e) => {
              setQuery(e.target.value)
              if (e.target.value !== '') {
                search();
              } else {
                getData();
              }
            }}
          />
        </div>
        <Table>
          <TableHeader>
            <tr className='dark:bg-gray-800 bg-white'>
              <TableCell>
                <Input 
                  className='rounded-sm'
                  type="checkbox"
                  value={allIssues} 
                  checked={allIssues}
                  onChange={handleSelectAll}
                  disabled={collecting}
                />
              </TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Affected</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Actions</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {issues.results.slice((pageTable - 1) * resultsPerPage, pageTable * resultsPerPage).map((issue, i) => (
              <TableRow key={i} className="hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer">
                <TableCell>
                  <div>
                    <Input 
                      className='rounded-sm'
                      type="checkbox"
                      value={issueIds.includes(issue.id)} 
                      checked={issueIds.includes(issue.id)}
                      onChange={() => handleCheckBox(issue.id)}
                      disabled={collecting}
                    />
                  </div>
                </TableCell>
                <TableCell>
                  <Link to={`/issue/${issue.id}`}>
                    <span className='font-semibold text-gray-600 dark:text-gray-400 truncate'>{issue.title}</span>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/issue/${issue.id}`}>
                    <div className='max-w-[25rem] hover:overflow-visible overflow-hidden truncate'>
                      <span className='relative rounded-md hover:bg-white hover:dark:bg-gray-800 p-1'>
                        {issue.affected ? issue.affected.str : 'None'}
                      </span>
                    </div>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/issue/${issue.id}`}>
                    {issue.status === 'open' ? <Badge type='primary'>open</Badge> : <Badge type='success'>closed</Badge> }
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/issue/${issue.id}`}>
                    <span className="text-sm">{getDate(issue)}</span>
                  </Link>
                </TableCell>
                <TableCell>
                  <div className='flex justify-start gap-2'>
                    <Tooltip content={`mark ${issue.status === 'open' ? 'closed' : 'open'}`}>
                      <UpdateIssueModal type='icon' callBack={getData} ids={[issue.id]} markAs={issue.status === 'open' ? 'closed' : 'open' }/>
                    </Tooltip>
                    <Tooltip content='delete issue'>
                      <DeleteIssueModal type='icon' callBack={deleteCallBack} issue={issue}/>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={issues.count}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}




export default IssuesTable

