import React, {useState, useEffect} from 'react';
import PageTitle from '../components/Typography/PageTitle';
import { Label, Card, CardBody, Badge, Button } from '@windmill/react-ui';
import axios from "axios";
import store from '../store';
import authSlice from '../store/slices/auth';
import { useDispatch } from "react-redux";
import toast from 'react-hot-toast';
import SlackButton from '../components/Buttons/SlackButton';
import ZapierButton from '../components/Buttons/ZapierButton';
import Spinner from '../components/Buttons/Spinner'
import { EyeIcon, EyeOffIcon, RefreshIcon } from '@heroicons/react/outline';
import Tooltip from '../components/Tooltip/Tooltip';
import DeleteMemberModal from '../components/Modals/DeleteMemberModal';
import MemberModal from '../components/Modals/MemberModal';
import teamSlice from '../store/slices/team';
import Loader from '../components/Buttons/Loader';
import PasswordModal from '../components/Modals/PasswordModal';
import ConfigsModal from '../components/Modals/ConfigsModal';
import ResetSlackModal from '../components/Modals/ResetSlackModal';




function Account () {

    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const user_url = `${BACKEND_URL}/v1/auth/update-user`
    const member_url = `${BACKEND_URL}/v1/auth/member`
    const account_url = `${BACKEND_URL}/v1/auth/account`


    const dispatch = useDispatch();

    const [email, setEmail] = useState(store.getState().auth.account.username)
    const [channel, setChannel] = useState(store.getState().team.team ? store.getState().team.team.slack.slack_channel_name : null)
    const token = store.getState().auth.api_token ? store.getState().auth.api_token : null
    const [emailLoading, setEmailLoading] = useState(false)
    const [showToken, setShowToken] = useState(false)
    const [account, setAccount] = useState(store.getState().team.team)
    const [teamName, setTeamName] = useState(store.getState().team.team.name)
    const [acctLoading, setAcctLoading] = useState(false)
    const [showAcct, setShowAcct] = useState(false)
    const [showEmail, setShowEmail] = useState(false)
    const [members, setMembers] = useState(null)
    const member = store.getState().member.member



    useEffect(() => {
        getMembers()
    }, [])
    

    const sendInvite = async (memb) => {
        var data = {
            'id': memb.id,
            'status': 'pending'
        }
        await axios.post(member_url, data)
            .then((r) => {
                toast.success('Invite sent!')
            })
            .catch((e) => {
                if (e.response) {
                    toast.error(e.response.data.reason)
                }
            })
    }


    const getMembers = async () => {
        await axios.get(`${account_url}/${account.id}/members`)
            .then((r) => {
                setMembers(r.data)
            })
            .catch((e) => {
                if (e.response) {
                    toast.error(e.response.data.reason)
                }
            })
    }


    const updateUser = (evt) => {
        evt.preventDefault();
        setEmailLoading(true)
        var data = {'email': email,}
        axios.post(user_url, data)
        .then((r) => {
            dispatch(authSlice.actions.setAccount(r.data))
            setEmail(r.data.email);
            setEmailLoading(false)
            setShowEmail(false)
            toast.success('Email Updated!')
        }).catch((err) => {
            toast.error('Sorry, that email is taken')
            setEmailLoading(false)
        })
    }


    
    const updateAccount = async () => {
        setAcctLoading(true)
        var data = {
            'id': account.id,
            'name': teamName
        }
        await axios.post(account_url, data)
            .then((r) => {
                setAccount(r.data)
                store.dispatch(teamSlice.actions.setTeam(r.data))
                setAcctLoading(false)
                setShowAcct(false)
                toast.success('Team Updated!')
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const copyKey = () => {
        navigator.clipboard.writeText(token).then(function() {
            toast.success('Coppied!')
        }).catch(err => toast.error('Couldn\'t copy'))
    }

    const toggleKey = () => {
        console.log('toggle key')
        if (showToken){
            setShowToken(false)

        }else {
            setShowToken(true)
        }
    }


    const getChannel = () => {
        if (channel){
            return (
                <p className="ml-3 dark:text-gray-300">               
                    {channel}
                </p>
            )
        }
    }


    const slackCallBack = () => {
        setAccount(store.getState().team.team)
        setChannel(null)
    }


    if (!members){return <Loader item={'Account'}/>} 


    return (
        <>
            <PageTitle>Account</PageTitle>
            {members && 
                <div className='grid md:grid-cols-2 gap-6 grid-cols-1 mb-8'>
                    <div>
                        <Card className=''>
                            <CardBody>
                                <div className="grid md:grid-cols-2 gap-2 px-4">
                                    <div>
                                        {showAcct ?
                                            
                                            <Label className="mt-4">
                                                <span>Team Name</span>
                                                <div className="relative text-gray-500">
                                                    <input
                                                        className="rounded-md p-2 block w-full pr-20 mt-1 text-sm text-black dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:shadow-outline-blue dark:focus:shadow-outline-gray form-input"
                                                        placeholder='Team Name' 
                                                        value={teamName}
                                                        type="text"
                                                        onChange={e => setTeamName(e.target.value)}
                                                    />
                                                    <button onClick={updateAccount} className="absolute inset-y-0 right-0 px-4 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-r-md active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue">
                                                        <Spinner display={acctLoading}/>
                                                        {!acctLoading && 'Update'}
                                                    </button>
                                                </div>
                                            </Label>
                                        
                                            :
                                            
                                            
                                            <Label className="mt-4">
                                                <Tooltip content="click to update" direction="top">
                                                <div className="text-gray-500">
                                                    <span onClick={() => {setShowAcct(true)}} className='md:text-3xl text-2xl font-semibold dark:text-gray-50 text-gray-600 cursor-pointer'>
                                                        {account.name}
                                                    </span>
                                                </div>
                                                </Tooltip>
                                            </Label>
                                        
                                        }

                                        {showEmail ?
                                            
                                            <Label className="mt-4">
                                                <span>Email</span>
                                                <div className="relative text-gray-500">
                                                    <input
                                                        className="rounded-md p-2 block w-full pr-20 mt-1 text-sm text-black dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:shadow-outline-blue dark:focus:shadow-outline-gray form-input"
                                                        placeholder="jane.doe@example.com" 
                                                        value={email || ''}
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                    <button onClick={updateUser} className="absolute inset-y-0 right-0 px-4 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-r-md active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue">
                                                        <Spinner display={emailLoading}/>
                                                        {!emailLoading && 'Update'}
                                                    </button>
                                                </div>
                                            </Label>
                                        
                                            :
                                            
                                            
                                            <Label className="mt-2">
                                                <Tooltip content="click to update" direction="top">
                                                <div className="">
                                                    <span onClick={() => {setShowEmail(true)}} className='md:text-[1rem] text-sm dark:text-gray-400 text-gray-500 cursor-pointer'>
                                                        {email}
                                                    </span>
                                                </div>
                                                </Tooltip>
                                            </Label>
                                        
                                        }

                                        <div className='mt-2'>
                                            <PasswordModal/>
                                        </div>

                                    </div>
                                
                                    <div className='mt-4 md:justify-self-end justify-self-start mr-4'>
                                        <ConfigsModal type='button'/>
                                    </div>


                                </div>
                                
                                <div className='mt-8 flex justify-center overflow-auto max-h-[60%]'>
                                    
                                    <div className='w-[90%] mb-6'>
                                        <Label className='mb-4'>Members</Label>
                                        {members.results.map((memb, i) => (
                                            <div key={i}>
                                                {memb.status !== 'removed' && 
                                                    <div key={i} className='my-1 p-2 px-4 rounded dark:bg-gray-700 bg-gray-100 grid grid-cols-2 gap-4'>
                                                        <div>
                                                            <p className='text-sm dark:text-gray-300 text-gray-600'>{memb.email}</p>
                                                        </div> 
                                                        <div className='flex justify-end'>
                                                            {
                                                                memb.type === 'admin' ? 
                                                                <div><Badge type={'success'}>admin</Badge></div> : 
                                                                <div><Badge type={memb.status==='active' ? 'primary' : 'warning'}>{memb.status}</Badge></div>
                                                            }
                                                            
                                                            {member.type === 'admin' && memb.email !== member.email && 
                                                                <div>
                                                                    <div className='ml-2'>
                                                                        <Tooltip content='remove member'>
                                                                            <DeleteMemberModal member={memb} callBack={getMembers} type='icon'/>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {member.type === 'admin' && memb.email !== member.email && memb.status === 'pending' &&
                                                                <div className='ml-1'>
                                                                    <Tooltip content='send invite'>
                                                                        <div>
                                                                            <Button onClick={() => sendInvite(memb)} layout="link" size="icon" aria-label="Send Invite">
                                                                                <RefreshIcon className="w-5 h-5" aria-hidden="true" />
                                                                            </Button>
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            }

                                                            
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                        <div className='mt-4'>
                                            {member.type === 'admin' && 
                                                <MemberModal team={account} callBack={getMembers}/>
                                            }
                                        </div>
                                        
                                    </div>
                                </div>
                                
                            </CardBody>
                        </Card>
                    </div>
                    <div className='grid grid-cols-1 gap-6'>
                        <div>
                            <Card>
                                <CardBody>
                                    <div className='px-4'>
                                        <h3 className='mt-3 text-xl font-semibold dark:text-gray-50 text-gray-600'>
                                            Integrations
                                        </h3>
                                        <div className="my-8">
                                            <span className='text-sm dark:text-gray-400 text-gray-700'>API Token</span>
                                            <div className="text-gray-500 w-full">
                                            
                                                <div
                                                    className="flex justify-between rounded-md w-full mt-1 text-sm text-black dark:text-gray-300 dark:border-l-gray-600 dark:bg-gray-700 bg-gray-100 form-input outline-none"
                                                >
                                                    <Tooltip content="click to copy" direction="top" classes='sm:flex hidden'>
                                                        <p className='sm:flex hidden overflow-auto cursor-pointer md:max-w-[100%] max-w-[70%]' onClick={copyKey}>{showToken  ? token : '•••••••••••••••••••••••••••••••••••••••••'}</p>
                                                    </Tooltip>
                                                    
                                                    <p className='-ml-10 sm:hidden flex overflow-auto cursor-pointer md:max-w-[100%] max-w-[70%]' onClick={copyKey}>{showToken  ? token : '•••••••••••••••••••••••••••••••••••••••••'}</p>


                                                    <button onClick={toggleKey} className="pr-2 sm:-ml-8">
                                                        {showToken ?
                                                            <EyeOffIcon className='h-5 w-5'/>
                                                            :
                                                            <EyeIcon className='h-5 w-5'/>
                                                        } 
                                                    </button>

                                                </div>

                                            </div>    
                                        </div>
                                        
                                        <div className='grid grid-cols-2 gap-4 my-8'>
                                            <div className="">
                                                <span className='text-sm dark:text-gray-400 text-gray-700'>Slack</span>
                                                {channel &&  
                                                    <div className="flex">
                                                        <p className="dark:text-gray-300">Channel:  </p>
                                                        {getChannel()}
                                                    </div>
                                                }
                                                <div className="">
                                                    {channel ? 
                                                        // <span className='underline hover:no-underline decoration-blue-600 text-sm text-blue-600 cursor-pointer' onClick={resetSlack}>Reset Slack</span>
                                                        <ResetSlackModal type='link' callBack={slackCallBack}/>
                                                    :
                                                        <SlackButton/>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <span className='text-sm dark:text-gray-400 text-gray-700'>Zapier</span>
                                                <div>
                                                    <ZapierButton/>
                                                </div>
                                            </div>

                                        </div>
                                        
                                    </div>
                                </CardBody>
                            </Card>
                        </div>                
                    </div>
                </div>
            }
            
        </>
    )
}

export default Account