import React, {useEffect, useState} from 'react'
import SiteTable from '../components/Tables/SiteTable';
import PageTitle from '../components/Typography/PageTitle'
import HomeStatsCard from '../components/Cards/HomeStatsCard'
import siteDataSlice from '../store/slices/siteData'
import metricsSlice from '../store/slices/metrics'
import store from '../store'
import axios from 'axios'


const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
const statsUrl = `${BACKEND_URL}/v1/ops/metrics/home`
const paginatedUrl = `${BACKEND_URL}/v1/ops/site?limit=10&offset=0`



function Home() {

  const [data, setData] = useState(null)

  const getData = async () => {
    
    await axios.get(statsUrl)
      .then((r) => {
        setData(r.data)
        store.dispatch(metricsSlice.actions.setMetrics(r.data))
      })
      .catch(err => console.log(err))
    
    await axios.get(paginatedUrl)
      .then((r) => {
        store.dispatch(siteDataSlice.actions.setSiteData(r.data));
      }).catch(err => console.log(err))
  }

  useEffect(() => {
    getData();
  }, [])



  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <HomeStatsCard data={data} />
      <SiteTable />
    </>
  )
}

export default Home
