import React, { useState } from 'react';
import { Input,  Button,  Label, Textarea } from '@windmill/react-ui';
import axios from "axios";
import toast from 'react-hot-toast';
import Spinner from '../Buttons/Spinner'
import { PlusIcon } from '@heroicons/react/outline';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'


function PageModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/page'

  const [page_url, setPageUrl] = useState('');
  const [page_urls, setPageUrls] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false)

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      handleAddPage(evt)
    }
  }

  const handleAddPage = () => {

    // cleaning page_urls if present
    var _page_urls = null
    if (page_urls.length > 0){
      _page_urls = []
      if (page_urls.includes(',')){
        var temp_urls = page_urls.split(',')
      }else{
        var temp_urls = page_urls.split('\n')
      }
      for (var i in temp_urls) {
        var _url = temp_urls[i].trim()
        _page_urls.push(_url)
      }
    }

    const data = {
      "page_url": page_urls ? null : page_url,
      "page_urls": _page_urls,
      "site_id": props.site.id
    }

    setIsLoading(true);
    if(!showAdvanced){
      if(page_url === ''){
        setIsLoading(false);
        toast.error('please enter a page_url');
        return
      } else if (!page_url.startsWith(props.site.site_url)){
        setIsLoading(false);
        toast.error('page_url must begin with site_url');
        return
      }
    }

    axios.post(`${url}`, data)
      .then((r) => {
        toast.success(`Page${_page_urls ? 's' : ''} added!`);
        props.callBack()
        setIsLoading(false);
        setIsModalOpen(false);
        setSiteUrl('');
      }).catch((e) => {
        if (e.response) {
          toast.error(e.response.data.reason)
          setIsLoading(false);
        }
      })
  }



  const ButtonType = () => {
    if(props.type==='icon'){
      return(
        <div>
          <Button onClick={openModal} layout="link" size="icon" aria-label="Delete">
            <PlusIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
        </div>
      )
    }else if(props.type==='button'){
      return (
        <Button layout="outline" iconRight={TrashIcon} onClick={openModal}>
            <span>Add Page</span>
        </Button>
      )
    } else if(props.type==='dropdown'){
      return (
        <DropdownItem
          onClick={(e) => {
            openModal()
            }} 
          className="z-40 relative">
          <PlusIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Add Page</span>
        </DropdownItem>
      )
    }
  }



  return (
    <>
        <div>{ButtonType()}</div>
        <Modal 
          isOpen={isModalOpen} 
          onClose={closeModal}
          title={'Add a Page'}
        >
          <div id='page-modal'>
          {/* <ModalHeader onClick={e=>e.nativeEvent.stopImmediatePropagation()}>Add a Page</ModalHeader> */}
          <ModalBody onClick={e=>e.nativeEvent.stopImmediatePropagation()}>
            <div>
              {!showAdvanced &&
                <Input
                  onClick={e=>e.nativeEvent.stopImmediatePropagation()}
                  onKeyDown={handleKeyDown}
                  className="rounded-md p-2 mt-1" 
                  placeholder='https://example.com/blog'
                  value={page_url}
                  onChange={e => setPageUrl(e.target.value)}
                  autoFocus={true}
                /> 
              }
              <div className='mt-6 text-left'>
                <span
                  className='text-sm underline text-blue-600 cursor-pointer w-auto'
                  onClick={() => setShowAdvanced(!showAdvanced)}
                >
                  {showAdvanced ? 'Single Add' : 'Bulk Add'}
                </span>
              </div>
              {showAdvanced &&
                <div className='mt-4 '>
                  <Label className="text-sm font-semibold">Add Pages</Label>
                  <Textarea className="rounded-md p-2 mt-1" rows="3" placeholder='https://example.com/blog&#10;https://example.com/contact' 
                    name="page_urls"
                    onChange={e => setPageUrls(e.target.value)}/>
                </div>
              }
            </div>
          </ModalBody>
          <ModalFooter onClick={e=>e.nativeEvent.stopImmediatePropagation()}>
            <div className="hidden sm:block">
              <Button layout="outline" onClick={handleAddPage} type="submit" disabled={isLoading}>
              <Spinner display={isLoading}/>
                Submit
              </Button>
            </div>
            <div className="block w-full sm:hidden">
              <Button block size="large" layout="outline" onClick={handleAddPage} type="submit" disabled={isLoading}>
              <Spinner display={isLoading}/>
                Submit
              </Button>
            </div>
          </ModalFooter>
          </div>
        </Modal>
      
    </>
  )
}

export default PageModal


