import React, { useState } from 'react';
import { LightningBoltIcon } from '@heroicons/react/outline';
import axios from "axios";
import toast from 'react-hot-toast';
import { Button } from '@windmill/react-ui';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import Spinner from '../Buttons/Spinner'





function RunScheduleModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/schedule/run'

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const runSchedule = (evt) => {
    evt.preventDefault();
    setIsLoading(true);

    let data = {
      'schedule_id': props.schedule.id
    }

    axios.post(`${url}`, data)
    .then((res) => {
      if (res.data) {
        setIsLoading(false);
        setIsModalOpen(false);
        toast.success('Schedule Executed');
        props.callBack && props.callBack();
        
      }
    }).catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    });
      
  }

  const ButtonType = () => {
    if(props.type==='icon'){
      return(
        <div>
          <Button onClick={openModal} layout="link" size="icon" aria-label="Run Schedule">
            <LightningBoltIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
        </div>
        
      )
    }else if(props.type==='button'){
      return (
        <Button layout="outline" iconRight={LightningBoltIcon} onClick={openModal}>
            <span>Run Schedule</span>
        </Button>
      )
    } else if(props.type==='dropdown'){
      return (
        <DropdownItem tag="a" onClick={openModal} className="z-40 relative">
          <LightningBoltIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Run Schedule</span>
        </DropdownItem>
      )
    }
  }



  return (
    <>

      {ButtonType()}

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Run Schedule'}>
        <ModalBody>
          <p>
            This will immediately execute the Schedule and associated Automation. 
            Are you sure you want to continue?
          </p>
        </ModalBody>
        <ModalFooter>
          <div className="hidden sm:block">
            <Button layout="outline" onClick={runSchedule} disabled={isLoading}>
              <Spinner display={isLoading}/>
              Confirm Run
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={runSchedule} disabled={isLoading}>
            <Spinner display={isLoading}/>
              Confirm Run
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default RunScheduleModal
