import axios from 'axios';
import authSlice from "../slices/auth";
import billingSlice from "../slices/billing";
import siteDataSlice from "../slices/siteData";
import store from '..';

const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;


const refreshAuthLogic = failedRequest => axios.post(`${BACKEND_URL}/v1/auth/refresh/`, 
    { 'refresh': store.getState().auth.refreshToken }, { skipAuthRefresh: true })
        .then(res => {
            store.dispatch(
                authSlice.actions.setAuthTokens({
                  token: res.data.access,
                  refreshToken: store.getState().auth.refreshToken,
                  api_token: store.getState().auth.api_token,
                })
              );

            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + res.data.access;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access;
            
        return Promise.resolve();
            
    }).catch((error) => {
        store.dispatch(authSlice.actions.setLogout());
        store.dispatch(billingSlice.actions.setLogout())
        store.dispatch(siteDataSlice.actions.setLogout())
        window.location.href = '/login';
    });


export default refreshAuthLogic;