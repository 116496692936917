import React, {useState} from 'react';
import LighthouseDeltasCard from './LighthouseDeltasCard'
import YellowlabDeltasCard from './YellowlabDeltasCard'
import { Button } from '@windmill/react-ui';
import SectionTitle from '../Typography/SectionTitle'



function DeltasCard(props) {

    const [lh, setLh] = useState('block')
    const [yl, setYl] = useState('none')
    const [lhButton, setLhButton] = useState('primary')
    const [ylButton, setYlButton] = useState('outline')


    function getLh(){
        setLh('block')
        setYlButton('outline')
        setLhButton('primary')
        setYl('none')
    }

    function getYl(){
        setLh('none')
        setYlButton('primary')
        setLhButton('outline')
        setYl('block')
    }

    return (
        <div className='mt-8'>
            {/* <SectionTitle>Performance Changes</SectionTitle> */}
            <div className="flex justify-arround mb-3">
                <div className="mb-3 mr-4">
                    <Button
                        size="small"
                        layout={lhButton}
                        onClick={() => getLh()}
                    >
                        Lighthouse
                    </Button>
                </div>
                <div className="mb-3">
                    <Button
                        size="small"
                        layout={ylButton}
                        onClick={() => getYl()}
                    >
                        Yellow Lab
                    </Button>
                </div>
            </div>
            <div style={{ display: lh }} className="mb-6">
                <LighthouseDeltasCard delta={props.test.lighthouse_delta ? props.test.lighthouse_delta : null} />
            </div>
            <div style={{ display: yl }} className="mb-6">
                <YellowlabDeltasCard delta={props.test.yellowlab_delta ? props.test.yellowlab_delta : null} />
            </div>

        </div>

    )
}

export default DeltasCard