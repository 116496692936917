import React, {useState} from 'react';
import { Card, CardBody, Button, Input, Select, Textarea, Label } from '@windmill/react-ui';
import Spinner from '../Buttons/Spinner';
import axios from 'axios';
import toast from 'react-hot-toast';
import store from '../../store';



function ApiRequestCard(props) {

    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;

    const [requestType, setRequestType] = useState('GET')
    const [path, setPath] = useState(`${BACKEND_URL}/v1/ops/`);
    const [data, setData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [displayData, setDisplayData] = useState('none');
    const [auth, setAuth] = useState('jwt');
    const [jwtChecked, setJwtChecked] = useState(true);
    const [tokenChecked, setTokenChecked] = useState(false);
    const api_token = store.getState().auth.api_token;
    const jwt_token = store.getState().auth.token;



    
    function getRequestType(e){
        const { value } = e.target;
        setRequestType(value)

        if (value === 'POST'){
            setDisplayData('block')
        }else{
            setDisplayData('none')
            setData('')
        }
    }

    function getAuthType(e) {
        setAuth(e.target.value)
        if (e.target.value === 'jwt'){
            setJwtChecked(true)
            setTokenChecked(false)
        }else {
            setJwtChecked(false)
            setTokenChecked(true)
        }
    }

    const formatCode = (e) => {
        try {
            var obj = JSON.parse(e.target.value);
            var pretty = JSON.stringify(obj, undefined, 4);
            setData(pretty)
            return pretty
        } catch (error) {
            setData(e.target.value)
            return
        }
    }

    const toastError = (err) => {
        try {
            toast.error(`Error ${err.response.status}`)
            return
        } catch (error) {
            toast.error(`Unknown Error, check url`)
        }
    }


    function handleSubmit(e){
        e.preventDefault();
        setIsLoading(true);
        var authToken = `Bearer ${jwt_token}` 
        if (auth === 'token'){
            authToken = `Token ${api_token}` 
        }
        
        const header_data = {
            headers: {
              'Authorization': `${authToken}`, 
              'Content-Type': 'application/json',
            }
        }

        if (requestType === 'POST'){
            if (data === ''){
                toast.error('Cant send without data');
                setIsLoading(false);
                return null;
            }
            try {
                var new_data = JSON.parse(data);
            } catch (error) {
                toast.error('JSON data not formated correctly')
                setIsLoading(false);
                return null;
            }
            
            axios.post(path, new_data, header_data)
            .then((r) => {
                props.apiResponse({...r})
                setIsLoading(false);
                toast.success('Success!')

            }).catch((err) => {
                props.apiResponse({...err.response})
                setIsLoading(false);
                toastError(err)
            })
        }else if (requestType === 'DELETE'){
            axios.delete(path, header_data)
            .then((r) => {
                props.apiResponse({...r})
                setIsLoading(false);
                toast.success('Success!')
            }).catch((err) => {
                props.apiResponse({...err.response})
                setIsLoading(false);
                toastError(err)
            })
        }else if (requestType === 'GET'){
            axios.get(path, header_data)
            .then((r) => {
                props.apiResponse({...r})
                setIsLoading(false);
                toast.success('Success!')
            }).catch((err) => {
                props.apiResponse({...err.response})
                setIsLoading(false);
                toastError(err)
            })
        }
    }

    

    return (
        <>
        <Card className="">
            <CardBody className="">
                <p className="mb-2 font-semibold text-gray-600 dark:text-gray-300">Request</p>
                <div className="mt-2">
                    <div className="mt-4 mb-2">
                        <Label radio>
                            <Input 
                                type="radio" 
                                value="jwt" 
                                name="authType" 
                                checked={jwtChecked}
                                disabled={isLoading}
                                onChange={e => {
                                    getAuthType(e);
                                }}
                                />
                            <span className="ml-2">JWT Auth</span>
                        </Label>
                        <Label className="ml-6" radio>
                            <Input 
                                type="radio" 
                                value="token" 
                                name="authType" 
                                checked={tokenChecked}
                                disabled={isLoading}
                                onChange={e => {
                                    getAuthType(e);
                                    }}
                            />
                            <span className="ml-2">Token Auth</span>
                        </Label>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-4 mb-3">
                    <div className="col-start-1 col-end-2">
                        <div className="mt-4">
                            <Select
                                
                                // style={{ width: '11rem', maxHeight: '3em'}}
                                className='rounded-md p-2'
                                name="request_type"
                                value={requestType}
                                onChange={e => getRequestType(e)}
                                disabled={isLoading}
                            >
                                <option value="GET">GET</option>
                                <option value="POST">POST</option>
                                <option value="DELETE">DELETE</option>
                            </Select>
                        </div>
                    </div>
                    <div className="col-start-2 col-end-4">
                        <div className="mt-4">
                            <Input
                                className='rounded-md p-2'
                                placeholder="https://api.cursion.dev/v1/ops/site"
                                value={path}
                                onChange={e => setPath(e.target.value)}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-2 p-4 mb-4" style={{ display: displayData }}>
                    <code>
                        <Textarea 
                            className="rounded-md p-2 mt-1 whitespace-pre" rows="8" placeholder='{ "site_id": "<uuid>"}' 
                            name="data"
                            value={data}
                            disabled={isLoading}
                            onChange={e => {
                                formatCode(e)
                            }}

                        />
                    </code>
                </div>
                <div className="right-0 mt-6">
                    <Button 
                        onClick={e => handleSubmit(e)}
                        layout="primary"
                        size="small"
                        disabled={isLoading}
                    >
                        <Spinner display={isLoading} />
                        Send
                    </Button>
                </div>
            </CardBody>
        </Card>
        
        </>
    )
}

export default ApiRequestCard