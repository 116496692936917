import React from 'react'
import Spinner from './Spinner'

function Loader(props) {
  return (
    <div className="w-full my-4 p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900 flex justify-start">
      <Spinner display={true}/> <p className='-mt-1'>Loading{props.item ? ` ${props.item}` : ''}...</p>
    </div>
  )
}

export default Loader