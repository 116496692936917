import React, { useState } from 'react';
import { RefreshIcon } from '@heroicons/react/outline';
import axios from "axios";
import toast from 'react-hot-toast';
import { Button } from '@windmill/react-ui';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import Spinner from '../Buttons/Spinner'
import store from '../../store';
import teamSlice from '../../store/slices/team';




function ResetSlackModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = `${BACKEND_URL}/v1/auth/account`

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }


  const resetSlack = async () => {
    setIsLoading(true)

    let data = {
      'id': store.getState().team.team.id,
      'slack': {
          'slack_name': null, 
          'bot_user_id': null, 
          'slack_team_id': null, 
          'bot_access_token': null, 
          'slack_channel_id': null, 
          'slack_channel_name': null
      }
    }

    await axios.post(url, data)
      .then((r) => {
          store.dispatch(teamSlice.actions.setTeam(r.data))
          toast.success('Slack Reset')
          props.callBack();
      })
      .catch((e) => {
          console.log(e)
          toast.success('Something went wrong')
      })
    }



  const ButtonType = () => {
    if(props.type==='icon'){
      return(
        <div>
          <Button onClick={openModal} layout="link" size="icon" aria-label="Reset Slack">
            <RefreshIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
        </div>
      )
    }else if(props.type==='button'){
      return (
        <Button layout="outline" iconRight={RefreshIcon} onClick={openModal}>
            <span>Reset Slack</span>
        </Button>
      )
    } else if(props.type==='dropdown'){
      return (
        <DropdownItem tag="a" onClick={openModal} className="z-40 relative">
          <RefreshIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Reset Slack</span>
        </DropdownItem>
      )
    }
    else if(props.type==='link'){
      return (
        <span 
          className='underline hover:no-underline decoration-blue-600 text-sm text-blue-600 cursor-pointer' 
          onClick={openModal}
        >
          Reset Slack
        </span>
      )
    }
  }



  return (
    <>

      {ButtonType()}

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Reset Slack'}>
        <ModalBody>
            <p>
              This will reset your current Slack integration and all automations 
              using Slack will stop working. Are you sure you want to contine?
            </p>
        </ModalBody>
        <ModalFooter>
          <div className="hidden sm:block">
            <Button layout="outline" onClick={resetSlack} disabled={isLoading}>
              <Spinner display={isLoading}/>
              Confirm Reset
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={resetSlack} disabled={isLoading}>
            <Spinner display={isLoading}/>
              Confirm Reset
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ResetSlackModal
