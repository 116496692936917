import React, { useState, useEffect } from 'react';
import { Input, Button, Label, Textarea, Select } from '@windmill/react-ui';
import { useSelector } from 'react-redux'
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import axios from "axios";
import toast from 'react-hot-toast';
import { AdjustmentsIcon } from '@heroicons/react/outline';
import Spinner from '../Buttons/Spinner'
import Toggle from '../Toggle/Toggle';
import store from '../../store';
import teamSlice from '../../store/slices/team';





function ConfigsModal(props) {

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/auth/account'
  const devices_url = BACKEND_URL + '/v1/ops/device'
  const teamConfigs = useSelector(state => state.team.team.configs)
  const id = store.getState().team.team.id

  const [window_size, setWindowSize] = useState()
  const [browser, setBrowser] = useState()
  const [device, setDevice] = useState()
  const [location, setLocation] = useState('us')
  const [deviceId, setDeviceId] = useState(6)
  const [devices, setDevices] = useState(null)
  const [mask_ids, setMaskIds] = useState('')
  const [interval, setInterval] = useState()
  const [timeout, setTimeout] = useState()
  const [min_wait_time, setMinTime] = useState()
  const [max_wait_time, setMaxTime] = useState()
  const [disableAnimations, setDisableAnimation] = useState()
  const [autoHeight, setAutoHeight] = useState()
  const [createIssue, setCreateIssue] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDevices();
    setWindowSize(teamConfigs ? teamConfigs.window_size : '1920,1080')
    setBrowser(teamConfigs ? (teamConfigs.browser !== undefined ? teamConfigs.browser : 'chrome') : 'chrome')
    setDevice(teamConfigs ? teamConfigs.device : 'Windows 10 PC')
    setLocation(teamConfigs ? (teamConfigs.location !== undefined ? teamConfigs.location : 'us') : 'us')
    setMaskIds(teamConfigs ? teamConfigs.mask_ids : '')
    setInterval(teamConfigs ? teamConfigs.interval : 1)
    setTimeout(teamConfigs ? teamConfigs.timeout : 300)
    setMinTime(teamConfigs ? teamConfigs.min_wait_time : 3)
    setMaxTime(teamConfigs ? teamConfigs.max_wait_time : 60)
    setDisableAnimation(teamConfigs ? teamConfigs.disable_animations : false)
    setAutoHeight(teamConfigs ? teamConfigs.auto_height : true)
    setCreateIssue(teamConfigs ? teamConfigs.create_issue : true)
  }, [])


  const getDevices = async () => {
    await axios.get(devices_url)
      .then((r) => {
        let _devices = r.data.devices
        let _device = null
        setDevices(_devices)
        for (var i in _devices) {
          if (_devices[i].browser === teamConfigs.browser && 
            _devices[i].name === teamConfigs.device) {
            _device = _devices[i]
          }
        }
        if (_device){
          setDeviceId(_device.id)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const updateConfigs = () => {

    const configs = {
      "window_size": window_size.length > 0 ? window_size : '1920,1080',
      "browser": browser,
      "device": device,
      "location": location,
      "interval": interval != null ? interval : 1,
      "mask_ids": mask_ids,
      "max_wait_time": max_wait_time != null ? max_wait_time : 60,
      "min_wait_time": min_wait_time != null ? min_wait_time : 3,
      "timeout": timeout != null ? timeout : 300,
      "disable_animations": disableAnimations,
      "auto_height": autoHeight,
      "create_issue": createIssue
    }
    
    var data = {
      "id": id,
      "configs": configs,
    }
  
    setIsLoading(true);

    axios.post(url, data)
    .then((r) => {
      if (r.data) {
        store.dispatch(teamSlice.actions.setTeam(r.data))
        setIsLoading(false);
        setIsModalOpen(false);
        toast.success(`Configs Updated!`)
      }
    })
    .catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    })

    

    
  }


  const ButtonType = () => {
    if(props.type==='icon'){
      return(
          <Button onClick={openModal} layout="link" size="icon" aria-label="Test">
            <AdjustmentsIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
      )
    }else if(props.type==='button'){
      return (
        <div>
          <Button  onClick={openModal} iconLeft={AdjustmentsIcon} layout='outline'>
            Configs
          </Button>
        </div>
      )
    }else if(props.type==='text'){
      return (
        <span onClick={openModal} className="cursor-pointer">
            { props.text ? props.text : 'New Test' }
        </span>
      )
    }else if(props.type==='dropdown'){
      return (
        <DropdownItem tag="a" onClick={openModal} className="z-40 relative">
          <AdjustmentsIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Account Configs</span>
        </DropdownItem>
      )
    }
  }



  return (
    <>

      {ButtonType()}

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Account Configs'}>
        
        <ModalBody>
        
          <div className='overflow-auto px-3'>

            <div className='p-4 rounded-md border-[1.5px] dark:border-gray-600 border-gray-400'>
              <div className='mb-4'>
                <Label className="mb-2">Location</Label>
                <Select className="rounded-md p-2 mt-1"
                  value={location}
                  onChange={(e) => {
                    setLocation(e.target.value)
                  }}
                >
                  <option value="us">United States</option>
                  <option value="eu">Europe</option>
                  <option value="au" disabled={true} className='cursor-not-allowed'>Austraila</option>
                  <option value="ca" disabled={true} className='cursor-not-allowed'>Canada</option>
                </Select>
              </div>
              <div className='grid gap-6 grid-cols-2'>
                <div className='mb-4'>
                  <Label className="mb-2">Browser</Label>
                  <Select className="rounded-md p-2 mt-1"
                    value={browser}
                    onChange={(e) => {
                      setBrowser(e.target.value)
                      for (var i in devices) {
                        if (devices[i].browser === e.target.value && 
                          devices[i].name === device) {
                            setDeviceId(devices[i]['id'])
                        }
                      }
                    }}
                  >
                    <option value="chrome">Chrome</option>
                    <option value="firefox">Firefox</option>
                    <option value="edge">Edge</option>
                  </Select>
                </div>
                <div className='mb-4'>
                  <Label className="mb-2">Device</Label>
                  <Select className="rounded-md p-2 mt-1"
                    value={deviceId}
                    onChange={(e) => {
                      let _device = devices.find(item => item.id === e.target.value)
                      setDeviceId(_device.id)
                      setDevice(_device.name)
                      setWindowSize(_device.window_size)
                    }}
                  >
                    {devices && devices.map((item, i) => (
                      item.browser === browser && 
                        <option
                          key={i} 
                          value={item.id} 
                        >
                          {item.name}
                        </option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className='grid gap-4 mb-8 grid-cols-2'>
                <div className='grid grid-cols-2 gap-3'>

                  <Label className="mt-4">
                    <span className='sm:block hidden'>Interval (s)</span>
                    <span className='sm:hidden block'>Interval</span>
                    <Input 
                      className='rounded-md p-2 max-w-[6rem]'
                      onChange={e => setInterval(e.target.value)} 
                      value={interval}
                      type='number'  
                      placeholder={5}/>
                  </Label>

                  <Label className="mt-4">
                    <span className='sm:block hidden'>Timeout (s)</span>
                    <span className='sm:hidden block'>Timeout</span>
                    <Input 
                      className='rounded-md p-2 max-w-[6rem]'
                      onChange={e => setTimeout(e.target.value)} 
                      value={timeout}
                      type='number'  
                      placeholder={300}/>
                  </Label>

                </div>
                <div className='grid grid-cols-2 gap-3'>

                  <Label className="mt-4">
                    <span className='sm:block hidden'>Min Wait (s)</span>
                    <span className='sm:hidden block'>Min Wait</span>
                    <Input
                      className='rounded-md p-2 max-w-[6rem]' 
                      onChange={e => setMinTime(e.target.value)} 
                      value={min_wait_time}
                      type='number'  
                      placeholder={10}/>
                  </Label>

                  <Label className="mt-4">
                    <span className='sm:block hidden'>Max Wait (s)</span>
                    <span className='sm:hidden block'>Max Wait</span>
                    <Input 
                      className='rounded-md p-2 max-w-[6rem]'
                      onChange={e => setMaxTime(e.target.value)} 
                      value={max_wait_time}
                      type='number'  
                      placeholder={60}/>
                  </Label>

                </div>
                

                <Label className="mt-4">
                  <span>Window Size (x,y)</span>
                  <Input
                    className='rounded-md p-2' 
                    onChange={e => setWindowSize(e.target.value)} 
                    value={window_size} 
                    placeholder={'1920,1080'}/>
                </Label>

                <div>
                  <div className="mt-6">
                    <div className='flex justify-end mt-2'>
                      <span className='mr-4 text-sm text-gray-800 dark:text-gray-400 text-right'>Disable Animations</span>
                      <div>
                        <Toggle id='test-disable-animations' small checked={disableAnimations} onChange={() => setDisableAnimation(!disableAnimations)} />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                      <div className='flex justify-end mt-2'>
                        <span className='mr-4 text-sm text-gray-800 dark:text-gray-400'>Auto Height</span>
                        <div>
                          <Toggle id='test-auto-height' small checked={autoHeight} onChange={() => setAutoHeight(!autoHeight)} />
                        </div>
                      </div>
                  </div>
                  <div className="mt-2">
                      <div className='flex justify-end mt-2'>
                        <span className='mr-4 text-sm text-gray-800 dark:text-gray-400'>Create Issue</span>
                        <div>
                          <Toggle id='test-create-issue' small checked={createIssue} onChange={() => setCreateIssue(!createIssue)} />
                        </div>
                      </div>
                  </div>
                </div>

              </div>

              <Label className="mt-2 mb-4">
                <span>Element ID's to Mask (seperated by commas)</span>
                <Textarea 
                  className='rounded-md p-2 '
                  onChange={e => setMaskIds(e.target.value)} 
                  value={mask_ids}
                  type='text'  
                  rows='2'
                  placeholder={'example-id-1, example-id-2'}/>
              </Label>
            </div>

          </div>

        </ModalBody>
        
        
        
        
        <ModalFooter>

          <div className="hidden sm:block">
            <Button layout="outline" onClick={updateConfigs} disabled={isLoading}>
            <Spinner display={isLoading}/>
              {'Update'}
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={updateConfigs} disabled={isLoading}>
            <Spinner display={isLoading}/>
              {'Update'}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ConfigsModal


