import React, {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router';
import PageTitle from '../components/Typography/PageTitle';
import DeltasCard from "../components/Cards/DeltasCard";
import HtmlCard from '../components/Cards/HtmlCard'
import ImageCompareCard from '../components/Cards/ImageCompareCard/ImageCompareCard'
import ErrorsCard from '../components/Cards/ErrorsCard'
import TestScoreCard from '../components/Cards/TestScoreCard';
import BackButton from '../components/Buttons/BackButton';
import DeleteTestModal from '../components/Modals/DeleteTestModal';
import Gear from '../components/Buttons/Gear';
import axios from "axios";
import Loader from '../components/Buttons/Loader';
import IDCard from '../components/Cards/IDCard';





function TestDetail(props) {

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const { id } = useParams();
  const getTestUrl = `${BACKEND_URL}/v1/ops/test/${id}`;
  const getPageUrl = `${BACKEND_URL}/v1/ops/page/`;
  const [test, setTest] = useState(null);
  const [htmlDelta, setHtmlDelta] = useState(null);
  const [page, setPage] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    const loadData = async () => {
      // get test info
      await axios.get(getTestUrl)
      .then(async (r) => {
        setTest(r.data);
        await axios.get(`${getPageUrl}${r.data.page}`)
          .then((r) => {
            setPage(r.data)
          }).catch((err) => {
            console.log(err)
          });
        await getHtmlDelta(r.data);
      })
      .catch((err) => {
        console.log(err)
        window.location.href = '/404';
      });
    }
    loadData();
    
  },[getTestUrl, getPageUrl])



  async function getTest() {
    await axios.get(getTestUrl).then((r) => {setTest(r.data);})
  }


  useEffect(() => {
    const interval = setInterval(() => {
      getTest();
    }, 20000);
    return () => {
      clearInterval(interval);
    };
     // eslint-disable-next-line
  }, []);


  const getHtmlDelta = async (test) => {
    if (test.html_delta){
      await fetch(test.html_delta)
        .then(r => r.json())
        .then((out) => {
          setHtmlDelta(out)
        })
        .catch((e) => {console.log(e)})

    }
  }

  
  const getTestStatus = () => {
    if (test.time_completed === null){
      return (<span className='text-gray-700 dark:text-white bg-gray-200 dark:bg-gray-700 inline-flex px-2 text-xs font-medium leading-5 rounded-full'>Working</span>)
    }
  }

  const urlLength = () => {
    var text_size = 'text-2xl'
    var len = page.page_url.length
    if (len >= 50 ){
      var text_size = 'text-xl'
    }else if (len > 36 && len < 50){
      var text_size = 'text-xl'
    }else if (len < 50){
      var text_size = 'text-2xl'
    }
    return text_size
  }



  if (test === null || page === null) {
    return <Loader item={'Test Data'}/>
  }



  return (
    <>
      <div className='flex justify-between'>
        <PageTitle>
          <div className="flex">
            <div>
              <div className='flex justify-start'>
                <div>
                  <span className='text-center px-2 py-1 rounded-full text-[11px] bg-cyan-200 dark:bg-cyan-600 font-semibold dark:text-white text-cyan-700'>
                    TEST
                  </span>
                </div>
                <h1 className='font-semibold text-gray-400 dark:text-gray-500 text-[.9rem] ml-3 mt-1'>
                  {new Date(test.time_created).toLocaleDateString()}
                </h1>
              </div>
             
              <div onClick={() => navigate(`/page/${page.id}`)}>
                <p className={`hover:underline cursor-pointer max-w-lg ${urlLength()}`}>{page.page_url}</p>
              </div>
              <div className='mt-1'>
                <IDCard resource={'test'} id={test.id}/>
              </div>
              <div className='flex justify-start mt-2'>
                <div className='-mt-[.3rem] flex'>
                  <div className='mr-1'>{getTestStatus()}</div>
                  {!test.time_completed && <div className='mt-2 ml-1'><Gear/></div>}
                  <div className='md:hidden block'>
                    <DeleteTestModal type='icon' test={test} callBack={() => {navigate(`/page/${test.page}`)}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageTitle>

        <div className='mt-8 md:block hidden'>
          <DeleteTestModal type='button' test={test} callBack={() => {navigate(`/page/${test.page}`)}}/>
        </div>
      </div>
      
      <BackButton />
      
      <div className='mt-4'>
        <TestScoreCard test={test} />
      </div>
      
      
      <DeltasCard  test={test ? test : null} />
      
      <div className='grid gap-8 mb-8 md:grid-cols-2 grid-cols-1'>
        <div className="md:m-6">
          <ErrorsCard errors={test.logs_delta ? test.logs_delta.pre_logs_delta : null} card_title='Errors missing in scan 2'/>
        </div>
        <div className="md:m-6">
          <ErrorsCard errors={test.logs_delta ? test.logs_delta.post_logs_delta : null} card_title='Errors missing in scan 1'/>
        </div>
      </div>
      <div className='grid gap-6 mb-8 md:grid-cols-2 grid-cols-1' >
        <div>
          <HtmlCard 
            parsed={htmlDelta ? htmlDelta.pre_micro_delta.delta_parsed : null}
            diff={htmlDelta ? htmlDelta.pre_micro_delta.delta_parsed_diff : null}
            card_title='Code in scan 1 missing in scan 2' 
          />
        </div>
        <div>
          <HtmlCard 
            parsed={htmlDelta ? htmlDelta.post_micro_delta.delta_parsed : null}
            diff={htmlDelta ? htmlDelta.post_micro_delta.delta_parsed_diff : null}
            card_title='Code in scan 2 missing in scan 1' 
          />
        </div>
      </div>
      <div>
        {test.type.includes('vrt') && 
          <ImageCompareCard
            test={test} 
            card_title='Image Comparison'  
          />
        }
      </div>

      
      

    </>
  )
}

export default TestDetail
