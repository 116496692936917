import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { LeftArrow } from '../../icons'


function BackButton(props) {

    const [hover, setHover] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    const nav = () => {
        if (props.link){
            navigate(props.link)
        }else if(location.key !== 'default'){
            navigate(-1)
        } else (
            navigate('/')
        )
    }

    return(
        <>
            <div 
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={nav}
                className="flex cursor-pointer text-sm mb-1 -mt-2 dark:hover:text-blue-300 hover:text-blue-600 dark:text-gray-100 text-gray-600 max-w-[8rem]"

            >
                <div className={`${hover ? '-translate-x-[.1rem]' : 'translate-x-[.1rem]'} ease-in-out duration-200 -mt-[.1rem]`}>
                    <LeftArrow /> 
                </div>
                <div>
                    <p className="ml-2">
                        {props.text ? props.text : 'go back'}
                    </p>
                </div>
            </div>
        </>
    )
}


export default BackButton