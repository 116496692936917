import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router';
import PageTitle from '../components/Typography/PageTitle'
import BackButton from '../components/Buttons/BackButton';
import StepsCard from '../components/Cards/StepsCard';
import StepCard from '../components/Cards/StepCard';
import DeleteTestcaseModal from '../components/Modals/DeleteTestcaseModal'
import Gear from '../components/Buttons/Gear';
import { useParams } from 'react-router-dom'
import { Badge } from '@windmill/react-ui'
import axios from 'axios'
import Loader from '../components/Buttons/Loader';
import IDCard from '../components/Cards/IDCard';





function TestcaseDetail() {
    
    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const testcaseUrl = `${BACKEND_URL}/v1/ops/testcase`;
    const getSiteUrl = `${BACKEND_URL}/v1/ops/site/`;
    const [testcase, setTestcase] = useState(null);
    const [site, setSite] = useState(null);
    const [step, setStep] = useState(null);
    const [stepIndex, setStepIndex] = useState(0)
    const { id } = useParams();
    const navigate = useNavigate();


    const loadData = async () => {
        // get testcase info
        if (id){
            await axios.get(`${testcaseUrl}/${id}`)
            .then((r) => {
                setTestcase(r.data)
                axios.get(`${getSiteUrl}${r.data.site}`)
                    .then((r) => {
                        setSite(r.data)
                    }).catch((err) => {
                        console.log(err)
                    });
            })
            .catch((err) => {
                console.log(err)
    
            });
        }
      }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line 
      },[])


    const getStep = (newstep, i) => {
        setStep(newstep)
        setStepIndex(i)
    }
    

    useEffect(() => {
        const interval = setInterval(() => {
            loadData();
        }, 5000);
        return () => {
          clearInterval(interval);
        };
         // eslint-disable-next-line
      }, []);


    const urlLength = () => {
        var text_size = 'text-2xl'
        var len = site ? site.site_url.length : ''
        if (len >= 50 ){
            var text_size = 'text-xl'
        }else if (len > 36 && len < 50){
            var text_size = 'text-xl'
        }else if (len < 50){
            var text_size = 'text-2xl'
        }
        return text_size
    }


    const getDurration = (start, end) => {
        let now = new Date;
        let utc = Date.UTC(
            now.getUTCFullYear(), 
            now.getUTCMonth(), 
            now.getUTCDate(), 
            now.getUTCHours(), 
            now.getUTCMinutes(), 
            now.getUTCSeconds(), 
            now.getUTCMilliseconds()
        );
        let startTime = start ? new Date(start).getTime() : utc
        let endTime = end ? new Date(end).getTime() : utc
        let durration = Math.round((endTime - startTime) / 1000)
        return durration
    }


    if (!testcase || !site){
        return <Loader item={'Testcase'}/>
    }



    return (
        <>
        <div className='grid md:grid-cols-3 gap-2 mb-2'>
            <PageTitle>
                <div className="flex">
                    <div className=''>
                        <div className='flex justify-start'>
                            <div>
                                <span className='text-center px-2 py-1 rounded-full text-[11px] bg-gray-200 dark:bg-gray-600 font-semibold dark:text-white text-gray-700'>
                                    TESTCASE
                                </span>
                            </div>
                            <h1 className='font-semibold text-gray-400 dark:text-gray-500 text-[.9rem] ml-3 mt-1'>
                                {new Date(testcase.time_created).toLocaleDateString()}
                            </h1>
                        </div>
                        <h3 onClick={() => navigate(`/site/${site.id}`)} className={`hover:underline cursor-pointer max-w-lg ${urlLength()}`}>{site ? site.site_url : ''}</h3>
                        <div className='mt-1'>
                            <IDCard resource={'testcase'} id={testcase.id}/>
                        </div>
                        <div className='md:hidden block'>
                            <DeleteTestcaseModal type='icon' testcase={testcase} callBack={() => {navigate(`/site/${testcase.site}`)}}/>
                        </div>
                    </div>
                </div>
            </PageTitle>
            <div className='justify-self-center w-full md:mt-10 md:mb-0 mb-12'>
                <div className='py-3 px-4 border-2 border-gray-300 dark:border-gray-400 w-full md:w-auto rounded-lg'>
                    <div className='flex justify-between'>
                        <div className='flex justify-start'>
                            <h3 className='mr-2 font-semibold text-gray-600 dark:text-gray-300'>Status:</h3> 
                            <div>
                                { !testcase.time_completed ?
                                    <span className='text-gray-700 dark:text-white bg-gray-200 dark:bg-gray-700 inline-flex px-2 text-xs font-medium leading-5 rounded-full'>working <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/></span>
                                :
                                    <Badge type={testcase.status === 'passed' ? 'success' : 'danger'}>{testcase.status === 'passed' ? 'Passed' : 'Failed'}</Badge>

                                }
                            
                            </div>
                        </div>

                        <div className=' flex justify-start'>
                            <h3 className='mr-2 font-semibold text-gray-600 dark:text-gray-300'>Steps:</h3> 
                            <span className='text-sm font-semibold px-2 py-1 dark:bg-gray-800 bg-gray-200 dark:text-white rounded-md text-center w-auto text-wrap'>
                                <pre className='text-pretty max-w-64 text-left'><code>{testcase.steps.length}</code></pre>
                            </span>
                        </div>

                        <div className=' flex justify-start'>
                            <h3 className='mr-2 font-semibold text-gray-600 dark:text-gray-300'>Time:</h3> 
                            <span className='text-sm font-semibold px-2 py-1 dark:bg-gray-800 bg-gray-200 dark:text-white rounded-md text-center w-auto text-wrap'>
                                <pre className='text-pretty max-w-64 text-left'><code>{getDurration(testcase.time_created, testcase.time_completed)}<span className='text-xs'>s</span></code></pre>
                            </span>
                        </div>

                    </div>
                    <div className='flex justify-between mt-3'>
                        <div className='flex justify-start '>
                            <h3 className='mr-4 text-lg font-semibold text-gray-600 dark:text-gray-300'>Case:</h3>
                            <a href={`/case/${testcase.case}`} className='cursor-pointer text-sm font-semibold px-2 py-1 dark:bg-gray-800 bg-gray-200 dark:text-white rounded-md text-center w-auto text-wrap'>
                                <pre className='text-pretty max-w-64 text-left'><code>{testcase.case_name}</code></pre>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className='mt-10 md:block hidden ml-1 justify-self-end'>
                <DeleteTestcaseModal type='button' testcase={testcase} callBack={() => {navigate(`/site/${testcase.site}`)}}/>
            </div>
        </div>

        <BackButton site_id={testcase.site}/>

        <div className='grid md:grid-cols-2 gap-6 mb-8 relative mt-4'>
            <div className='md:mb-36 mb-6'>
                {/* listed steps */}
                <StepsCard testcase={testcase} callBack={getStep}/>
            </div>
            <div className='relative'>
                {/* step details card */}
                <StepCard step={step ? step : testcase.steps[0]} stepIndex={stepIndex}/>
            </div>
        </div>
        
        </>
    )
}

export default TestcaseDetail