import React, { useState, useEffect } from 'react';
import { Input, Button, Label } from '@windmill/react-ui';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import axios from "axios";
import toast from 'react-hot-toast';
import { CubeTransparentIcon, XIcon } from '@heroicons/react/outline'; 
import Spinner from '../Buttons/Spinner'








function AutoCaseModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/case/auto'
  const searchUrl = BACKEND_URL + '/v1/ops/search?query='
  
  const [sites, setSites] = useState(null);
  const [site, setSite] = useState(null);
  const [max_cases, setMaxCases] = useState(3);
  const [max_layers, setMaxLayers] = useState(5);
  const [window_size, setWindowSize] = useState('1920,1080');
  const [start_url, setStartUrl] = useState(site ? site.str : '');
  const [device, setDevice] = useState('desktop')
  const [interval, setInterval] = useState(1)
  const [min_wait_time, setMinTime] = useState(3)
  const [max_wait_time, setMaxTime] = useState(30)
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfigs, setShowConfigs] = useState(false);

  useEffect(() => {
    if (site){
      setStartUrl(site.str)
    }
  },[site])


  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const search = async (q) => {
    if (q !== '') {
        await axios.get(`${searchUrl}site:${q}`)
            .then((r) => {
                setSites(r.data)
            })
            .catch((e) => {
                console.log(e)
            })
    } else {
        console.log(query)
    }
  }



  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (!start_url.startsWith(site.str)){
      toast.error('start_url must begin with site url')
      return
    }

    var configs = {
      'window_size': window_size,
      'device': device,
      'interval': interval,
      'max_wait_time': max_wait_time, 
      'min_wait_time': min_wait_time
    }

    const data = {
      "site_id": site.id,
      "start_url": start_url.startsWith(site.name) ? start_url : site.name,
      "configs": configs,
      "max_cases": Number(max_cases),
      "max_layers": Number(max_layers),
    }

    setIsLoading(true);

    axios.post(`${url}`, data)
    .then((res) => {
      if (res.data) {
        setIsLoading(false);
        setIsModalOpen(false);
        toast.success('Generating New Cases');
        props.callBack();
      }
    })
    .catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    })
  }


  const ButtonType = () => {
    if(props.type==='icon'){
      return(
          <Button onClick={openModal} layout="link" size="icon" aria-label="Generate Cases">
            <CubeTransparentIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
      )
    }else if(props.type==='button'){
      return (
        <div>
          <Button className='w-[9rem]' onClick={openModal} iconRight={CubeTransparentIcon} layout='outline'>
            Generate Cases
          </Button>
        </div>
      )
    }else if(props.type==='text'){
      return (
        <span onClick={openModal} className="cursor-pointer">
            { props.text ? props.text : 'Generate Cases' }
        </span>
      )
    }
    else if(props.type==='dropdown'){
      return (
        <DropdownItem tag="a" onClick={openModal} className="z-40 relative">
          <CubeTransparentIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Generate</span>
        </DropdownItem>
      )
    }
  }



  return (
    <>

      <div>{ButtonType()}</div>

      <Modal 
        isOpen={isModalOpen} 
        onClose={closeModal}
        style={{ maxHeight: '100vh', position: 'absolute', overflow: 'scroll', display:'block', marginLeft: '0rem', marginRight: '0rem' }}
        title={'Generate New Cases'}
      >
        <div id='autocase-modal'>
        <ModalBody style={{ minHeight: open ? '15rem' : '8rem', }} onClick={e=>e.nativeEvent.stopImmediatePropagation()}>
        <div className='overflow-auto mx-0 px-4' style={{ maxHeight: '30rem',  minHeight: open ? '12rem' : '6rem'}}>
          <div className={`relative ${open ? 'h-[5rem]' : ''}`}>

            {!site && 
              <Label className="w-full">
                <span className='mb-2'>Site</span>
                <input 
                  className='block text-sm focus:outline-none dark:text-gray-300 form-input leading-5 focus:border-blue-400 dark:border-gray-600 focus:shadow-outline-blue dark:focus:border-gray-600 dark:focus:shadow-outline-gray dark:bg-gray-700 rounded-md p-2 w-full'
                  placeholder='search for a site'
                  value={query}
                  onChange={(e) => {
                      setQuery(e.target.value === '/' ? '' : e.target.value)
                      if (e.target.value !== '') {
                          setOpen(true)
                          search(e.target.value);
                          setQuery(e.target.value)
                      } else {
                          setOpen(false)
                      }
                  }}
                />
              </Label>
            }

            {query.length >= 1 && open && !site &&
              <div className={`w-full absolute z-30 mx-auto shadow rounded-b-lg p-4 bg-white dark:bg-gray-700 dark:text-gray-300 text-gray-600 overflow-x-scroll`}>

                  { sites && 

                      sites.slice().map((resource, i) => (
                        <div key={i}>
                          {resource.type !== 'case' && resource.type !== 'issue' &&
                            <div 
                                className="flex justify-start px-2 py-2 dark:hover:bg-gray-600 hover:bg-gray-100 my-2 rounded-md cursor-pointer" 
                                onClick={() => {
                                    setSite(resource)
                                    setQuery('')
                                    setOpen(false)
                                }}
                            >   
                                <div className='mr-3 my-auto'>
                                    <div className={`text-center px-2 py-1 rounded-full text-[8px] ${resource.type === 'site' ? 'dark:bg-blue-600 bg-blue-100' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                        <span className={`font-bold ${resource.type === 'site' ? 'dark:text-white text-blue-700' : 'dark:text-white text-gray-700'} `}>
                                            {resource.type.toUpperCase()}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="font-semibold text-sm">
                                        {resource.str}
                                    </h2>
                                </div>
                            </div> 
                          }
                        </div>
                      ))

                  }

              </div>
            }

            </div>
                      
            {site && !open &&

            <label className=''>
              <span className='text-sm text-gray-700 dark:text-gray-400 '>Site:</span>
              <div className='flex justify-between px-2 py-2 dark:bg-gray-700 bg-white rounded-xl my-auto ring-1 dark:ring-0'>
                <div className='mr-2 my-auto'>
                  <div className="flex justify-start  cursor-auto">   
                    <div className='mr-3 my-auto'>
                      <div className={`text-center px-2 py-1 rounded-full text-[8px] ${site.type === 'site' ? 'dark:bg-blue-600 bg-blue-100' : 'bg-gray-200 dark:bg-gray-600'}`}>
                        <span className={`font-bold ${site.type === 'site' ? 'dark:text-white text-blue-700' : 'dark:text-white text-gray-700'} `}>
                            {site.type.toUpperCase()}
                        </span>
                      </div>
                    </div>
                    <div>
                      <h2 className="font-semibold text-sm text-gray-700 dark:text-gray-300 max-w-[12rem] truncate">
                        {site.str}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className=''>
                  <div 
                    onClick={() => {
                      setSite(null)
                      setQuery('')
                    }} 
                    className='cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-6 h-6 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white my-auto'
                  >
                    <XIcon className="w-3 h-3" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </label>

            }

          <div>
            <div className='mt-12'>
              <div className='flex justify-between mb-2'>
                <label htmlFor="cases-range" className="my-auto block mb-2 text-sm text-gray-800 dark:text-gray-400">How many Cases do you want to generate?</label>
                <span className='mr-2 text-lg font-bold text-gray-600 dark:text-gray-400 rounded-lg ring-2 ring-blue-600 px-2 py-1'>
                  {max_cases}
                </span>
              </div>
              <input 
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 accent-blue-600"
                id="cases-range" 
                type="range" 
                min="1" 
                max="10" 
                value={max_cases}
                onChange={(e) => setMaxCases(e.target.value)}
              >
              </input>
            </div>
            <div className='my-6'>
              <div className='flex justify-between mb-2'>
                <label htmlFor="layers-range" className="my-auto block mb-2 text-sm text-gray-800 dark:text-gray-400">How many Steps do you want to targert per Case?</label>
                <span className='mr-2 text-lg font-bold text-gray-600 dark:text-gray-400 rounded-lg ring-2 ring-blue-600 px-2 py-1'>
                  {max_layers}
                </span>
              </div>
              <input 
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 accent-blue-600"
                id="layers-range" 
                type="range" 
                min="1" 
                max="10" 
                value={max_layers} 
                onChange={(e) => setMaxLayers(e.target.value)}
              >
              </input>
            </div>
          </div>
          <div className='mb-4 mt-4' onClick={e=>e.nativeEvent.stopImmediatePropagation()}>
            <div className='mt-8'>
              <span
                className='text-sm underline text-blue-600 cursor-pointer w-auto'
                onClick={() => setShowConfigs(!showConfigs)}
              >
                {showConfigs ? 'Hide' : 'Show'} Advanced
              </span>
            </div>
            {showConfigs &&
              <div>
                <div className='grid gap-6 grid-cols-2'>
                  <div className='mb-4 mt-6'>
                    <Label className="mb-2">Start Url
                      <Input 
                        className='rounded-md p-2 w-full' 
                        onChange={e => setStartUrl(e.target.value)} 
                        value={start_url} 
                        placeholder={site ? site.str : 'https://example.com/blog'}
                      />
                    </Label>
                  </div>
                  <div className='mb-4 mt-6'>
                    <Label className="mb-2">Device</Label>
                    <Label radio>
                      <Input 
                        type="radio" 
                        value="desktop" 
                        checked={device === 'desktop' ? true : false} 
                        onChange={(e) => {
                          setDevice(e.target.value)
                        }}
                      />
                      <span className="ml-2">Desktop</span>
                    </Label>
                    <Label className="ml-6" radio>
                      <Input 
                        type="radio" 
                        value="mobile" 
                        checked={device === 'mobile' ? true : false} 
                        onChange={(e) => {
                          setDevice(e.target.value)
                        }}
                      />
                      <span className="ml-2">Mobile</span>
                    </Label>
                  </div>
                </div>

                <div className='grid gap-6 mb-8 grid-cols-2 ' >
                  <div>
                    <Label className="mt-4">
                      <span>Interval (sec)</span>
                      <Input 
                        className='rounded-md p-2'
                        onChange={e => setInterval(e.target.value)} 
                        value={interval}
                        type='number'  
                        placeholder={1}/>
                    </Label>

                    <Label className="mt-4">
                      <span>Window Size</span>
                      <Input
                        className='rounded-md p-2' 
                        onChange={e => setWindowSize(e.target.value)} 
                        value={window_size} 
                        placeholder={'1920,1080'}/>
                    </Label>

                  </div>
                  <div>

                    <Label className="mt-4">
                      <span>Min Wait Time (sec)</span>
                      <Input
                        className='rounded-md p-2' 
                        onChange={e => setMinTime(e.target.value)} 
                        value={min_wait_time}
                        type='number'  
                        placeholder={3}/>
                    </Label>

                    <Label className="mt-4">
                    <span>Max Wait Time (sec)</span>
                      <Input 
                        className='rounded-md p-2'
                        onChange={e => setMaxTime(e.target.value)} 
                        value={max_wait_time}
                        type='number'  
                        placeholder={60}/>
                    </Label>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        </ModalBody>
        <ModalFooter onClick={e=>e.nativeEvent.stopImmediatePropagation()}>
          <div className="hidden sm:block pb-6">
            <Button layout="outline" onClick={handleSubmit} disabled={site ? isLoading : true}>
            <Spinner display={isLoading}/>
              {'Generate'}
            </Button>
          </div>
          <div className="block w-full sm:hidden pb-6">
            <Button block size="large" layout="outline" onClick={handleSubmit} disabled={site ? isLoading : true}>
            <Spinner display={isLoading}/>
              {'Generate'}
            </Button>
          </div>
        </ModalFooter>
        </div>
      </Modal>
    </>
  )
}

export default AutoCaseModal


