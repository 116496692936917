import toast from "react-hot-toast";
import Tooltip from "../Tooltip/Tooltip";



const IDCard = (props) => {

    const copy = () => {
        navigator.clipboard.writeText(props.id).then(function() {
            toast.success('Coppied!')
        }).catch(err => toast.error('Couldn\'t copy'))
    }


    return (
        
        <Tooltip content='click to copy' direction={'bottom'}>
            <span 
                className="w-auto rounded-md text-[.65rem] font-thin text-gray-500 dark:text-gray-400 cursor-pointer py-1 px-2 bg-gray-200/75 dark:bg-gray-800/75"
                onClick={copy}>
                <code className="pt-0 pb-0">
                    {props.resource}_id: {props.id}
                </code>
            </span>
        </Tooltip>  
    )
}


export default IDCard