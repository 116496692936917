import React, {useState, useEffect} from 'react';
import Prism from "prismjs";
import '../../assets/css/prism.style.css'
import { Card, CardBody, Button } from '@windmill/react-ui';




function PageCard(props) {

    

    const [code, setCode] = useState('block')
    const [image, setImage] = useState('none')
    const [html, setHtml] = useState('none')
    const [codeButton, setCodeButton] = useState('primary')
    const [imageButton, setImageButton] = useState('outline')

    useEffect(() => {
        Prism.highlightAll();
        getHtml();
    }, []);
    

    const getHtml = async () => {
        if (props.scan.html){
            await fetch(props.scan.html)
                .then(r => r.text())
                .then(out => setHtml(out))
                .catch((e) => {console.log(e)})
        }
      }


    function getCode(){
        setCode('block')
        setImageButton('outline')
        setCodeButton('primary')
        setImage('none')
    }

    function getImage(){
        setCode('none')
        setImageButton('primary')
        setCodeButton('outline')
        setImage('block')
    }


    return (
        <>
            <Card className="mt-4">
                <CardBody className="">
                <p className="mb-2 font-semibold text-gray-600 dark:text-gray-300">{props.card_title}</p>
                <div className="flex justify-arround mb-2">
                    <div className="m-4">
                        <Button
                            size="small"
                            layout={codeButton}
                            onClick={() => getCode()}
                        >
                            HTML
                        </Button>
                    </div>
                    <div className="m-4">
                        <Button
                            size="small"
                            layout={imageButton}
                            onClick={() => getImage()}
                        >
                            Images
                        </Button>
                    </div>
                </div>
                <div style={{ display: code }} className="mb-4">
                    <div style={{ maxHeight: '30rem' }} className="p-4 overflow-auto text-xs whitespace-pre-wrap text-gray-600 dark:text-gray-100">

                        {html ? <pre ><code>{html}</code></pre> : null}
                        
                    </div>
                </div>
                <div style={{ display: image }} className="mb-4">
                    <div style={{ maxHeight: '30rem' }} className="p-4 overflow-auto m-auto text-center">
                        {props.scan.images ? props.scan.images.map((image, i)=>(
                            
                            <img 
                                key={i}
                                src={image.url}
                                className='mt-0 mb-4'
                                alt='scan screeshot'
                            />


                        )) : 
                            
                            <h1 
                                className='text-2xl font-semibold text-gray-600 dark:text-gray-100'
                            >
                                No images in this scan
                            </h1>
                            
                        }
                    </div>
                </div>
                </CardBody>
            </Card>
        </>
    )
}

export default PageCard