import React, { useState, useEffect } from 'react'
import AuditsModal from '../Modals/AuditsModal';
import AuditsSlideModalYL from '../Modals/AuditsSlideModalYL';
import { Doughnut } from 'react-chartjs-2'
import {Badge} from '@windmill/react-ui'


function YellowlabChart(props) {

    const [pwgood, setPwGood] = useState(null);
    const [pwbad, setPwBad] = useState(null);
    const [imggood, setImgGood] = useState(null);
    const [imgbad, setImgBad] = useState(null);
    const [domgood, setDomGood] = useState(null);
    const [dombad, setDomBad] = useState(null);
    const [jcgood, setJcGood] = useState(null);
    const [jcbad, setJcBad] = useState(null);
    const [bjgood, setBjGood] = useState(null);
    const [bjbad, setBjBad] = useState(null);
    const [jqgood, setJqGood] = useState(null);
    const [jqbad, setJqBad] = useState(null);
    const [ccgood, setCcGood] = useState(null);
    const [ccbad, setCcBad] = useState(null);
    const [bcgood, setBcGood] = useState(null);
    const [bcbad, setBcBad] = useState(null);
    const [fngood, setFnGood] = useState(null);
    const [fnbad, setFnBad] = useState(null);
    const [scgood, setScGood] = useState(null);
    const [scbad, setScBad] = useState(null);
    const [audits, setAudits] = useState(null)


    const getAudits = async () => {
      if (typeof props.scan.yellowlab.audits === 'string'){
        await fetch(props.scan.yellowlab.audits)
            .then(r => r.json())
            .then(out => setAudits(out))
            .catch((e) => {console.log(e)})
      }else if (typeof props.scan.yellowlab.audits === 'object'){
        setAudits(props.scan.yellowlab.audits)
      }
    }

    const colorOne = '#0694a203'
    const colorTwo = '#1c64f2'
    const borderColor = '#9ca3af'

    useEffect(() => {
      if (props.scan.yellowlab !== null){
        if(props.scan.yellowlab.scores.globalScore !== null && props.scan.yellowlab.scores.globalScore !== '' ) {
          setPwGood(props.scan.yellowlab.scores.pageWeight < 0 ? 0 : props.scan.yellowlab.scores.pageWeight);
          setPwBad(100 - ( props.scan.yellowlab.scores.pageWeight < 0 ? 100 : props.scan.yellowlab.scores.pageWeight ));

          setImgGood(props.scan.yellowlab.scores.images < 0 ? 0 : props.scan.yellowlab.scores.images);
          setImgBad(100 - ( props.scan.yellowlab.scores.images < 0 ? 100 : props.scan.yellowlab.scores.images ));

          setDomGood(props.scan.yellowlab.scores.domComplexity < 0 ? 0 : props.scan.yellowlab.scores.domComplexity);
          setDomBad(100 - ( props.scan.yellowlab.scores.domComplexity < 0 ? 100 : props.scan.yellowlab.scores.domComplexity ));

          setJcGood(props.scan.yellowlab.scores.javascriptComplexity < 0 ? 0 : props.scan.yellowlab.scores.javascriptComplexity);
          setJcBad(100 - ( props.scan.yellowlab.scores.javascriptComplexity < 0 ? 100 : props.scan.yellowlab.scores.javascriptComplexity ));

          setBjGood(props.scan.yellowlab.scores.badJavascript < 0 ? 0 : props.scan.yellowlab.scores.badJavascript);
          setBjBad(100 - ( props.scan.yellowlab.scores.badJavascript < 0 ? 100 : props.scan.yellowlab.scores.badJavascript ));

          setJqGood(props.scan.yellowlab.scores.jQuery < 0 ? 0 : props.scan.yellowlab.scores.jQuery);
          setJqBad(100 - ( props.scan.yellowlab.scores.jQuery < 0 ? 100 : props.scan.yellowlab.scores.jQuery ));

          setCcGood(props.scan.yellowlab.scores.cssComplexity < 0 ? 0 : props.scan.yellowlab.scores.cssComplexity);
          setCcBad(100 - ( props.scan.yellowlab.scores.cssComplexity < 0 ? 100 : props.scan.yellowlab.scores.cssComplexity ));

          setBcGood(props.scan.yellowlab.scores.badCSS < 0 ? 0 : props.scan.yellowlab.scores.badCSS);
          setBcBad(100 - ( props.scan.yellowlab.scores.badCSS < 0 ? 100 : props.scan.yellowlab.scores.badCSS ));

          setFnGood(props.scan.yellowlab.scores.fonts < 0 ? 0 : props.scan.yellowlab.scores.fonts);
          setFnBad(100 - ( props.scan.yellowlab.scores.fonts < 0 ? 100 : props.scan.yellowlab.scores.fonts ));

          setScGood(props.scan.yellowlab.scores.serverConfig < 0 ? 0 : props.scan.yellowlab.scores.serverConfig);
          setScBad(100 - ( props.scan.yellowlab.scores.serverConfig < 0 ? 100 : props.scan.yellowlab.scores.serverConfig ));
        }
        getAudits();
      }
    },[props])



    const pwData = {
        data: {
          datasets: [
            {
              data: [pwgood, pwbad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'Page Weight',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const reqData = {
        data: {
          datasets: [
            {
              data: [imggood, imgbad,],
              backgroundColor: [colorTwo, colorOne],
              label: 'Images',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const domData = {
        data: {
          datasets: [
            {
              data: [domgood, dombad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'DOM Complexity',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const jcData = {
        data: {
          datasets: [
            {
              data: [jcgood, jcbad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'JS Complexity',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const bjData = {
        data: {
          datasets: [
            {
              data: [bjgood, bjbad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'Bad JS',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const jqData = {
        data: {
          datasets: [
            {
              data: [jqgood, jqbad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'jQuery',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const ccData = {
        data: {
          datasets: [
            {
              data: [ccgood, ccbad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'CSS Complexity',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const bcData = {
        data: {
          datasets: [
            {
              data: [bcgood, bcbad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'Bad CSS',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const fnData = {
        data: {
          datasets: [
            {
              data: [fngood, fnbad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'Fonts',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const scData = {
        data: {
          datasets: [
            {
              data: [scgood, scbad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'Server Config.',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }


      function getScore(score) {
        var value = 'no data'
        if(score!==null && score!==''){
          var score_int = Math.round(parseInt(score))
          value = score_int+'%'
        }
        return (
            <>
                <span className="text-gray-600 dark:text-gray-300">{value}</span>
            </>
        )
      }


      function getScoreStatus(score) {
        if(score!==null && score!==''){
          var badge = 'neutral'
          var health = 'no data'
          var score_int = Math.round(parseInt(score))
          if (score_int >= 75) {
            health = 'Good';
            badge = 'success';
          }else if (score_int >= 60 && score_int < 75) {
            health = 'Okay';
            badge = 'warning';
          }else {
            health = 'Poor';
            badge = 'danger';
          }
        }else{
            badge = 'neutral'
            health = 'no data'
        }
        return <div><Badge type={badge}>{health}</Badge></div>;
      }



  return (
    <>
            <div className='grid gap-1 mb-8 xl:grid-cols-5 md:grid-cols-3 lg:grid-cols-5 grid-cols-2 ml-12'>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...pwData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(pwgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300 text-sm" >
                      Page Weight
                      <div className='flex justify-center'>
                        <AuditsSlideModalYL type='icon' cat='Page Weight' audits={audits ? audits.pageWeight : null}/> 
                        {getScoreStatus(pwgood)}
                      </div>
                    </div>
                </div>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...reqData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(imggood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300 text-sm" >
                      Images
                      <div className='flex justify-center'>
                        <AuditsSlideModalYL type='icon' cat='Images' audits={audits ? audits.images : null}/> 
                        {getScoreStatus(imggood)}
                      </div>
                    </div>
                </div>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...domData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(domgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300 text-sm" >
                      DOM Complexity
                      <div className='flex justify-center'>
                        <AuditsSlideModalYL type='icon' cat='DOM Complexity' audits={audits ? audits.domComplexity : null}/> 
                        {getScoreStatus(domgood)}
                      </div>
                    </div>
                </div>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...jcData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(jcgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300 text-sm" >
                      JS Complexity
                      <div className='flex justify-center'>
                        <AuditsSlideModalYL type='icon' cat='JS Complexity' audits={audits ? audits.javascriptComplexity : null}/> 
                        {getScoreStatus(jcgood)}
                      </div>
                    </div>
                </div>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...bjData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(bjgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300 text-sm" >
                      Bad JS
                      <div className='flex justify-center'>
                        <AuditsSlideModalYL type='icon' cat='Bad JS' audits={audits ? audits.badJavascript : null}/> 
                        {getScoreStatus(bjgood)}
                      </div>
                    </div>
                </div>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...jqData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(jqgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300 text-sm" >
                      jQuery
                      <div className='flex justify-center'>
                        <AuditsSlideModalYL type='icon' cat='jQuery' audits={audits ? audits.jQuery : null}/> 
                        {getScoreStatus(jqgood)}
                      </div>
                    </div>
                </div>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...ccData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(ccgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300 text-sm" >
                      CSS Complexity
                      <div className='flex justify-center'>
                        <AuditsSlideModalYL type='icon' cat='CSS Complexity' audits={audits ? audits.cssComplexity : null}/> 
                        {getScoreStatus(ccgood)}
                      </div>
                    </div>
                </div>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...bcData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(bcgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300 text-sm" >
                      Bad CSS
                      <div className='flex justify-center'>
                        <AuditsSlideModalYL type='icon' cat='Bad CSS' audits={audits ? audits.badCSS : null}/> 
                        {getScoreStatus(bcgood)}
                      </div>
                    </div>
                </div>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...fnData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(fngood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300 text-sm" >
                      Fonts
                      <div className='flex justify-center'>
                        <AuditsSlideModalYL type='icon' cat='Fonts' audits={audits ? audits.fonts : null}/> 
                        {getScoreStatus(fngood)}
                      </div>
                    </div>
                </div>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...scData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(scgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300 text-sm" >
                      Server Configs
                      <div className='flex justify-center'>
                        <AuditsSlideModalYL type='icon' cat='Server Configs' audits={audits ? audits.serverConfig : null}/> 
                        {getScoreStatus(scgood)}
                      </div>
                    </div>
                </div>
            </div>
      
    </>
  )
}

export default YellowlabChart

