import React from 'react';
import './Highlight.style.css';


function Highlight(props)  {


    return (
        <>  
        
        {props.parsed &&
            props.parsed.map((line, i) => (
                
                props.diff.includes(line) ? 
                    
                    line.startsWith('<') ? 
                        
                        <span className='bg-blue-300 dark:bg-blue-600' key={i}><br></br>{line}</span>

                    :

                        line.endsWith('>') ?
                            
                            <mark className='bg-blue-300 dark:bg-blue-600' key={i}>{line}<br></br></mark>
                        :

                            <mark className='bg-blue-300 dark:bg-blue-600' key={i}>{line}</mark>

                :

                    line.startsWith('<') ?

                        <span className="language-html" key={i}><br></br>{line}</span>

                    :

                        line.endsWith('>') ?

                            <span className="language-html" key={i}>{line}<br></br></span>

                        :
                            <span className="language-html" key={i}>{line}</span>


                
            ))
        }
        {props.parsed ? null : <div className="text-gray-500 dark:text-gray-100">No differences to show!</div>}

        </>
    );

}


export default Highlight


// old method with highlighting strings

// {props.parsed &&
//     props.parsed.map((line, i) => (
//         <span className={props.diff.includes(line) ? "bg-blue-300 dark:bg-blue-600" : ""} key={i}>{line}</span>
//     ))
// }
// {props.parsed ? null : <div className="text-gray-500 dark:text-gray-100">No differences to show!</div>}




//  Trying to show diff with HTML format

// {props.parsed &&
//     props.parsed.map((line, i) => (
        
//         props.diff.includes(line) ? 
            
//             <mark className='bg-blue-300 dark:bg-blue-600' key={i}>{line}</mark>
        
//             :

//             <code className="language-html" key={i}>{line}</code>
        
        
//     ))
// }
// {props.parsed ? null : <div className="text-gray-500 dark:text-gray-100">No differences to show!</div>}