import {useState, useEffect} from 'react'
import { Button, Input } from '@windmill/react-ui';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { InformationCircleIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import Spinner from '../Buttons/Spinner';
import Loader from '../Buttons/Loader';
import BillingForm from '../Forms/BillingForm';
import Toggle from '../Toggle/Toggle';
import store from '../../store';
import billingSlice from '../../store/slices/billing';
import axios from 'axios';
import toast from 'react-hot-toast';
import Tooltip from '../Tooltip/Tooltip';







const BillingPlanCard = (props) => {
    
    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const account_url = `${BACKEND_URL}/v1/auth/account`
    const setup_url = `${BACKEND_URL}/v1/billing/subscription/setup`
    const get_sub_url = `${BACKEND_URL}/v1/billing/subscription/url`
    const info_url = `${BACKEND_URL}/v1/billing/info`
    const strip_key_url = `${BACKEND_URL}/v1/billing/stripe/key`

    const [key, setKey] = useState(null);
    const [interval, setInterval] = useState('month');
    const [plan, setPlan] = useState('free');
    const [priceAmount, setPriceAmount] = useState(null);
    const [maxSites, setMaxSites] = useState(1);
    const [maxPages, setMaxPages] = useState(3);
    const [maxSchedules, setMaxSchedules] = useState(0);
    const [retentionDays, setRetentionDays] = useState(15);
    const [testcases, setTestcases] = useState(0);
    const [scans, setScans] = useState(30);
    const [tests, setTests] = useState(30);
    const [coupon, setCoupon] = useState('');
    const [discount, setDiscount] = useState(0);
    const [viewCoupon, setViewCoupon] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [showSiteCount, setShowSiteCount] = useState(false)
    const [loading, setLoading] = useState(false);
    const [pageloading, setPageloading] = useState(true);
    const [type, setType] = useState('initial');
    const [showBasic, setShowBasic] = useState(false);
    const [showPro, setShowPro] = useState(false);
    const [showPlus, setShowPlus] = useState(false);
    const [showCustom, setShowCustom] = useState(false);
    const [subUrl, setSubUrl] = useState(null);


    // billing defaults
    const setupData = {
        'name': plan, 
        'interval': interval,
        'price_amount': priceAmount, 
        'max_sites': maxSites, 
        'max_pages': maxPages,
        'max_schedules': maxSchedules,
        'retention_days': retentionDays,
        'scans_allowed': scans,
        'tests_allowed': tests,
        'testcases_allowed': testcases,
        'meta': {
            'coupon': {
                'code': coupon,
                'discount': discount
            }
        }
        
    }

    useEffect(() => {
        // get current billing info
        preLoad()
    }, [])

    // load stripe key
    useEffect(() => {
        getKey()
    },[])

    const getKey = async () => {
        setKey(null)
        await axios.post(strip_key_url)
            .then((r) => {
                setKey(r.data.key);
            }).catch((err) => {
                console.log(err);
            });
    }

    const preLoad = async () => {
        await axios.post(info_url)
            .then((r) => {
                setInterval(r.data.plan.interval)
                if (r.data.plan.name === 'custom' || r.data.plan.name === 'enterprise'){
                    console.log(r.data)
                    setPlan(r.data.plan.name)
                    setPriceAmount(r.data.plan.price_amount)
                    setMaxPages(r.data.plan.max_pages)
                    setMaxSites(r.data.plan.max_sites)
                    setMaxSchedules(r.data.plan.max_schedules)
                    setRetentionDays(r.data.plan.retention_days)
                    setScans(r.data.plan.usage.scans_allowed)
                    setTests(r.data.plan.usage.tests_allowed)
                    setTestcases(r.data.plan.usage.tests_allowed)
                    if (r.data.plan.meta){
                        if (r.data.plan.meta.coupon){
                            setCoupon(r.data.plan.meta.coupon.code)
                            checkCoupon(r.data.plan.meta.coupon.code)
                        }
                    }
                    setPageloading(false);
                    
                    // get subUrl if plan.name == enterprise
                    if (r.data.plan.name === 'enterprise'){
                        axios.get(get_sub_url)
                            .then((r) => {
                                console.log(r.data)
                                setSubUrl(r.data.stripe_url)
                                setPageloading(false)
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    }
                    
                } else {
                    setBillingInfo(r.data.plan.name)
                    if (r.data.plan.meta){
                        if (r.data.plan.meta.coupon){
                            setCoupon(r.data.plan.meta.coupon.code)
                            checkCoupon(r.data.plan.meta.coupon.code)
                        }
                    }
                    setPageloading(false)
                }
  
            })
            .catch((e) => {
                console.log(e)
            })

    }

    const setBillingInfo = (p) => {

        switch (p) {
          case 'free':
            setPlan('free')
            setPriceAmount(0)
            setMaxPages(3)
            setMaxSites(1)
            setMaxSchedules(0)
            setTestcases(0)
            setScans(30)
            setTests(30)
            setRetentionDays(15)
            break
          case 'basic':
            setPlan('basic')
            setPriceAmount(interval === 'month' ? Math.round(8900-(8900*discount)) : Math.round((8900*10)-((8900*10)*(discount))))
            setMaxPages(3)
            setMaxSites(5)
            setMaxSchedules(5)
            setTestcases(500)
            setScans(1000)
            setTests(1000)
            setRetentionDays(30)
            break
          case 'pro':
            setPlan('pro')
            setPriceAmount(interval === 'month' ? Math.round(17900-(17900*discount)) : Math.round((17900*10)-((17900*10)*(discount))))
            setMaxPages(5)
            setMaxSites(10)
            setMaxSchedules(10)
            setTestcases(1000)
            setScans(2000)
            setTests(2000)
            setRetentionDays(90)
            break
          case 'plus':
            setPlan('plus')
            setPriceAmount(interval === 'month' ? Math.round(34900-(34900*discount)) : Math.round((34900*10)-((34900*10)*discount)))
            setMaxPages(10)
            setMaxSites(25)
            setMaxSchedules(25)
            setTestcases(2500)
            setScans(5000)
            setTests(5000)
            setRetentionDays(180)
            break
          case 'custom':
            setPlan('custom')
            break
          case 'enterprise':
            setPlan('custom')
            break
        }
      }
    
    
    const checkCoupon = (c, announce=false) => {
        var _discount = 1
        switch (c) {
            case 'WEBINAR-50':
                _discount = .5;
                announce && toast.success('50% off code applied!');
                break;
            case 'LAUNCH-60':
                _discount = .6;
                announce && toast.success('60% off code applied!');
                break;
            case 'SPECIAL-75':
                _discount = .75;
                announce && toast.success('75% off code applied!');
                break;
            case 'KYLE-75':
                _discount = .75;
                announce && toast.success('75% off code applied!');
                break;
            default:
                _discount = 0;
        }

        // setting discount
        setDiscount(_discount);

        // re-calc custom price
        if (plan === 'custom'){calcPrice(maxSites, interval, _discount)};
        
    }
    
    const billingCallBack = () => {
        setShowCard(false)
    }
    
    
    const setFreeAccount = async () => {
        setLoading(true)
        var data = {
            'type': 'free', 
            'id': store.getState().team.team.id
        }
        await axios.post(account_url, data)
            .then((r) => {
                toast.success('billing updated!')
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                toast.error(e.message ? e.message : 'contact support')
                setLoading(false)
            })
    }


    const updatePlan = async () => {

        let plan = store.getState().billing.billing.plan;
        console.log(plan);
        setLoading(true);

        await axios.post(setup_url, setupData)
          .then(() => {
            axios.post(info_url)
                .then((r)=>{
                    store.dispatch(billingSlice.actions.setBilling(r.data));
                    setLoading(false);
                    toast.success('billing updated!')
                })
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            toast.error('contact support')
          });
    };




  const calcPrice = (value, inter=interval, disc=discount) => {
        // y = 79.707x0.4142 -> power 
        // y = 0.836x + 328.1 -> linear
        // y = -0.0003x2 + 1.5142x + 325.2 -> polynomial
        // x = # sites
        // y = total price

        if (value > 2000) {
            value = 2000
        }
        
        setMaxSites(value)
        var newPrice = 0 
        
        if (value > 25){
            newPrice = (((-0.0003 * (value ** 2)) + (1.5142 * value) + 325.2) * 100)
            // newPrice =  (79.707 * (value ** 0.4142)) * 100
            // newPrice =  ((0.836 * value) + 328.1) * 100
            setMaxPages(15)
            setMaxSchedules(25)
            setRetentionDays(180)
            setTestcases(value * 100)
            setScans((value*2) * 100)
            setTests((value*2) * 100)
        } else if (value <= 5){
            newPrice = 8900
            setMaxPages(3)
            setMaxSchedules(5)
            setRetentionDays(30)
            setTestcases(500)
            setScans(1000)
            setTests(1000)
        } else if (6 < value && value <= 10) {
            newPrice = 17900
            setMaxPages(5)
            setMaxSchedules(10)
            setRetentionDays(90)
            setTestcases(1000)
            setScans(2000)
            setTests(2000)
        }else if (10 < value && value <= 25) {
            newPrice = 34900
            setMaxPages(10)
            setMaxSchedules(25)
            setRetentionDays(180)
            setTestcases(2500)
            setScans(5000)
            setTests(5000)
        }
        newPrice = Math.round(inter === 'month' ? newPrice : (newPrice * 10))
        setPriceAmount(newPrice - (newPrice * disc))
        console.log(`Price is -> ${newPrice}  ||  Interval is ${inter}  || discount is ${disc}` )
    }


    const formatPrice = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    const manageEnterpriseBilling = () => {
        console.log(subUrl)
        if (subUrl){
            window.location.href = subUrl
        }
    }


    if (key === null || pageloading) {
        return <Loader item={'Billing Info'}/>
    }
    
    // can only be created after all pre loading occurs
    const stripePromise = loadStripe(key);

    return(
        <div className="flex-1 bg-white rounded-lg dark:bg-gray-800">
        
        <div className="p-6 sm:p-12 ">
                
            <div className='flex justify-between mb-6'>
                <div>
                <h1 id='title' className="text-xl font-semibold text-gray-700 dark:text-gray-200">
                    Current: <span className='py-1 px-2 bg-gray-100 dark:bg-gray-900 rounded font-mono ml-1'>{plan.toUpperCase()}</span>
                </h1>
                <div className='mt-2'>
                    <span onClick={() => setViewCoupon(!viewCoupon)} className='hover:text-blue-600 text-blue-500 my-4 text-xs cursor-pointer'>
                        {coupon ? 
                            'View Coupon'
                        :
                            'Enter Coupon'
                        }
                    </span>
                    <div>
                    {viewCoupon && 
                        <Input 
                            className='w-32 rounded h-8'
                            placeholder='FREE-24'
                            value={coupon}
                            onChange={(e) => {
                                setCoupon(e.target.value)
                                checkCoupon(e.target.value, true)
                            }}
                        />
                    }
                    </div>
                </div>
                </div>

                {plan !== 'enterprise' && 
                    <div className='flex justify-end md:max-w-[40%] max-w-[40%]'>
                        <div>
                            <span className='md:mr-2 my-auto text-[.7rem] text-gray-700 dark:text-gray-200 font-bold'>{interval === 'month' ? 'Monthly' : 'Annually (save 16%)'}</span>
                        </div>
                        <div>
                            <Toggle 
                                small 
                                id='interval' 
                                checked={interval === 'year'} 
                                onChange={() => {
                                    var inter = null
                                    if (interval === 'month'){
                                        inter = 'year'
                                    } else {
                                        inter = 'month'
                                    }
                                    setInterval(inter)
                                    if (plan === 'custom'){
                                        calcPrice(maxSites, inter)
                                    }
                                }} 
                            />
                        </div>
                    </div>
                }
            </div>

            {plan !== 'enterprise' ?
                <div>
                    <div className='my-10 w-full'>
                        <div onClick={() => {setBillingInfo('basic')}} className={`${plan === 'basic' && 'ring-1 ring-blue-600'} cursor-pointer rounded-lg hover:-translate-y-1 ease-in-out duration-200 w-full bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-4 py-3 my-3`}>
                            <div className='grid grid-cols-3'>  
                                <div>
                                    <span className='font-bold text-xs py-1 px-2 rounded-xl text-yellow-700 bg-yellow-200 dark:text-yellow-100 dark:bg-yellow-600'>BASIC</span>
                                </div>
                                <div className='justify-self-center'>
                                    <span className='font-semibold md:text-sm text-xs'>5 Sites</span>
                                </div>
                                <div className='justify-self-end'>
                                    <div className='flex justify-between'>
                                        <div>
                                            {interval === 'month' ?
                                                <span className='font-bold md:text-sm text-xs'>${(89-(89*discount)).toFixed(2)} /m</span>
                                            :
                                                <span className='font-bold md:text-sm text-xs'>${(74.17-(74.17*discount)).toFixed(2)} /m</span>
                                            }
                                        </div>
                                        <Tooltip content='view details' classes='my-auto'>
                                        <div 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowBasic(!showBasic)
                                            }} 
                                            className='ml-2 h-full'
                                        >
                                            <InformationCircleIcon className='h-3 w-3 my-auto'/>
                                        </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            {showBasic && 
                                <div 
                                    className='my-3 p-4 grid grid-cols-2 gap-4 text-xs text-gray-500 dark:text-gray-400 cursor-text'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div>
                                        <ul className='list-disc ml-2'>
                                            <li className='my-1'>5 Sites</li>
                                            <li className='my-1'>3 Pages per Site</li>
                                            <li className='my-1'>1,000 Scans & Tests /m</li>
                                            <li className='my-1'>5 Schedules & Automations</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className='list-disc ml-2'>
                                            <li className='my-1'>1 month data retention</li>
                                            <li className='my-1'>500 Testcases /m</li>
                                            <li className='my-1'>Slack Support</li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        <div onClick={() => {setBillingInfo('pro')}} className={`${plan === 'pro' && 'ring-1 ring-blue-600'} cursor-pointer rounded-lg hover:-translate-y-1 ease-in-out duration-200 w-full bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-4 py-3 my-3`}>
                            <div className='grid grid-cols-3'>
                                <div>
                                    <span className='font-bold text-xs py-1 px-2 rounded-xl text-blue-600 bg-blue-200 dark:text-blue-100 dark:bg-blue-600'>PRO</span>
                                </div>
                                <div className='justify-self-center'>
                                    <span className='font-semibold md:text-sm text-xs'>10 Sites</span>
                                </div>
                                <div className='justify-self-end'>
                                    <div className='flex justify-between '>
                                        <div>
                                            {interval === 'month' ?
                                                <span className='font-bold md:text-sm text-xs'>${(179-(179*discount)).toFixed(2)} /m</span>
                                            :
                                                <span className='font-bold md:text-sm text-xs'>${(149.17-(149.17*discount)).toFixed(2)} /m</span>
                                            }
                                        </div>
                                        <Tooltip content='view details' classes='my-auto'>
                                        <div 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowPro(!showPro)
                                            }} 
                                            className='ml-2 h-full'
                                        >
                                            <InformationCircleIcon className='h-3 w-3 my-auto'/>
                                        </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            {showPro && 
                                <div 
                                    className='my-3 p-4 grid grid-cols-2 gap-4 text-xs text-gray-500 dark:text-gray-400 cursor-text'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div>
                                        <ul className='list-disc ml-2'>
                                            <li className='my-1'>10 Sites</li>
                                            <li className='my-1'>5 Pages per Site</li>
                                            <li className='my-1'>2,000 Scans & Tests /m</li>
                                            <li className='my-1'>10 Schedules & Automations</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className='list-disc ml-2'>
                                            <li className='my-1'>3 month data retention</li>
                                            <li className='my-1'>1,000 Testcases /m</li>
                                            <li className='my-1'>Slack Support</li>
                                            <li className='my-1'>Email Support</li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        <div onClick={() => {setBillingInfo('plus')}} className={`${plan === 'plus' && 'ring-1 ring-blue-600'} cursor-pointer rounded-lg hover:-translate-y-1 ease-in-out duration-200 w-full bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-4 py-3 my-3`}>
                            <div className='grid grid-cols-3'>
                                <div>
                                    <span className='font-bold text-xs py-1 px-2 rounded-xl text-teal-700 bg-teal-200 dark:text-teal-100 dark:bg-teal-600'>PLUS</span>
                                </div>
                                <div className='justify-self-center'>
                                    <span className='font-semibold md:text-sm text-xs'>25 Sites</span>
                                </div>
                                <div className='justify-self-end'>
                                    <div className='flex justify-between'>
                                        <div>
                                            {interval === 'month' ?
                                                <span className='font-bold md:text-sm text-xs'>${(349-(349*discount)).toFixed(2)} /m</span>
                                            :
                                                <span className='font-bold md:text-sm text-xs'>${(290.83-(290.83*discount)).toFixed(2)} /m</span>
                                            }
                                        </div>
                                        <Tooltip content='view details' classes='my-auto'>
                                        <div 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowPlus(!showPlus)
                                            }} 
                                            className='ml-2 h-full'
                                        >
                                            <InformationCircleIcon className='h-3 w-3 my-auto'/>
                                        </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            {showPlus && 
                                <div 
                                    className='my-3 p-4 grid grid-cols-2 gap-4 text-xs text-gray-500 dark:text-gray-400 cursor-text'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div>
                                        <ul className='list-disc ml-2'>
                                            <li className='my-1'>25 Sites</li>
                                            <li className='my-1'>10 Pages per Site</li>
                                            <li className='my-1'>5,000 Scans & Tests /m</li>
                                            <li className='my-1'>25 Schedules & Automations</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className='list-disc ml-2'>
                                            <li className='my-1'>6 month data retention</li>
                                            <li className='my-1'>2,500 Testcases /m</li>
                                            <li className='my-1'>Slack Support</li>
                                            <li className='my-1'>Email Support</li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        <div 
                            onClick={() => {
                                setBillingInfo('custom')
                                setShowSiteCount(false)
                                calcPrice((maxSites >= 26 ? maxSites : 26))
                            }} 
                            className={`${plan === 'custom' && 'ring-1 ring-blue-600'} cursor-pointer rounded-lg hover:-translate-y-1 ease-in-out duration-200 w-full bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-4 py-3 my-3`}>
                            
                            <div className='grid grid-cols-3'>
                                <div className='my-auto'>
                                    <span className=' font-bold text-xs py-1 px-2 rounded-xl text-gray-600 bg-gray-200 dark:text-gray-100 dark:bg-gray-600'>CUSTOM</span>
                                </div>
                                <div className='justify-self-center my-auto'>
                                    <div className='flex justify-center'>
                                        {showSiteCount ? 
                                            <input
                                                className='rounded-md p-1 text-center max-w-12 form-input dark:bg-gray-800 outline-none border-0 mr-3'
                                                value={maxSites}
                                                min={26} 
                                                max={2000}
                                                placeholder={26}
                                                onChange={(e) => {calcPrice(e.target.value)}}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            />
                                        :
                                            <Tooltip content='click to update'>
                                                <span 
                                                    className='font-semibold md:text-sm text-xs my-auto mr-1' 
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowSiteCount(true)
                                                    }}
                                                >
                                                    {plan === 'custom' ? maxSites : 26}
                                                </span>
                                            </Tooltip>
                                        }
                                    
                                        <span className='font-semibold md:text-sm text-xs md:my-auto mt-[.35rem]'> Sites</span>
                                    </div>
                                    
                                </div>
                                <div className='justify-self-end'>
                                    <div className='flex justify-between'>
                                        <div>
                                            {interval === 'month' ?
                                                <span className='font-bold md:text-sm text-xs'>${plan === 'custom' ? formatPrice(((priceAmount)/100).toFixed(2)) : (364.37 - (364.37*discount)).toFixed(2)} /m</span>
                                            :
                                                <span className='font-bold md:text-sm text-xs'>${plan === 'custom' ? formatPrice((((priceAmount)/12)/100).toFixed(2)) : (327.93 - (327.93*discount)).toFixed(2)} /m</span>
                                            }
                                        </div>
                                        <Tooltip content='view details' classes='my-auto'>
                                        <div 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowCustom(!showCustom)
                                            }} 
                                            className='ml-2 h-full'
                                        >
                                            <InformationCircleIcon className='h-3 w-3 my-auto'/>
                                        </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            {showCustom && 
                                <div 
                                    className='my-3 p-4 grid grid-cols-2 gap-4 text-xs text-gray-500 dark:text-gray-400 cursor-text'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div>
                                        <ul className='list-disc ml-2'>
                                            <li className='my-1'>{plan === 'custom' ? maxSites.toLocaleString() : '26'} Sites</li>
                                            <li className='my-1'>15 Pages per Site</li>
                                            <li className='my-1'>{plan === 'custom' ? (maxSites*200).toLocaleString() : '5,200'} Scans & Tests /m</li>
                                            <li className='my-1'>26 Schedules & Automations</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className='list-disc ml-2'>
                                            <li className='my-1'>6 month data retention</li>
                                            <li className='my-1'>{plan === 'custom' ? (maxSites*100).toLocaleString() : '2,600'} Testcases /m</li>
                                            <li className='my-1'>Slack Support</li>
                                            <li className='my-1'>Email Support</li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    
                    {(store.getState().billing.billing.card && !showCard) ? 
                        <div className='flex justify-between mt-3'>
                            {!showCard && 
                                <div className='mt-3'>
                                    <span 
                                        onClick={() => {
                                            setShowCard(true)
                                            setType('card')
                                        }} 
                                        className='hover:text-blue-600 text-blue-500 my-4 text-xs cursor-pointer'
                                    >
                                        Update Card
                                    </span>
                                </div>
                            }
                            <div>
                                <Button layout="outline" size="small" onClick={updatePlan} disabled={loading || plan === store.getState().billing.billing.plan.name}>
                                    <Spinner display={loading}/>
                                    Update
                                </Button>
                            </div>
                        </div>
                    :
                        <Elements stripe={stripePromise}>
                            <BillingForm 
                                setupData={setupData} 
                                callBack={billingCallBack} 
                                type={type} 
                                hideCardCallBack={() => {
                                    setType('initial')
                                    setShowCard(false)
                                }}
                            />
                        </Elements>
                    }

                </div>
            :
                
                <div className='mt-8'>
                    <Button 
                        iconRight={ExternalLinkIcon} layout='outline'
                        onClick={manageEnterpriseBilling}
                    >
                        Manage Billing
                    </Button>
                </div>

            }
            

        </div>

        </div>

    )
}




export default BillingPlanCard