import React, {useState, useEffect} from 'react';
import {Card, CardBody} from '@windmill/react-ui';



function ApiResponseCard(props) {

    const [response, setResponse] = useState(props.response ? props.response : null)


    useEffect(() => {
        setResponse(props?.response)
        // eslint-disable-next-line
    }, [props?.response,])


    return (
        <>
        <Card className="">
            <CardBody className="">
                <p className="mb-2 font-semibold text-gray-600 dark:text-gray-300">Response</p>
                <div className="max-h-[70vh] p-4 overflow-auto text-xs whitespace-pre-wrap text-gray-600 dark:text-gray-100">
                    <pre><code className="text-gray-600 dark:text-gray-100">{response ? JSON.stringify(response, null, 2) : '{ "response": "Send a request to get started" }'}</code></pre>
                </div>
                
            </CardBody>
        </Card>
        
        </>
    )
}

export default ApiResponseCard