import React, {useState, useEffect} from 'react'
import PageTitle from '../components/Typography/PageTitle'
import ReportCard from '../components/Cards/ReportCard'
import IFrameReportCard from '../components/Cards/IFrameReportCard'
import BackButton from '../components/Buttons/BackButton'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import IDCard from '../components/Cards/IDCard'
import Loader from '../components/Buttons/Loader'




function Report() {
    
  const { id } = useParams();
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const reportUrl = `${BACKEND_URL}/v1/ops/report`;
  const pageUrl = `${BACKEND_URL}/v1/ops/page`;
  const siteUrl = `${BACKEND_URL}/v1/ops/site`;

  const [report, setReport] = useState(false);
  const [iframe, setIframe] = useState(1);
  const [page, setPage] = useState(null);
  const [site, setSite] = useState(null);
  const navigate = useNavigate();


  const loadData = async () => {
      // get report info
      await axios.get(`${reportUrl}?page_id=${id}`)
      .then((r) => {
          setReport(r.data.results[0])
          setIframe(iframe + 1)
          axios.get(`${pageUrl}/${id}`)
            .then((r) => {
                setPage(r.data)
                axios.get(`${siteUrl}/${r.data.site}`)
                  .then((r) => {
                    setSite(r.data)
                  })
            })
            .catch((e) => {
                console.log(e)
            })
      })
      .catch((err) => {
        setReport(null)
        console.log(err)
      });
    }

  useEffect(() => {
      loadData();
      // eslint-disable-next-line 
    },[reportUrl])


  const urlLength = () => {
    var text_size = 'text-2xl'
    var len = page.page_url.length
    if (len >= 50 ){
      var text_size = 'text-xl'
    }else if (len > 36 && len < 50){
      var text_size = 'text-xl'
    }else if (len < 50){
      var text_size = 'text-2xl'
    }
    return text_size
  }
  

  if (report === false || site === null || page === null) {
    return <Loader item={'Report Data'}/>
  }


  return (
    <>
    <div className='flex justify-start mb-2'>
      <PageTitle>
        <div className="">
          <div>
            <span className='text-center px-2 py-1 rounded-full text-[11px] bg-purple-200 dark:bg-purple-600 font-semibold dark:text-white text-purple-700'>
              REPORT
            </span>
            <p 
              onClick={() => navigate(`/page/${page.id}`)} 
              className={`hover:underline cursor-pointer max-w-lg ${urlLength()}`}
            >
              {page.page_url}
            </p>
            <div className='mt-1'>
              <IDCard resource={'report'} id={report ? report.id : '-'}/>
            </div>
          </div>
        </div>
      </PageTitle>
    </div>
     
     <BackButton link={`/page/${page.id}`} text='back to page'/>

      <div className="grid gap-6 md:grid-cols-2 grid-cols-1 mt-4">
          
          <div>
              <ReportCard page_id={id} report={report} callBack={loadData}/>
          </div>
          
          <div>
              <IFrameReportCard iframe={iframe} report={report}/>
          </div>

      </div>
    </>
  )
}

export default Report