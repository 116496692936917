import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Input, Button, Label, Select } from '@windmill/react-ui';
import { XIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux'
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import Toggle from '../Toggle/Toggle';
import axios from "axios";
import toast from 'react-hot-toast';
import { BeakerIcon } from '../../icons';
import Spinner from '../Buttons/Spinner'





function TestcaseModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/testcase'
  const caseUrl = BACKEND_URL + '/v1/ops/case/search?query='
  const devices_url = BACKEND_URL + '/v1/ops/device'
  const teamConfigs = useSelector(state => state.team.team.configs)

  const [window_size, setWindowSize] = useState();
  const [browser, setBrowser] = useState()
  const [device, setDevice] = useState()
  const [location, setLocation] = useState()
  const [deviceId, setDeviceId] = useState(6)
  const [devices, setDevices] = useState(null)
  const [interval, setInterval] = useState()
  const [min_wait_time, setMinTime] = useState()
  const [max_wait_time, setMaxTime] = useState()
  const [timeout, setTimeout] = useState()
  const [createIssue, setCreateIssue] = useState()
  const [cas, setCas] = useState(null);
  const [cases, setCases] = useState(null);
  const [query, setQuery] = useState('')
  const [updates, setUpdates] = useState(null)
  const [open, setOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfigs, setShowConfigs] = useState(false);
  const [showUpdates, setShowUpdates] = useState(false)

  const navigate = useNavigate();


  useEffect(() => {
    getDevices();
    setWindowSize(teamConfigs ? teamConfigs.window_size : '1920,1080')
    setBrowser(teamConfigs ? (teamConfigs.browser !== undefined ? teamConfigs.browser : 'chrome') : 'chrome')
    setDevice(teamConfigs ? teamConfigs.device : 'desktop')
    teamConfigs ? (teamConfigs.location !== undefined ? teamConfigs.location : 'us') : 'us'
    setInterval(teamConfigs ? teamConfigs.interval : 1)
    setMinTime(teamConfigs ? teamConfigs.min_wait_time : 3)
    setMaxTime(teamConfigs ? teamConfigs.max_wait_time : 60)
    setTimeout(teamConfigs ? teamConfigs.timeout : 300)
    setCreateIssue(teamConfigs ? teamConfigs.create_issue : true)
  }, [])


  const getDevices = async () => {
    await axios.get(devices_url)
      .then((r) => {
        let _devices = r.data.devices
        let _device = null
        setDevices(_devices)
        for (var i in _devices) {
          if (_devices[i].browser === teamConfigs.browser && 
            _devices[i].name === teamConfigs.device) {
            _device = _devices[i]
          }
        }
        if (_device){
          setDeviceId(_device.id)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }


  useEffect(() => {
    document.addEventListener('click', handleModalClose, { capture: true })
    return () => {
      document.removeEventListener('click', handleModalClose)
    }
  })

  const handleModalClose = (e) => {
    try{
      var modalElement = document.querySelector('#testcase-modal');
      if (!modalElement.includes(e.target)) {
        console.log('click was not in modal')
        // console.log(e)
        closeModal()
      }
    }catch{}
  }


  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }


  const search = async (q) => {
    if (q.length > 0 !== '') {
        await axios.get(`${caseUrl}${q}`)
            .then((r) => {
                setCases(r.data)
            })
            .catch((e) => {
                console.log(e)
            })
    } else {
        // console.log(query)
    }
  }

  const addUpdates = (cas) => {
    var newUpdates = []
    fetch(cas.steps.url)
        .then(r => r.json())
        .then((out) => {
          var steps = out
          for (var i in steps) {
            var step = steps[i]
            if (
              step.action.value !== '' && 
              step.action.value !== null &&
              step.action.type == 'change'
            ){
              var update = {
                value: step.action.value,
                index: i
              }
              newUpdates.push(update)
            }
          }
          setUpdates(newUpdates)
        }).catch((e)=>{
          console.log(e)
        })
  }


  const updateChange = (e, i) => {
    const { value } = e.target;
    const list = [...updates];
    list[i]['value'] = value;
    setUpdates(list);
  }


  const handleCreateTestcase = (evt) => {
    evt.preventDefault();

    const configs = {
      "interval": interval,
      "window_size": window_size,
      "device": device,
      "location": location,
      "browser": browser,
      "min_wait_time": min_wait_time,
      "max_wait_time": max_wait_time,
      "create_issue": createIssue,
    }

    const data = {
      "site_id": props.site.id,
      "case_id": cas.id,
      "updates": updates,
      "configs": configs,
    }

    setIsLoading(true);

    axios.post(`${url}`, data)
    .then((res) => {
      if (res.data) {
        setIsLoading(false);
        setIsModalOpen(false);
        toast.success('Testcase started');
        navigate(`/testcase/${res.data.id}`)

      }
    })
    .catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    })
  }


  const ButtonType = () => {
    if(props.type==='icon'){
      return(
          <Button onClick={openModal} layout="link" size="icon" aria-label="Test">
            <BeakerIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
      )
    }else if(props.type==='button'){
      return (
        <div>
          <Button className='w-[9rem]' onClick={openModal} iconRight={BeakerIcon} layout='outline'>
            Testcase
          </Button>
        </div>
      )
    }else if(props.type==='text'){
      return (
        <span onClick={openModal} className="cursor-pointer">
            { props.text ? props.text : 'New Testcase' }
        </span>
      )
    }
    else if(props.type==='dropdown'){
      return (
        <DropdownItem onClick={openModal} className="z-40 relative">
          <BeakerIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Testcase</span>
        </DropdownItem>
      )
    }
  }



  return (
    <>

      <div>{ButtonType()}</div>

      <Modal 
        isOpen={isModalOpen} 
        onClose={closeModal}
        style={{ maxHeight: '100vh', position: 'absolute', overflow: 'scroll', display:'block', }}
        title={'New Testcase'}
      >
        <div id='testcase-modal'>
        <ModalBody style={{ minHeight: open ? '15rem' : '8rem', }} onClick={e=>e.nativeEvent.stopImmediatePropagation()}>
        <div className='overflow-auto px-3' style={{ maxHeight: '30rem'}}>
          <div className={`relative ${open ? 'h-[9rem]' : ''}`}>

            {!cas && 
              <Label className="mb-1 w-full">
                <span className='mb-2'>Case</span>
                <input 
                  className='block text-sm focus:outline-none dark:text-gray-300 form-input leading-5 focus:border-blue-400 dark:border-gray-600 focus:shadow-outline-blue dark:focus:border-gray-600 dark:focus:shadow-outline-gray dark:bg-gray-700 rounded-md p-2 w-full'
                  placeholder='search for a case'
                  value={query}
                  onChange={(e) => {
                      setQuery(e.target.value === '/' ? '' : e.target.value)
                      if (e.target.value !== '') {
                          setOpen(true)
                          search(e.target.value);
                      } else {
                          setOpen(false)
                      }
                  }}
                />
              </Label>
            }

            {query.length >= 1 && open && !cas &&
              <div className={`w-full absolute z-30 mx-auto shadow rounded-b-lg p-4 bg-white dark:bg-gray-700 dark:text-gray-300 text-gray-600 overflow-x-scroll`}>

                  { cases && 

                    cases.results.slice().map((resource, i) => (
                      <div key={i}>
                          <div 
                              className="flex justify-start px-2 py-2 dark:hover:bg-gray-600 hover:bg-gray-100 my-2 rounded-md cursor-pointer" 
                              onClick={() => {
                                setCas(resource)
                                addUpdates(resource)
                                setQuery('')
                                setOpen(false)
                              }}
                          >   
                              <div className='mr-3 my-auto'>
                                  <div className={`text-center px-2 py-1 rounded-full text-[8px] bg-gray-300 dark:bg-gray-500`}>
                                      <span className={`font-bold dark:text-white text-gray-700`}>
                                          CASE
                                      </span>
                                  </div>
                              </div>
                              <div>
                                  <h2 className="font-semibold text-sm">
                                      {resource.name}
                                  </h2>
                              </div>
                          </div> 
                      </div>
                    ))

                  }

              </div>
            }

          </div>
          
          {cas && !open &&

          <label className='mb-3'>
            <span className='text-sm text-gray-700 dark:text-gray-400 '>Case:</span>
            <div className='flex justify-between px-2 py-2 dark:bg-gray-700 bg-white rounded-xl my-auto ring-1 dark:ring-0'>
              <div className='mr-2 my-auto'>
                <div className="flex justify-start  cursor-auto">   
                  <div className='mr-3 my-auto'>
                    <div className={`text-center px-2 py-1 rounded-full text-[8px] bg-gray-300 dark:bg-gray-500`}>
                      <span className={`font-bold dark:text-white text-gray-700 `}>
                          CASE
                      </span>
                    </div>
                  </div>
                  <div>
                    <h2 className="font-semibold text-sm text-gray-700 dark:text-gray-300 max-w-[12rem] truncate">
                      {cas.name}
                    </h2>
                  </div>
                </div>
              </div>
              <div className=''>
                <div 
                  onClick={() => {
                    setCas(null)
                    setUpdates(null)
                  }} 
                  className='cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-6 h-6 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white my-auto'
                >
                  <XIcon className="w-3 h-3" aria-hidden="true" />
                </div>
              </div>
            </div>
          </label>

          }

          <div className='mt-6'>
            {updates && (
              updates.length !== 0 &&
                <span 
                  className="text-sm cursor-pointer underline decoration-blue-600 text-blue-600"
                  onClick={() => setShowUpdates(!showUpdates)}
                >
                  {showUpdates ? 'Hide' : 'Show'} Values
                </span> 
              )
            }
            {updates && showUpdates &&
              <div className='grid grid-cols-2 gap-4 my-8 p-4 rounded-md border-[1.5px] dark:border-gray-600 border-gray-400'>

              {updates.map((update, i) => (
                <div key={i} >
                  <Label className="" >
                    <span>Step {update.index}</span>
                    <Input 
                      className='rounded-md p-2 '
                      value={update.value}
                      placeholder={'enter some testing text...'}
                      onChange={e => updateChange(e, i)} 
                    />
                  </Label>
                </div>
              ))}

              </div>
            }
          </div>

          <div className='' onClick={e=>e.nativeEvent.stopImmediatePropagation()}>
            <div className='mt-6'>
              <span
                className='text-sm underline text-blue-600 cursor-pointer w-auto'
                onClick={() => setShowConfigs(!showConfigs)}
              >
                {showConfigs ? 'Hide' : 'Show'} Advanced
              </span>
            </div>
            {showConfigs &&
              <div className='my-8 p-4 rounded-md border-[1.5px] dark:border-gray-600 border-gray-400'>
                <div className='mb-4'>
                  <Label className="mb-2">Location</Label>
                  <Select className="rounded-md p-2 mt-1"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value)
                    }}
                  >
                    <option value="us">United States</option>
                    <option value="eu">Europe</option>
                    <option value="au" disabled={true} className='cursor-not-allowed'>Austraila</option>
                    <option value="ca" disabled={true} className='cursor-not-allowed'>Canada</option>
                  </Select>
                </div>
                <div className='grid gap-6 grid-cols-2'>
                  <div className='mb-4'>
                    <Label className="mb-2" >Browser</Label>
                    <Select className="rounded-md p-2 mt-1"
                      value={browser}
                      onChange={(e) => {
                        setBrowser(e.target.value)
                        for (var i in devices) {
                          if (devices[i].browser === e.target.value && 
                            devices[i].name === device) {
                              setDeviceId(devices[i]['id'])
                          }
                        }
                      }}
                    >
                      <option value="chrome">Chrome</option>
                      <option value="firefox">Firefox</option>
                      <option value="edge">Edge</option>
                    </Select>
                  </div>
                  <div className='mb-4'>
                    <Label className="mb-2">Device</Label>
                    <Select className="rounded-md p-2 mt-1"
                      value={deviceId}
                      onChange={(e) => {
                        let _device = devices.find(item => item.id === e.target.value)
                        setDeviceId(_device.id)
                        setDevice(_device.name)
                        setWindowSize(_device.window_size)
                      }}
                    >
                      {devices && devices.map((item, i) => (
                        item.browser === browser && 
                          <option
                            key={i} 
                            value={item.id} 
                          >
                            {item.name}
                          </option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className='grid md:gap-6 gap-4 grid-cols-4 mb-4'>
                  <div>
                    <Label className="mt-4">
                      <span>Interval (s)</span>
                      <Input 
                        className='rounded-md p-2 max-w-[6rem]'
                        onChange={e => setInterval(e.target.value)} 
                        value={interval}
                        type='number'  
                        placeholder={5}/>
                    </Label>
                  </div>
                    
                  <div>
                    <Label className="mt-4">
                      <span>Timeout (s)</span>
                      <Input 
                        className='rounded-md p-2 max-w-[6rem]'
                        onChange={e => setTimeout(e.target.value)} 
                        value={timeout}
                        type='number'  
                        placeholder={300}/>
                    </Label>
                  </div>

                  <div>
                    <Label className="mt-4">
                      <span>Min Wait (s)</span>
                      <Input
                        className='rounded-md p-2 max-w-[6rem]' 
                        onChange={e => setMinTime(e.target.value)} 
                        value={min_wait_time}
                        type='number'  
                        placeholder={10}/>
                    </Label>
                  </div>

                  <div>
                    <Label className="mt-4">
                    <span>Max Wait (s)</span>
                      <Input 
                        className='rounded-md p-2 max-w-[6rem]'
                        onChange={e => setMaxTime(e.target.value)} 
                        value={max_wait_time}
                        type='number'  
                        placeholder={60}/>
                    </Label>
                  </div>

                </div>
                <div className='grid grid-cols-2 gap-6 mb-4'>
                  <div>
                    <Label className="mt-4">
                      <span>Window Size (x,y)</span>
                      <Input
                        className='rounded-md p-2' 
                        onChange={e => setWindowSize(e.target.value)} 
                        value={window_size} 
                        placeholder={'1920,1080'}/>
                    </Label>
                  </div>

                  <div className="mt-4">
                    <div className='flex justify-end mt-6'>
                      <span className='mr-4 text-sm text-gray-800 dark:text-gray-400'>Create Issue</span>
                      <div>
                        <Toggle id='test-create-issue' small checked={createIssue} onChange={() => setCreateIssue(!createIssue)} />
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            }
          </div>
        </div>
        </ModalBody>
        <ModalFooter onClick={e=>e.nativeEvent.stopImmediatePropagation()}>
          <div className="hidden sm:block pb-6">
            <Button layout="outline" onClick={handleCreateTestcase} disabled={cas ? isLoading : true}>
            <Spinner display={isLoading}/>
              {'Run Testcase'}
            </Button>
          </div>
          <div className="block w-full sm:hidden pb-6">
            <Button block size="large" layout="outline" onClick={handleCreateTestcase} disabled={cas ? isLoading : true}>
            <Spinner display={isLoading}/>
              {'Run Testcase'}
            </Button>
          </div>
        </ModalFooter>
        </div>
      </Modal>
    </>
  )
}

export default TestcaseModal


