


const Table = (props) => {


    return (
        <div class="w-full overflow-auto">
            <table className='w-full whitespace-nowrap'>
                {props.children}
            </table>
        </div>
    )
}

export default Table