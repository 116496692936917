import React from 'react';
import { Card, CardBody } from '@windmill/react-ui';
import Highlight from '../Typography/Highlight/Highlight';


function HtmlCard(props) {


        

    return (
        <>
            <Card className="mt-4">
                <CardBody className="">
                <p className="mb-4 font-semibold text-gray-600 dark:text-gray-300">{props.card_title}</p>
                    <div style={{ maxHeight: '30rem' }} className="p-4 overflow-auto text-small whitespace-pre-wrap text-gray-600 dark:text-gray-100">
                        {props.parsed && props.diff && 
                            <pre className='whitespace-pre-wrap'>
                                <code > 
                                    <Highlight 
                                        parsed={props.parsed ? props.parsed : null} 
                                        diff={props.diff ? props.diff : null}
                                    />
                                </code>
                            </pre>
                        }
                        {props.html ? <pre>{props.html}</pre> : null}
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default HtmlCard