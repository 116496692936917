import { Button } from "@windmill/react-ui"
import { ZapierIcon } from '../../icons';




const ZapierButton = (props) => {

    const navToZapier = () => {
        window.open('https://zapier.com/apps/cursion/integrations')
    }

    return (
        <Button layout="outline" onClick={navToZapier} iconLeft={ZapierIcon} size='regular'>
            <span>
                Add to Zapier
            </span>
        </Button>
    )
}


export default ZapierButton