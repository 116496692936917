import React , { useEffect } from 'react'
import { useNavigate } from 'react-router';
import axios from 'axios'
import toast from 'react-hot-toast';
import getToken from './GetToken';
import teamSlice from '../slices/team';
import Loader from '../../components/Buttons/Loader';
import store from '..';



function SlackConfirm() {

    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const navigate = useNavigate();
    var token = getToken();

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const url = `${BACKEND_URL}/v1/auth/slack?code=${urlParams.get('code')}&user_id=${store.getState().auth.account.id}`;
        const account_url = `${BACKEND_URL}/v1/auth/account`

        axios.get(url, { headers: { Authorization: token } })
            .then((r) => {
                axios.get(account_url, { headers: { Authorization: token } })
                    .then((r) => {
                        store.dispatch(teamSlice.actions.setTeam(r.data))
                        toast.success('Added Slack!');
                        navigate('/account')
                    }).catch((e) => {console.log(e);})
            }).catch((e) => {console.log(e);})

      },[BACKEND_URL, navigate, token])


    return (
        <div className="text-gray-300 dark:text-gray-100">

            <Loader item={'Slack Context'}/>

        </div>
    )
    
}
    
export default SlackConfirm;