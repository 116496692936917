

const DropdownItem = (props) => {

    return (
        <li>
            <div 
                className="text-sm flex justify-start px-4 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer rounded"
                onClick={() => props.onClick()}
            >
                {props.children}
            </div>
        </li>
    )
}

export default DropdownItem