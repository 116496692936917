
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountResponse } from "../../types";

type State = {
  token: string | null;
  refreshToken: string | null;
  api_token: string | null;
  account: AccountResponse | null;
};

const initialState: State = { token: null, refreshToken: null, api_token: null, account: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens(
      state: State,
      action: PayloadAction<{ token: string; refreshToken: string; api_token: string; }>
    ) {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
      state.api_token = action.payload.api_token;
    },
    setAccount(state: State, action: PayloadAction<AccountResponse>) {
      state.account = action.payload;
    },
    setLogout(state: State) {
      state.account = null;
      state.refreshToken = null;
      state.token = null;
      state.api_token = null;
    },
  },
});

export default authSlice;

  