/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
const routes = [
  {
    path: '/', // the url
    icon: 'HomeIcon', // the component being exported from icons/index.js
    name: 'Home', // name that appear in Sidebar
    badge: null,
    badgeText: null,
  },
  {
    path: '/schedule', 
    icon: 'ClockIcon', 
    name: 'Schedules', 
    badge: null,
    badgeText: null,
  },
  {
    path: '/case', 
    icon: 'BeakerIcon', 
    name: 'Cases', 
    badge: 'primary',
    badgeText: 'beta',
  },
  {
    path: '/issue', 
    icon: 'WarningIcon', 
    name: 'Issues', 
    badge: null,
    badgeText: null,
  },
  {
    path: '/logs', 
    icon: 'ListIcon', 
    name: 'Logs', 
    badge: null,
    badgeText: null,
  },
  {
    path: '/billing', 
    icon: 'MoneyIcon', 
    name: 'Billing', 
    badge: null,
    badgeText: null,
  },
  {
    path: '/account', 
    icon: 'OutlinePersonIcon', 
    name: 'Account', 
    badge: null,
    badgeText: null,
  },
  // {
  //   path: '/internal-api', 
  //   icon: 'CodeSandboxIcon', 
  //   name: 'API', 
  //   badge: null,
  //   badgeText: null,
  // },
  // {
  //   path: '/dashboard', // the url
  //   icon: 'HomeIcon', // the component being exported from icons/index.js
  //   name: 'Dashboard', // name that appear in Sidebar
  // },
  // {
  //   path: '/forms',
  //   icon: 'FormsIcon',
  //   name: 'Forms',
  // },
  // {
  //   path: '/cards',
  //   icon: 'CardsIcon',
  //   name: 'Cards',
  // },
  // {
  //   path: '/charts',
  //   icon: 'ChartsIcon',
  //   name: 'Charts',
  // },
  // {
  //   path: '/buttons',
  //   icon: 'ButtonsIcon',
  //   name: 'Buttons',
  // },
  // {
  //   path: '/modals',
  //   icon: 'ModalsIcon',
  //   name: 'Modals',
  // },
  // {
  //   path: '/tables',
  //   icon: 'TablesIcon',
  //   name: 'Tables',
  // },
  // {
  //   icon: 'PagesIcon',
  //   name: 'Pages',
  //   routes: [
  //     // submenu
  //     {
  //       path: '/login',
  //       name: 'Login',
  //     },
  //     {
  //       path: '/create-account',
  //       name: 'Create account',
  //     },
  //     {
  //       path: '/forgot-password',
  //       name: 'Forgot password',
  //     },
  //     {
  //       path: '/404',
  //       name: '404',
  //     },
  //     {
  //       path: '/blank',
  //       name: 'Blank',
  //     },
  //   ],
  // },
]

export default routes
