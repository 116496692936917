
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MemberResponse } from "../../types";

type State = {
  member: MemberResponse | null;
};

const initialState: State = { member: null };

const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    setMember(state: State, action: PayloadAction<MemberResponse>) {
      state.member = action.payload;
    },
    setLogout(state: State) {
      state.member = null;
    },
    
  },
});

export default memberSlice;

  