import React, { useState } from 'react';
import { Input, Button } from '@windmill/react-ui';
import axios from "axios";
import toast from 'react-hot-toast';
import Spinner from '../Buttons/Spinner'
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'




function MemberModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/auth/member'

  const [email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      handleAddMember(evt)
    }
  }

  const handleAddMember = (evt) => {
    evt.preventDefault();
    var data = {
      'account': props.team.id,
      'email': email,
      'status': 'pending',
      'type': 'contributor'
    }
    if (email.length <= 0 || !email.includes('@') || isLoading) {return null}
    setIsLoading(true);
    axios.post(`${url}`, data)
      .then((r) => {
        props.callBack()
        setIsLoading(false);
        setIsModalOpen(false);
        setEmail('');
        toast.success('Member added!');
      }).catch((e) => {
        if (e.response) {
          toast.error(e.response.data.reason)
          setIsLoading(false);
        }
      })
  }



  return (
    <>

      <div>
        <div onClick={openModal} className='cursor-pointer flex justify-center mt-2 p-1 px-4 rounded dark:bg-gray-700 bg-gray-50 hover:shadow hover:-translate-y-[2px] duration-200 ease-in-out border-dashed border-4 dark:border-gray-600 border-gray-200'>
            <p className='dark:text-gray-300 text-sm text-gray-600'>Add a Member</p>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Add a Member'}>
        
        <ModalBody>
          <Input 
              onKeyDown={handleKeyDown}
              className="rounded-md p-2 mt-1" 
              placeholder='jane@example.com'
              value={email}
              onChange={e => setEmail(e.target.value)}/>
        </ModalBody>
        <ModalFooter>

          <div className="hidden sm:block">
            <Button 
              layout="outline" 
              onClick={handleAddMember} 
              type="submit"
              className={`${(email.length > 0 && email.includes('@') && !isLoading) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
              disabled={email.length <= 0 || !email.includes('@') || isLoading}
            >
            <Spinner display={isLoading}/>
              Submit
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button 
              block 
              className={`${(email.length > 0 && email.includes('@') && !isLoading) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
              size="large" 
              layout="outline" 
              onClick={handleAddMember} 
              type="submit" 
              disabled={email.length <= 0 || !email.includes('@') || isLoading}>
            <Spinner display={isLoading}/>
              Submit
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default MemberModal


