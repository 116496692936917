import React, { useState, useEffect } from 'react'
import AuditsSlideModalLH from '../Modals/AuditsSlideModalLH';
import { Doughnut } from 'react-chartjs-2'
import {Badge} from '@windmill/react-ui'


function LighthouseChart(props) {

    const [seogood, setSeoGood] = useState(null);
    const [seobad, setSeoBad] = useState(null);
    const [accessgood, setAccessGood] = useState(null);
    const [accessbad, setAccessBad] = useState(null);
    const [perfgood, setPerfGood] = useState(null);
    const [perfbad, setPerfBad] = useState(null);
    const [bestgood, setBestGood] = useState(null);
    const [bestbad, setBestBad] = useState(null);
    // const [pwagood, setPwaGood] = useState(null);
    // const [pwabad, setPwaBad] = useState(null);
    const [cruxgood, setCruxGood] = useState(null);
    const [cruxbad, setCruxBad] = useState(null);
    const [audits, setAudits] = useState(null)


    const getAudits = async () => {
      if (props.scan.lighthouse){
        if (typeof props.scan.lighthouse.audits === 'string'){
          await fetch(props.scan.lighthouse.audits)
            .then(r => r.json())
            .then(out => setAudits(out))
            .catch((e) => {console.log(e)})
        }else if (typeof props.scan.lighthouse.audits === 'object'){
          setAudits(props.scan.lighthouse.audits)
        }
      }
    }

    const colorOne = '#0694a203'
    const colorTwo = '#1c64f2'
    const borderColor = '#9ca3af'

    useEffect(() => {
      if (props.scan.lighthouse){
        if(props.scan.lighthouse.scores.average !== null && props.scan.lighthouse.scores.average !== '' ) {
          setSeoGood(props.scan.lighthouse.scores.seo);
          setSeoBad(100 - props.scan.lighthouse.scores.seo);
          setAccessGood(props.scan.lighthouse.scores.accessibility);
          setAccessBad(100 - props.scan.lighthouse.scores.accessibility);
          setPerfGood(props.scan.lighthouse.scores.performance);
          setPerfBad(100 - props.scan.lighthouse.scores.performance);
          setBestGood(props.scan.lighthouse.scores.best_practices);
          setBestBad(100 - props.scan.lighthouse.scores.best_practices);
          // setPwaGood(props.scan.lighthouse.scores.pwa);
          // setPwaBad(100 - props.scan.lighthouse.scores.pwa);
          setCruxGood(props.scan.lighthouse.scores.crux);
          setCruxBad(100 - props.scan.lighthouse.scores.crux);
          // if (props.scan.lighthouse.scores.crux!==null){
            
          // }
        }
        getAudits();
      }
    },[props])



      const seoData = {
        data: {
          datasets: [
            {
              data: [seogood, seobad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'SEO',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      // const pwaData = {
      //   data: {
      //     datasets: [
      //       {
      //         data: [pwagood, pwabad],
      //         backgroundColor: [colorTwo, colorOne,],
      //         label: 'PWA',
      //         borderColor: borderColor,
      //       },
      //     ],
      //     labels: [],
      //   },
      //   options: {
      //     responsive: true,
      //     cutout: 37,
      //     plugins: {
      //       tooltip: {
      //         enabled: false,
      //         display: false,
      //       },
      //     },

      //   },
      //   legend: {
      //     display: false,
      //   },
      // }

      const cruxData = {
        data: {
          datasets: [
            {
              data: [cruxgood, cruxbad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'CRUX',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const accessData = {
        data: {
          datasets: [
            {
              data: [accessgood, accessbad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'Accessibility',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const perfData = {
        data: {
          datasets: [
            {
              data: [perfgood, perfbad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'Performance',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const bestData = {
        data: {
          datasets: [
            {
              data: [bestgood, bestbad],
              backgroundColor: [colorTwo, colorOne,],
              label: 'Best Practices',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 37,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }


      function getScore(score) {
        var value = 'no data'
        if(score!==null && score!==''){
          var score_int = Math.round(parseInt(score))
          value = score_int+'%'
        }
        return (
            <>
                <span className="text-gray-600 dark:text-gray-300">{value}</span>
            </>
        )
      }


      function getScoreStatus(score) {
        if(score!==null && score!==''){
          var badge = 'neutral'
          var health = 'no data'
          var score_int = Math.round(parseInt(score))
          if (score_int >= 75) {
            health = 'Good';
            badge = 'success';
          }else if (score_int >= 60 && score_int < 75) {
            health = 'Okay';
            badge = 'warning';
          }else {
            health = 'Poor';
            badge = 'danger';
          }
        }else{
            badge = 'neutral'
            health = 'no data'
        }
        return <div><Badge type={badge}>{health}</Badge></div>;
      }



  return (
    <>
            <div className='grid gap-1 mb-8 xl:grid-cols-5 md:grid-cols-3 lg:grid-cols-5 grid-cols-2 ml-12' style={{ justifyContent: 'center', }}>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...seoData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(seogood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300" style={{ }}>
                      SEO
                      <div className='flex justify-center'>
                        <AuditsSlideModalLH type='icon' cat='SEO' audits={audits ? audits.seo : null}/> 
                        {getScoreStatus(seogood)}
                      </div>
                    </div>
                </div>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...accessData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(accessgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300" style={{ }}>
                      Accessibility
                      <div className='flex justify-center'>
                        <AuditsSlideModalLH type='icon' cat='Accessibility' audits={audits ? audits.accessibility : null}/> 
                        {getScoreStatus(accessgood)}
                      </div>
                    </div>
                </div>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...perfData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(perfgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300" style={{ }}>
                      Performance
                      <div className='flex justify-center'>
                        <AuditsSlideModalLH type='icon' cat='Performance' audits={audits ? audits.performance : null}/> 
                        {getScoreStatus(perfgood)}
                      </div>
                    </div>
                </div>
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...bestData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(bestgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300" style={{ }}>
                      Best Practices
                      <div className='flex justify-center'>
                        <AuditsSlideModalLH type='icon' cat='Best Practices' audits={audits ? audits.best_practices : null}/> 
                        {getScoreStatus(bestgood)}
                      </div>
                    </div>
                </div>
                {/* <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...pwaData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(pwagood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300" style={{ }}>
                      PWA
                      <div className='flex justify-center'>
                        <AuditsSlideModalLH type='icon' cat='PWA' audits={audits ? audits.pwa : null}/> 
                        {getScoreStatus(pwagood)}
                      </div>
                    </div>
                </div> */}
                <div className="text-center mt-6 mb-20 max-w-[7rem] max-h-[7rem]">
                    <Doughnut {...cruxData} />
                    <div className="text-center mx-auto" style={{ marginTop: '-50%', marginBottom: '50%',}}>{getScore(cruxgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300" style={{ }}>
                      CRUX
                      <div className='flex justify-center'>
                        <AuditsSlideModalLH type='icon' cat='CRUX' audits={audits ? audits.crux : null}/> 
                        {getScoreStatus(cruxgood)}
                      </div>
                    </div>
                </div>
            </div>
  
    </>
  )
}

export default LighthouseChart

