import React, { useState } from 'react';
import { TrashIcon } from '../../icons';
import axios from "axios";
import toast from 'react-hot-toast';
import { Button } from '@windmill/react-ui';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import Spinner from '../Buttons/Spinner'




function DeleteMemberModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/auth/member'

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const handleDeleteSite = (evt) => {
    evt.preventDefault();
    setIsLoading(true);

    var data = {
      'id': props.member.id,
      'status': 'removed'
    }
    axios.post(`${url}`, data)
    .then((res) => {
      if (res.data) {
        console.log(res.data);
        setIsLoading(false);
        setIsModalOpen(false);
        if (props.callBack){
          props.callBack()
        }
        toast.success('Member removed!');
      }
    }).catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    });
      
  }

  const ButtonType = () => {
    if(props.type==='icon'){
      return(
        <div>
          <Button onClick={openModal} layout="link" size="icon" aria-label="Delete">
            <TrashIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
        </div>
        
      )
    }else if(props.type==='button'){
      return (
        <Button layout="outline" iconRight={TrashIcon} onClick={openModal}>
            <span>Remove Member</span>
        </Button>
      )
    } else if(props.type==='dropdown'){
      return (
        <DropdownItem tag="a" onClick={openModal} className="z-40 relative">
          <TrashIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Remove Member</span>
        </DropdownItem>
      )
    }
  }



  return (
    <>

      {ButtonType()}

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Remove Member'}>
        <ModalBody>
          <p>Are you sure you want to remove "{props.member.email}" from your account?</p>
        </ModalBody>
        <ModalFooter>
          <div className="hidden sm:block">
            <Button layout="outline" onClick={handleDeleteSite} disabled={isLoading}>
              <Spinner display={isLoading}/>
              Confirm Remove
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={handleDeleteSite} disabled={isLoading}>
            <Spinner display={isLoading}/>
              Confirm Remove
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default DeleteMemberModal
