import React , { useEffect } from 'react'
import authSlice from "../store/slices/auth";
import store from '../store';
import Loader from '../components/Buttons/Loader';



function GoogleConfirm() {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const next = localStorage.getItem('next')
  const wait = ms => new Promise(res => setTimeout(res, ms));

  const user = {
    id: urlParams.get('id'),
    username: urlParams.get('username'),
    email: urlParams.get('email'),
    is_active: urlParams.get('is_active'),
    created: urlParams.get('created'),
    update: urlParams.get('updated'),
  }


  const saveInfo = () => new Promise((resolve, reject) => {
    store.dispatch(
      authSlice.actions.setAuthTokens({
        api_token: urlParams.get('api_token'),
        token: urlParams.get('token'),
        refreshToken: urlParams.get('refresh'),
      })
    );
    store.dispatch(
      authSlice.actions.setAccount(user)
    );
    resolve();
  })


  const saveAuthData = async () => {
    
    let n = 0 
    while (!store.getState().auth.token && n < 2) {
      console.log(`current auth -> ${JSON.stringify(store.getState().auth)}`)
      await saveInfo();
      await wait(1000);
      n++
    }

    if (next){
      window.location.href = next
      localStorage.removeItem('next')
    } else{
      window.location.href = '/account/new';
    }
     
  }


  useEffect(() => {

    saveAuthData();

    },[urlParams, user])


  return (
    <div className='flex h-screen bg-gray-100 dark:bg-gray-900'>
      <Loader item={'Auth'}/>
    </div>
  )
    
}
    
export default GoogleConfirm;