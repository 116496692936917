import React, { useState } from 'react';
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/outline'
import axios from "axios";
import toast from 'react-hot-toast';
import { Button } from '@windmill/react-ui';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import Spinner from '../Buttons/Spinner'
import metricsSlice from '../../store/slices/metrics';
import store from '../../store';





function UpdateIssueModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/issues/update'

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const handleUpdate = () => {

    setIsLoading(true);

    let data = {
      'ids': props.ids, 
      'updates': {'status': props.markAs}
    }

    axios.post(url, data)
      .then(() => {
        refreshData();
        if (props.callBack){props.callBack()}
      }).catch((e) => {
        if (e.response) {
          toast.error(e.response.data.reason)
          setIsLoading(false);
        }
      });
  }

  const refreshData = () => {
    setIsLoading(false);
    setIsModalOpen(false);
    axios.get(`${BACKEND_URL}/v1/ops/metrics/home`)
      .then((r) => {
        store.dispatch(metricsSlice.actions.setMetrics(r.data))
      });
      toast.success(`Issue${props.ids ? 's' : ''} updated`);
    if(window.location.pathname!=='/issue'){
      navigate('/issue');
    }
  }
    

  const ButtonType = () => {
    if(props.type==='icon'){
      return(
        <div>
          <Button onClick={openModal} layout="link" size="icon" aria-label="Update Issue">
            {props.markAs === 'open' ? 
              <ExclamationCircleIcon className="w-5 h-5" aria-hidden="true" />
            : 
              <CheckCircleIcon className="w-5 h-5" aria-hidden="true" />
            }
          </Button>
        </div>
      )
    }else if(props.type==='button'){
      return (
        <Button layout="outline" iconRight={props.markAs === 'open' ? ExclamationCircleIcon : CheckCircleIcon} onClick={openModal}>
            <span>Mark {props.markAs}</span>
        </Button>
      )
    } else if(props.type==='dropdown'){
      return (
        <DropdownItem tag="a" onClick={openModal} className="z-40 relative">
          {props.markAs === 'open' ? 
            <ExclamationCircleIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          : 
            <CheckCircleIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          } 
          <span>Mark {props.markAs}</span>
        </DropdownItem>
      )
    }
  }



  return (
    <>

      {ButtonType()}

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Delete Issue'}>
        <ModalBody>
          <p>Are you sure you want to mark {props.ids.length} Issue{props.ids.length > 1 ? 's' : ''} as {props.markAs}?</p>
        </ModalBody>
        <ModalFooter>
          <div className="hidden sm:block">
            <Button layout="outline" onClick={handleUpdate} disabled={isLoading}>
              <Spinner display={isLoading}/>
              Confirm Update
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={handleUpdate} disabled={isLoading}>
            <Spinner display={isLoading}/>
              Confirm Update
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default UpdateIssueModal
