import React, { useState, useEffect } from 'react';
import { Button } from '@windmill/react-ui';
import { SearchIcon } from '../../icons';
import Markdown from 'react-markdown'
import Tooltip from '../Tooltip/Tooltip';
import { XIcon } from '@heroicons/react/outline';

function AuditsSlideModalYL(props) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleAudits, setVisibleAudits] = useState([]);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }


  const toggleAudit = (id) => {
    console.log(visibleAudits)
    if (visibleAudits.includes(id)){
      var ids = [...visibleAudits]
      var newIds = ids.filter(i => i !== id)
      setVisibleAudits([...newIds])
    }else{
      setVisibleAudits([...visibleAudits, id])
    }
  }


  const updateLinks = () => {
    try{
      var div = document.getElementById(`yl-${props.cat}-audits`)
      var links = div.getElementsByTagName('a')
      if (links.length > 0){
        for (var i in links) {
          if (links[i]){
            links[i].setAttribute("class", "hover:underline text-blue-600 dark:text-blue-400")
            links[i].setAttribute("target", "_blank");
          }
        }
      }
    } catch {
      console.log(null)
    }
    
  }

  const updateLists = () => {
    var div = document.getElementById(`yl-${props.cat}-audits`)
    try{
      var ul_lists = div.getElementsByTagName('ul')
      if (ul_lists.length > 0){
        for (var i in ul_lists) {
          if (ul_lists[i]){
            ul_lists[i].setAttribute("class", "list-disc pl-5")
          }
        }
      }
    }catch{}
  }

  useEffect(() => {
    setTimeout(() => {
      updateLinks();
      updateLists();
    }, 500);  
  }, [updateLinks, visibleAudits])



  const getColor = (score, is_binary) => {

    if (is_binary){
      score = score * 100
    }
    var color = '#38B43F'
    if (score >= 80) {
      color = '#38B43F'
    } else if (80 >= score && score >= 70) {
      color = '#82B436'
    } else if (70 >= score && score >= 50) {
      color = '#ACB43C'
    } else if (50 >= score && score >= 30) {
      color = '#B49836'
    } else if (30 >= score && score >= 0) {
      color = '#B46B34'
    } else {
      color = '#B43A29'
    }
    return color
  }


  function createMarkup(str) {
    return {__html: str};
  }


  const formatNumber = (num, key) => {
    if (typeof num === 'number'){
      var blackList = [
        'column', 'time', 'seconds', 'line', 'value', 
        'pixels', 'rules', 'occurrences', 'count'
      ]
      if (num >= 10000) {
        if (!blackList.includes(key)){
          num = String((num/1000000).toFixed(4))+' MB'
        }
      }
    }
    return num
  }


  const getListYL = (obj) => {
    var list = []
    var blackList = ['gzipped']
    if (typeof obj === 'object'){
      for (var [key, value] of Object.entries(obj)) {
        if (!blackList.includes(key)){
          if (typeof value === 'boolean'){
            value = value ? 'True' : 'False'
            list.push(value)
          }
          else if (typeof value !== 'object' && value !== null && typeof value !== 'boolean'){
            value = formatNumber(value, key)
            list.push(value)
          }
          else if (typeof value === 'object'){
            for (var [k, v] of Object.entries(value)){
              v = formatNumber(v, k)
              var node = (
                <div className='flex justify-start text-xs overflow-auto'>
                  <div className='overflow-auto'><code>{JSON.stringify(v)}</code></div>
                </div>
              )
              list.push(node)
            }
          }
        }
      }
    }
    if (typeof obj === 'string'){
      list.push(obj)
    }
    return list
  }


  const getTitle = (obj, i) => {
    if (obj) {
      var _obj = Object.keys(obj);
      var _item = Object.values(_obj);
      return _item[i]
    }
  }



  const formatYellowLabDetails = (offenders) => {
    try {
      if (offenders.list){
        if (typeof offenders.list === 'object'){
          if (offenders.list.byType){
            return (
              <div className='overflow-auto'>
                {
                  Array.isArray(Object.values(offenders.list.byType)[0]) ?
                    <>
                      {Object.values(offenders.list.byType).map((value, i) => (
                        <div className='my-8' key={i}>
                          {value.length !== 0 &&
                            <table className='table-auto border-2 border-slate-500 my-1'>
                              <tbody>
                                <tr className='border border-slate-500 p-1'>
                                    <th className='border border-slate-500 p-1 text-sm'>{getTitle(offenders.list.byType, i)}</th>
                                </tr>
                                {value.map((item, i) => (
                                  <tr key={i} className='border border-slate-500 p-1 text-xs'>
                                    {getListYL(item).map((v, i) => (
                                      <td className='border border-slate-500 p-1' key={i}>{v}</td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          }
                        </div>
                      ))}
                    </>
                  :
                    <>
                      {Object.values(offenders.list.byType).map((value, i) => (
                        <div className='my-8' key={i}>
                          {value.requests[0] && <p className='uppercase p-1'>{getTitle(offenders.list.byType, i)}</p>}
                          {value.requests[0] &&
                            <table className='table-auto border-2 border-slate-500'>
                              <tbody>
                                <tr className='border border-slate-500 p-1'>
                                  {value.requests[0] && Object.keys(value.requests[0]).map((key, i) => (  
                                    <th className='border border-slate-500 p-1 text-sm' key={i}>{key}</th>
                                  ))}
                                </tr>
                                {value.requests.map((item, i) => (
                                  <tr key={i} className='border border-slate-500 p-1 text-xs'>
                                    {getListYL(item).map((v, i) => (
                                      <td className='border border-slate-500 p-1' key={i}>{v}</td>
                                    ))}
                                  </tr>
                              ))}
                              </tbody>
                            </table>
                            }
                        </div>
                      ))}
                    </>
                }
              </div>
            )
          } else if (offenders.list.images || offenders.list.files){
            var list = offenders.list.images ? offenders.list.images : offenders.list.files
            var title = offenders.list.images ? 'Images' : 'Files'
            return (
              <div className='overflow-auto'>
                {list.length !== 0 &&
                  <div>
                    <p className='uppercase p-1'>{title}</p> 
                    <table className='table-auto border border-slate-500 my-1'>
                      <tbody>
                        <tr className='border border-slate-500 p-1'>
                          {Object.keys(list[0]).map((key, i) => (  
                            <th className='border border-slate-500 p-1 text-sm' key={i}>{key}</th>
                          ))}
                        </tr>
                        {list.map((item, i) => (
                          <tr key={i} className='border border-slate-500 p-1 text-xs'>
                            {getListYL(item).map((v, i) => (
                              <td className='border border-slate-500 p-1' key={i}>{v}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                }
              </div>
            )
          } else if (Array.isArray(offenders.list)){
              var list = offenders.list
              if (list.length > 0){
                if (typeof list[0] === 'string'){
                  return (
                    <div className='overflow-auto'>
                      <ol className='list-decimal text-xs'>
                        {list.map((item, i) => (
                          <li key={i}>{`${i+1}. ${item}`}</li>
                        ))}
                      </ol>
                    </div>
                  )
                } else if (list[0].rules){
                    return (
                      <div className='overflow-auto'>
                        {
                          list.map((obj, i) => (
                            <div key={i} className='my-8'>
                              <code className='p-1'>{obj.property}</code>
                              <table className='table-auto border border-slate-500 my-1'>
                                <tbody>
                                  <tr className='border border-slate-500 p-1'>
                                    {obj.rules[0] && Object.keys(obj.rules[0]).map((key, i) => (  
                                      <th className='border border-slate-500 p-1 text-sm' key={i}>{key}</th>
                                    ))}
                                  </tr>
                                  {obj.rules.map((item, i) => (
                                    <tr key={i} className='border border-slate-500 p-1 text-xs'>
                                      {getListYL(item).map((v, i) => (
                                        <td className='border border-slate-500 p-1' key={i}>{v}</td>
                                      ))}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <span className='text-xs bg-blue-600 w-auto p-1 text-white'>
                                * {obj.message}
                              </span>
                            </div>
                          ))
                        }
                      </div>
                    )
                }
                return (
                  <div className='overflow-auto'>
                    {list.length !== 0 &&
                      <table className='table-auto border border-slate-500 my-1'>
                        <tbody>
                          <tr className='border border-slate-500 p-1'>
                            {Object.keys(list[0]).map((key, i) => (  
                              <th className='border border-slate-500 p-1 text-sm' key={i}>{key}</th>
                            ))}
                          </tr>
                          {list.map((item, i) => (
                            <tr key={i} className='border border-slate-500 p-1 text-xs'>
                              {getListYL(item).map((v, i) => (
                                <td className='border border-slate-500 p-1' key={i}>{v}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    }
                  </div>
                )
              }
          }
        }
      } else if (offenders.byFile){
        return (
          <div className='overflow-auto'>
            {offenders.byFile.map((value, i) => (
              <div className='my-8' key={i}>
                <p className='p-1'>{value.url}</p>
                {value.offenders[0] &&
                  <table className='table-auto border-2 border-slate-500 my-1'>
                    <tbody>
                      <tr className='border border-slate-500 p-1'>
                        {value.offenders[0] && Object.keys(value.offenders[0]).map((key, i) => (  
                          <th className='border border-slate-500 p-1 text-sm' key={i}>{key}</th>
                        ))}
                      </tr>
                      {value.offenders.map((item, i) => (
                        <tr key={i} className='border border-slate-500 p-1 text-xs'>
                          {getListYL(item).map((v, i) => (
                            <td className='border border-slate-500 p-1' key={i}>{v}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                </table>
                }
              </div>
            ))}
  
          </div>
        )
      } else if (offenders.palette || Array.isArray(offenders)) {
        var list = offenders.palette ? offenders.palette : offenders
        if (typeof list[0] === 'string'){
          return(
            <div className='overflow-auto'>
              <ol className='list-decimal text-xs pl-5'>
                {list.map((item, i) => (
                  <li key={i} className='text-xs'>{`${item}`}</li>
                ))}
              </ol>
            </div>
          )
        }
        return (
          <div className='overflow-auto'>
            {list.length !== 0 &&
              <table className='table-auto border border-slate-500 my-1'>
                <tbody>
                  <tr className='border border-slate-500 p-1'>
                    {Object.keys(list[0]).map((key, i) => (  
                      <th className='border border-slate-500 p-1 text-sm' key={i}>{key}</th>
                    ))}
                  </tr>
                  {list.map((item, i) => (
                    <tr key={i} className='border border-slate-500 p-1 text-xs'>
                      {getListYL(item).map((v, i) => (
                        <td className='border border-slate-500 p-1' key={i}>{v}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          </div>
        )
      }
      
    } catch (error) {
      console.log(error)
    }

    return null;
    
  }






  const ButtonType = () => {
    if(props.type==='icon'){
      return(
          <Tooltip content='view details'>
            <div onClick={openModal} className='mx-2 cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-6 h-6 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white'>
              <SearchIcon className="w-5 h-5" aria-hidden="true" />
            </div>
          </Tooltip>
      )
    }else if(props.type==='button'){
      return (
        <div>
          <Button onClick={openModal} iconRight={SearchIcon} layout='outline'>
            View Audits
          </Button>
        </div>
      )
    }else if(props.type==='text'){
      return (
        <span onClick={openModal} className="cursor-pointer">
            { props.text ? props.text : 'View Audits' }
        </span>
      )
    }else if(props.type==='wrapper'){
      return (
        <div onClick={openModal} className="cursor-pointer">
          {props.children}
        </div>
      )
    }
  }



  return (
    <>

      {ButtonType()}
        
          <div className={`shadow-lg p-6 rounded-lg bg-white dark:bg-gray-700 max-w-2xl min-w-[50%] h-[98%] z-50 absolute top-2 -right-3 ${isModalOpen ? '-translate-x-6 transition ease-in-out duration-300' : 'hidden translate-x-6 transition ease-in-out duration-300'}`}>  

            {/* modal header */}
            <div className='flex justify-between w-full mb-2'>
              <div>
                <h1 className='text-lg font-bold text-gray-700 dark:text-gray-400'>{props.cat} Audits</h1>
              </div>
              <div>
                <div onClick={closeModal} className='cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white'>
                  <XIcon className="w-5 h-5" aria-hidden="true" />
                </div>
              </div>
            </div>

            {/* modal body */}
            <div className='overflow-auto h-[95%]' id={`yl-${props.cat}-audits`}>
                {props.audits && 
                props.audits.map((audit, i) => (

                  <div className='flex justify-start' key={i}> 
                    <div className='my-4 h-auto w-[1%] rounded-l-md' style={{backgroundColor: (audit.policy ? getColor(audit.score, false) : getColor(audit.score, true) ) }}></div>
                    <div className='my-4 rounded-r-md px-2 py-2 bg-gray-100 dark:bg-gray-600 w-[99%] text-gray-600 dark:text-gray-300' >
                      <div 
                        className='flex justify-between cursor-pointer' 
                        onClick={() => {toggleAudit((props.cat+i).replace(/\s+/g, '-'))}}
                      >
                        <h3 className='font-semibold text-[14px] mb-2 text-left'>
                          <Markdown>{audit.policy.label}</Markdown>
                        </h3>
                        <h3>
                          { audit.value && <span className='text-[14px] font-bold my-2'>{audit.policy.label.includes('time') ? (String(audit.value/1000) + ' s') : formatNumber(audit.value, audit.policy.units)}</span> }
                        </h3>
                      </div>
                      { visibleAudits.includes((props.cat+i).replace(/\s+/g, '-')) &&
                        <div id={audit.id ? `${props.cat}-${audit.id}` : (props.cat+i).replace(/\s+/g, '-') } className={'px-8 mt-2 cursor-text overflow-auto text-left'}>
                          { audit.value && <h5 className='text-sm font-bold my-2 underline'>{audit.value}</h5> }
                          { audit.policy && <h5 className='text-sm mb-4' dangerouslySetInnerHTML={createMarkup(audit.policy.message)}></h5> }
                          { audit.offendersObj && formatYellowLabDetails(audit.offendersObj) }
                        </div>
                      }

                    </div>
                  </div>
                  
                ))}

            </div>
            
          

          </div>
    </>
  )
}

export default AuditsSlideModalYL


