import React, { useState, useEffect } from 'react';
import { Input, Button, Label, Textarea, Select } from '@windmill/react-ui';
import { useSelector } from 'react-redux'
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import axios from "axios";
import toast from 'react-hot-toast';
import { Copy } from '../../icons';
import { CodeIcon, ExclamationIcon, 
  PhotographIcon, PresentationChartBarIcon, 
  PresentationChartLineIcon, CheckIcon } from '@heroicons/react/outline';
import Spinner from '../Buttons/Spinner'
import Toggle from '../Toggle/Toggle';





function TestModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/test'
  const _url = BACKEND_URL + '/v1/ops/tests/create'
  const devices_url = BACKEND_URL + '/v1/ops/device'
  const teamConfigs = useSelector(state => state.team.team.configs)

  const [window_size, setWindowSize] = useState()
  const [browser, setBrowser] = useState()
  const [device, setDevice] = useState()
  const [location, setLocation] = useState()
  const [deviceId, setDeviceId] = useState(6)
  const [devices, setDevices] = useState(null)
  const [mask_ids, setMaskIds] = useState('')
  const [interval, setInterval] = useState()
  const [timeout, setTimeout] = useState()
  const [min_wait_time, setMinTime] = useState()
  const [max_wait_time, setMaxTime] = useState()
  const [disableAnimations, setDisableAnimation] = useState()
  const [autoHeight, setAutoHeight] = useState()
  const [createIssue, setCreateIssue] = useState()
  const [test_type, setTestType] = useState(['html', 'logs', 'lighthouse', 'yellowlab', 'vrt']);
  const [threshold, setThreshold] = useState(95)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfigs, setShowConfigs] = useState(false);


  useEffect(() => {
    getDevices();
    setWindowSize(teamConfigs ? teamConfigs.window_size : '1920,1080')
    setBrowser(teamConfigs ? (teamConfigs.browser !== undefined ? teamConfigs.browser : 'chrome') : 'chrome')
    setDevice(teamConfigs ? teamConfigs.device : 'desktop')
    teamConfigs ? (teamConfigs.location !== undefined ? teamConfigs.location : 'us') : 'us'
    setMaskIds(teamConfigs ? teamConfigs.mask_ids : '')
    setInterval(teamConfigs ? teamConfigs.interval : 1)
    setTimeout(teamConfigs ? teamConfigs.timeout : 300)
    setMinTime(teamConfigs ? teamConfigs.min_wait_time : 3)
    setMaxTime(teamConfigs ? teamConfigs.max_wait_time : 60)
    setDisableAnimation(teamConfigs ? teamConfigs.disable_animations : false)
    setAutoHeight(teamConfigs ? teamConfigs.auto_height : true)
    setCreateIssue(teamConfigs ? teamConfigs.create_issue : true)

  }, [])


  const getDevices = async () => {
    await axios.get(devices_url)
      .then((r) => {
        let _devices = r.data.devices
        let _device = null
        setDevices(_devices)
        for (var i in _devices) {
          if (_devices[i].browser === teamConfigs.browser && 
            _devices[i].name === teamConfigs.device) {
            _device = _devices[i]
          }
        }
        if (_device){
          setDeviceId(_device.id)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  


  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const testTypes = [
    {
      value: "html", 
      name: "HTML Page Source",
    },
    {
      value: "logs", 
      name: "Console Logs",
    },
    {
      value: "lighthouse", 
      name: "Lighthouse Metrics",
    },
    {
      value: "yellowlab", 
      name: "YellowLab Metrics",
    },
    {
      value: "vrt", 
      name: "Visual Regression",
    },
  ]


  const getIcon = (value) => {
    let icon = null
    switch(value) {
      case 'html':
        icon = <CodeIcon className='h-6 w-8'/>
        break;
      case 'logs':
        icon = <ExclamationIcon className='h-6 w-6'/>
        break;
      case 'lighthouse':
        icon = <PresentationChartBarIcon className='h-6 w-6'/>
        break;
      case 'yellowlab':
        icon = <PresentationChartLineIcon className='h-6 w-6'/>
        break;
      case 'vrt':
        icon = <PhotographIcon className='h-6 w-6'/>
        break;
    }
    return icon
  }


  const handleTestType = (value) => {
    if (test_type.includes(value)){
      var list = [...test_type]
      var newList = list.filter(i => i !== value)
      setTestType([...newList])
    }else{
      setTestType([...test_type, value])
    }
  }


  const handleCreateTest = () => {

    const configs = {
      "window_size": window_size.length > 0 ? window_size : '1920,1080',
      "browser": browser,
      "device": device,
      "location": location,
      "interval": interval != null ? interval : 1,
      "mask_ids": mask_ids,
      "max_wait_time": max_wait_time != null ? max_wait_time : 60,
      "min_wait_time": min_wait_time != null ? min_wait_time : 3,
      "timeout": timeout != null ? timeout : 300,
      "disable_animations": disableAnimations,
      "auto_height": autoHeight,
      "create_issue": createIssue
    }
    
    var type_default = ['html', 'logs', 'lighthouse', 'yellowlab', 'vrt']

    if (props.siteIds || props.pageIds){
      var data = {
        "site_ids": props.siteIds ? props.siteIds : null,
        "page_ids": props.pageIds ? props.pageIds : null,
        "type": test_type.length === 0 ? type_default : test_type,
        "configs": configs,
        "threshold": threshold,
      }
  
      setIsLoading(true);
  
      axios.post(`${_url}`, data)
      .then((r) => {
        if (r.data) {
          setIsLoading(false);
          setIsModalOpen(false);
          toast.success(`Started ${r.data.num_succeeded} Tests`);
          if (!r.data.success){
            toast.error(`${r.data.num_failed} Tests Failed`);
          }
          props.callBack && props.callBack();
        }
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.reason)
          setIsLoading(false);
        }
      })

    }else{
      var data = {
        "site_id": props.site ? props.site.id : null,
        "page_id": props.page ? props.page.id : null,
        "type": test_type.length === 0 ? type_default : test_type,
        "configs": configs,
        "threshold": threshold,
      }
  
      setIsLoading(true);
  
      axios.post(`${url}`, data)
      .then((res) => {
        if (res.data) {
          setIsLoading(false);
          setIsModalOpen(false);
          toast.success('Test started');
          props.callBack && props.callBack();
        }
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.reason)
          setIsLoading(false);
        }
      })
    }

    
  }


  const ButtonType = () => {
    if(props.type==='icon'){
      return(
          <Button onClick={openModal} layout="link" size="icon" aria-label="Test">
            <Copy className="w-5 h-5" aria-hidden="true" />
          </Button>
      )
    }else if(props.type==='button'){
      return (
        <div>
          <Button className='w-[9rem]' onClick={openModal} iconRight={Copy} layout='outline'>
            Create Test
          </Button>
        </div>
      )
    }else if(props.type==='text'){
      return (
        <span onClick={openModal} className="cursor-pointer">
            { props.text ? props.text : 'New Test' }
        </span>
      )
    }else if(props.type==='dropdown'){
      return (
        <DropdownItem tag="a" onClick={openModal} className="z-40 relative">
          <Copy className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Create Test</span>
        </DropdownItem>
      )
    }
  }



  return (
    <>

      {ButtonType()}

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'New Test'}>
        
        <ModalBody>
        
          <div className='overflow-auto px-3' style={{ maxHeight: '32rem' }}>
            
            <div className="">
                <Label className="text-sm font-semibold">Test Type:</Label>
                <div className='flex justify-between'>
                  {testTypes.map((type, i) => (
                    <div key={i}>
                      { test_type.includes(type.value) &&
                        <div className={`rounded-full bg-green-300 text-green-700 p-1 relative top-6 w-5 h-5 -mt-5`}>
                          <CheckIcon className='h-3 w-3'/>
                        </div>
                      }
                      <div
                        onClick={() => handleTestType(type.value)}
                        className={`text-wrap cursor-pointer mt-2 h-24 w-20 rounded-lg border-2 mx-auto text-center text-xs flex flex-col justify-evenly ${test_type.includes(type.value) ? 'text-gray-600 dark:text-gray-300 border-blue-600' : 'text-gray-600 dark:text-gray-400 border-gray-600 dark:border-gray-400'}`}
                      >
                        <div className='mx-auto'>{getIcon(type.value)}</div>
                        <p>{type.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
            </div>
            <div className='mt-8'>
              <div className='flex justify-between mb-2'>
                <label htmlFor="threshold-range" className="my-auto block mb-2 text-sm text-gray-800 dark:text-gray-400">At what score should the Test "Fail"?</label>
                <span className='mr-2 text-lg font-bold text-gray-600 dark:text-gray-400 px-2 py-1'>
                  {threshold}%
                </span>
              </div>
              <input 
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 accent-blue-600"
                id="threshold-range" 
                type="range" 
                min="60" 
                max="99" 
                value={threshold}
                onChange={(e) => setThreshold(e.target.value)}
              >
              </input>
            </div>
            <div className='mt-6'>
              <span
                className='text-sm underline text-blue-600 cursor-pointer w-auto'
                onClick={() => setShowConfigs(!showConfigs)}
              >
                {showConfigs ? 'Hide' : 'Show'} Advanced
              </span>
            </div>
            {showConfigs &&
              <div className='my-8 p-4 rounded-md border-[1.5px] dark:border-gray-600 border-gray-400'>
                <div className='mb-4'>
                  <Label className="mb-2">Location</Label>
                  <Select className="rounded-md p-2 mt-1"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value)
                    }}
                  >
                    <option value="us">United States</option>
                    <option value="eu">Europe</option>
                    <option value="au" disabled={true} className='cursor-not-allowed'>Austraila</option>
                    <option value="ca" disabled={true} className='cursor-not-allowed'>Canada</option>
                  </Select>
                </div>
                <div className='grid gap-6 grid-cols-2'>
                  <div className='mb-4'>
                    <Label className="mb-2" >Browser</Label>
                    <Select className="rounded-md p-2 mt-1"
                      value={browser}
                      onChange={(e) => {
                        setBrowser(e.target.value)
                        for (var i in devices) {
                          if (devices[i].browser === e.target.value && 
                            devices[i].name === device) {
                              setDeviceId(devices[i]['id'])
                          }
                        }
                      }}
                    >
                      <option value="chrome">Chrome</option>
                      <option value="firefox">Firefox</option>
                      <option value="edge">Edge</option>
                    </Select>
                  </div>
                  <div className='mb-4'>
                    <Label className="mb-2">Device</Label>
                    <Select className="rounded-md p-2 mt-1"
                      value={deviceId}
                      onChange={(e) => {
                        let _device = devices.find(item => item.id === e.target.value)
                        setDeviceId(_device.id)
                        setDevice(_device.name)
                        setWindowSize(_device.window_size)
                      }}
                    >
                      {devices && devices.map((item, i) => (
                        item.browser === browser && 
                          <option
                            key={i} 
                            value={item.id} 
                          >
                            {item.name}
                          </option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className='grid gap-4 mb-8 grid-cols-2'>
                  <div className='grid grid-cols-2 gap-3'>

                    <Label className="mt-4">
                      <span>Interval (s)</span>
                      <Input 
                        className='rounded-md p-2 max-w-[6rem]'
                        onChange={e => setInterval(e.target.value)} 
                        value={interval}
                        type='number'  
                        placeholder={5}/>
                    </Label>

                    <Label className="mt-4">
                      <span>Timeout (s)</span>
                      <Input 
                        className='rounded-md p-2 max-w-[6rem]'
                        onChange={e => setTimeout(e.target.value)} 
                        value={timeout}
                        type='number'  
                        placeholder={300}/>
                    </Label>

                  </div>
                  <div className='grid grid-cols-2 gap-3'>

                    <Label className="mt-4">
                      <span>Min Wait (s)</span>
                      <Input
                        className='rounded-md p-2 max-w-[6rem]' 
                        onChange={e => setMinTime(e.target.value)} 
                        value={min_wait_time}
                        type='number'  
                        placeholder={10}/>
                    </Label>

                    <Label className="mt-4">
                    <span>Max Wait (s)</span>
                      <Input 
                        className='rounded-md p-2 max-w-[6rem]'
                        onChange={e => setMaxTime(e.target.value)} 
                        value={max_wait_time}
                        type='number'  
                        placeholder={60}/>
                    </Label>

                  </div>
                  

                  <Label className="mt-4">
                    <span>Window Size (x,y)</span>
                    <Input
                      className='rounded-md p-2' 
                      onChange={e => setWindowSize(e.target.value)} 
                      value={window_size} 
                      placeholder={'1920,1080'}/>
                  </Label>

                  <div>
                    <div className="mt-6">
                      <div className='flex justify-end mt-2'>
                        <span className='mr-4 text-sm text-gray-800 dark:text-gray-400'>Disable Animations</span>
                        <div>
                          <Toggle id='test-disable-animations' small checked={disableAnimations} onChange={() => setDisableAnimation(!disableAnimations)} />
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                        <div className='flex justify-end mt-2'>
                          <span className='mr-4 text-sm text-gray-800 dark:text-gray-400'>Auto Height</span>
                          <div>
                            <Toggle id='test-auto-height' small checked={autoHeight} onChange={() => setAutoHeight(!autoHeight)} />
                          </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <div className='flex justify-end mt-2'>
                          <span className='mr-4 text-sm text-gray-800 dark:text-gray-400'>Create Issue</span>
                          <div>
                            <Toggle id='test-create-issue' small checked={createIssue} onChange={() => setCreateIssue(!createIssue)} />
                          </div>
                        </div>
                    </div>
                  </div>

                </div>

                <Label className="mt-2 mb-4">
                  <span>Element ID's to Mask (seperated by commas)</span>
                  <Textarea 
                    className='rounded-md p-2 '
                    onChange={e => setMaskIds(e.target.value)} 
                    value={mask_ids}
                    type='text'  
                    rows='2'
                    placeholder={'example-id-1, example-id-2'}/>
                </Label>
              </div>
            }

          </div>

        </ModalBody>
        
        
        
        
        <ModalFooter>

          <div className="hidden sm:block">
            <Button layout="outline" onClick={handleCreateTest} disabled={isLoading}>
            <Spinner display={isLoading}/>
              {'Submit'}
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={handleCreateTest} disabled={isLoading}>
            <Spinner display={isLoading}/>
              {'Submit'}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default TestModal


