import React, { useState } from 'react';
import { Button, Badge } from '@windmill/react-ui';
import { SearchIcon } from '../../icons';
import Tooltip from '../Tooltip/Tooltip';
import { XIcon, DownloadIcon } from '@heroicons/react/outline';
import smallLogo from '../../assets/img/logo_small.png'






function InvoiceSlideModal(props) {

  const [isModalOpen, setIsModalOpen] = useState(false);


  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }


  const getStatusBadge = (status) => {
    let badge = null
    switch(status) {
      case 'draft':
        badge = <Badge type='primary'>{status}</Badge>
        break;
      case 'open':
        badge = <Badge type='neutral'>{status}</Badge>
        break;
      case 'void':
        badge = <Badge type='warning'>{status}</Badge>
        break;
      case 'paid':
        badge = <Badge type='success'>{status}</Badge>
        break;
      case 'uncollectible':
        badge = <Badge type='warning'>{status}</Badge>
        break;
    }
    return badge
  }



  function getTimeStamp(timestamp) {
    let date = new Date(timestamp * 1000);
    let month = date.toLocaleString('default', { month: 'short' });
    let year = date.getFullYear();
    let day = date.getDate();
    return `${month} ${day}, ${year}`
  }


  const downloadInvoice = (link) => {
    window.open(link)
  }

  const ButtonType = () => {
    if(props.type==='icon'){
      return(
          <Tooltip content='view details'>
          <Button onClick={openModal} layout="link" size="icon" aria-label="View Invoice">
            <SearchIcon className="w-5 h-5 mx-2 rounded" aria-hidden="true" />
          </Button>
          </Tooltip>
      )
    }else if(props.type==='button'){
      return (
        <div>
          <Button onClick={openModal} iconRight={SearchIcon} layout='outline'>
            View Invoice
          </Button>
        </div>
      )
    }else if(props.type==='text'){
      return (
        <span onClick={openModal} className="cursor-pointer">
          { props.text ? props.text : 'View Invoice' }
        </span>
      )
    }else if(props.type==='wrapper'){
      return (
        <div onClick={openModal} className="cursor-pointer">
          {props.children}
        </div>
      )
    }
  }



  return (
    <>

      {ButtonType()}
        
          <div className={`cursor-auto shadow-lg p-6 rounded-lg bg-white dark:bg-gray-700 max-w-2xl min-w-[50%] h-[98%] z-50 absolute top-2 -right-3 ${isModalOpen ? '-translate-x-6 transition ease-in-out duration-300' : 'hidden translate-x-6 transition ease-in-out duration-300'}`}>  

            {/* modal header */}
            <div className='flex justify-between w-full mb-6'>
              <div>
                <code>
                  <pre>
                    <span className='font-semibold text-gray-700 dark:text-gray-400 cursor-text'>{props.invoice.number}</span>
                  </pre>
                </code>
              </div>
              <div className='flex justify-end'>
                <Button 
                  iconLeft={DownloadIcon} 
                  layout='outline' 
                  size='small'
                  className='mr-4'
                  onClick={() => downloadInvoice(props.invoice.invoice_pdf)}
                >
                  Download
                </Button>
                <div onClick={closeModal} className='cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white'>
                  <XIcon className="w-5 h-5" aria-hidden="true" />
                </div>
              </div>
            </div>

            {/* modal body */}
            <div className='cursor-text p-6 dark:bg-gray-800 bg-gray-50 rounded h-[90%] overflow-auto w-full'>
              <div className='grid grid-cols-4'>
                <div className='col-span-3'>
                  
                  <div>
                    <div className='flex justify-start'>
                      <h3 className='font-bold text-2xl dark:text-gray-200'>Invoice</h3>
                      <div className='ml-4 my-auto'>{getStatusBadge(props.invoice.status)}</div>
                    </div>
                    <div className='mt-6'>
                      <p className='font-semibold dark:text-gray-300'>Invoice Number: <span className='ml-3 font-light text-sm'>{props.invoice.number}</span></p>
                      <p className='font-semibold dark:text-gray-300'>Created: <span className='ml-[4.55rem] font-light text-sm'>{getTimeStamp(props.invoice.created)}</span></p>
                      <p className='font-semibold dark:text-gray-300'>Due Date: <span className='ml-16 font-light text-sm'>{getTimeStamp(props.invoice.created)}</span></p>
                    </div>
                  </div>

                  <div className='grid grid-cols-2 mt-6'>
                    <div className='dark:text-gray-300'>
                      <h4 className='font-semibold '>Cursion</h4>
                      <p className='font-light text-sm'>San Antonio, Texas 78245</p>
                      <p className='font-light text-sm'>United States</p>
                      <p className='font-light text-sm'>hello@cursion.dev</p>
                    </div>
                    <div className='dark:text-gray-300'>
                      <h4 className='font-semibold '>Bill to</h4>
                      <p className='font-light text-sm'>{props.invoice.customer_name}</p>
                      <p className='font-light text-sm'>{props.invoice.customer_email}</p>
                    </div>
                  </div>

                </div>

                <div className='justify-self-end col-span-1'>
                  <img src={smallLogo} className='w-12 h-12'/>
                </div>

              </div>

              <h3 className='mt-12 font-semibold text-xl dark:text-gray-300'>
                $ {Number((props.invoice.price_amount/100).toFixed(2)).toLocaleString()} USD due {getTimeStamp(props.invoice.created)}
              </h3>
              

              <div className='mt-12 grid grid-cols-5 text-xs font-light border-b-[1px] border-gray-400 pb-1'>
                <div className='col-span-2'>
                  <span>Description</span>
                </div>
                <div className='justify-items-end justify-self-end'>
                  <span>Qty</span>
                </div>
                <div className='justify-items-end justify-self-end'>
                  <span>Unit Price</span>
                </div>
                <div className='justify-items-end justify-self-end'>
                  <span>Amount</span>
                </div>
              </div>
              {props.invoice.items.map((item, i) => (
              <div className='mt-2 grid grid-cols-5 text-[.8rem] font-light' key={i}>
                <div className='col-span-2'>
                  <p className='truncate font-medium'>{item.description}</p>
                  <p className='text-[.7rem]'>{getTimeStamp(item.period_start)} - {getTimeStamp(item.period_end)}</p>
                </div>
                <div className='justify-items-end justify-self-end'>
                  <span>{item.quantity}</span>
                </div>
                <div className='justify-items-end justify-self-end'>
                 {!item.proration && <span>$ {Number((props.invoice.price_amount/100).toFixed(2)).toLocaleString()}</span> }
                </div>
                <div className='justify-items-end justify-self-end'>
                  <span>$ {Number((item.amount/100).toFixed(2)).toLocaleString()}</span>
                </div>
              </div>
              ))}

              <div className='mt-12 grid grid-cols-1 text-xs font-light dark:text-gray-300 w-full'>
                <div className='justify-self-end w-1/2'>

                    <div className='flex justify-between border-t-[1px] border-gray-400 pt-1 mb-1'>
                      <div><span>Subtotal</span></div>
                      <div><span>$ {Number((props.invoice.subtotal/100).toFixed(2)).toLocaleString()}</span></div>
                    </div>
                    <div className='flex justify-between border-t-[1px] border-gray-400 pt-1 mb-1'>
                      <div><span>Total</span></div>
                      <div><span>$ {Number((props.invoice.price_amount/100).toFixed(2)).toLocaleString()}</span></div>
                    </div>
                    <div className='flex justify-between border-t-[1px] border-gray-400 pt-1 mb-1 font-bold'>
                      <div><span>Amount due</span></div>
                      <div><span>$ {Number((props.invoice.price_amount/100).toFixed(2)).toLocaleString()}</span></div>
                    </div>

                </div>
              </div>

            </div>
            
          

          </div>
    </>
  )
}

export default InvoiceSlideModal


