import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Gear from '../Buttons/Gear';
import {TableHeader, TableCell, TableBody, TableRow, TableFooter,
  TableContainer, Badge, Pagination} from '@windmill/react-ui'
import Table from './Table'
import axios from 'axios'
import { ArrowSmDownIcon, ArrowSmUpIcon, MinusSmIcon } from '@heroicons/react/outline';
import Loader from '../Buttons/Loader';
import Tooltip from '../Tooltip/Tooltip';
import DeleteTestModal from '../Modals/DeleteTestModal';

const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;

function TestsTable(props) {

  const emptyTests = {results:[], count:0}
  const [tests, setTests] = useState(emptyTests)
  const [offSet, setOffSet] = useState(0)
  const [loading, setLoading] = useState(true)

  let getTestsUrl = `${BACKEND_URL}/v1/ops/test?lean=true&page_id=${props.page.id}&limit=10&offset=${offSet}`;

  const getData = async () => {
    await axios.get(getTestsUrl)
      .then((r) => {
        setTests(emptyTests)
        setTests(r.data)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }
  
  useEffect(() => {
    getData()
    return () => {
      getData;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 20000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [offSet]);

  // setup pages control for table
  const pageTable = 1

  // pagination setup
  const resultsPerPage = 10

  // pagination change control
  function onPageChangeTable(p) {
    if(p){
      let off_set = (p-1)*10
      setOffSet(off_set)
      let paginatedUrl = `${BACKEND_URL}/v1/ops/test?lean=true&page_id=${props.page.id}&limit=10&offset=${off_set}`
      axios.get(paginatedUrl)
        .then((r) => {
          setTests(r.data);
      })
    }
    
  }

  function getScore(test) {
    let badge = 'neutral';
    let health = 'no data';
    let value = health;
    if(test.score!==null){
        let score = test.score < 100 ? Number(test.score.toFixed(2)) : test.score
        if (test.status === 'passed'){
          health = 'Passed'
          badge = 'success'
        } else if (test.status === 'failed') {
          health = 'Failed'
          badge = 'danger'
        }
        value = health+' - '+score+'%';
    }
    if (test.time_completed === null){
      value = 'working'
      badge = 'neutral'
    }

    return <Badge type={badge}>{value} {value === 'working' && <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/>}</Badge>
  }



  function getHealthDelta(test) {
    let badge = 'neutral'
    let value = 'no data'
    let delta_total = 0
    let d = 0
    let icon = <MinusSmIcon/>

      if (test.lighthouse_delta!==null){
        if(test.lighthouse_delta.scores.average_delta!==null){
          delta_total = delta_total + test.lighthouse_delta.scores.average_delta
          d += 1
        }
      }
      if (test.yellowlab_delta!==null){
        if(test.yellowlab_delta.scores.average_delta!==null){
          delta_total = delta_total + test.yellowlab_delta.scores.average_delta          
          d += 1
        }
      }
  
      if (d > 0){

        delta_total = Math.round((delta_total / d) * 100 ) / 100
        let delta = String(delta_total).replace('-','')
  
        if (delta_total <= -10 ) {
          icon = <ArrowSmDownIcon className='mr-2 h-4 mt-[.1rem]'/>
          badge = 'danger';
        }else if (delta_total > -10 && delta_total < 0) {
          icon = <ArrowSmDownIcon className='mr-2 h-4 mt-[.1rem]'/>
          badge = 'warning';
        }else if (delta_total === 0) {
          icon = <MinusSmIcon className='mr-2 w-4 mt-[.1rem]'/>
          badge = 'neutral';
        }else if (delta_total > 0){
          icon = <ArrowSmUpIcon className='mr-2 h-4 mt-[.1rem]'/>
          badge = 'success';
        }else {
          return <Badge type={badge}>{value}</Badge>
        }
        
        value = <div className='flex'>{icon} {delta+'%'}</div>
      }   
    
    return <Badge type={badge}>{value}</Badge>
  }



  function getDate(test) {
    if(test.time_created){
        let date = new Date(test.time_created).toLocaleDateString();
        let time = new Date(test.time_created).toLocaleTimeString('en-US');
        let formatedDateTime = `${date} ${time}`
        return formatedDateTime
    }else{
        return '––';
    }
    
  }

  if (loading){
   return <Loader item={'Tests'}/>
  }


  return (
    <>
      <TableContainer className="mb-8 dark:bg-gray-800 bg-white">
        <h1 className="text-gray-600 dark:text-gray-300" style={{ margin: '.7em', fontSize: '1.3em', fontWeight: 600, }}>Tests</h1>
        <Table>
          <TableHeader >
            <tr className='dark:bg-gray-800 bg-white'>
              <TableCell>Created</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Health Delta</TableCell>
              <TableCell>Actions</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {tests.results.slice((pageTable - 1) * resultsPerPage, pageTable * resultsPerPage).map((test, i) => (
              <TableRow key={i} className="hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer">
                
                  <TableCell>
                    <Link to={`/test/${test.id}`}>
                      <span className="text-sm">{getDate(test)}</span>
                    </Link>
                  </TableCell>
                
                
                  <TableCell>
                    <Link to={`/test/${test.id}`}>
                      {getScore(test)}
                    </Link>
                  </TableCell>
                
                
                  <TableCell>
                    <Link to={`/test/${test.id}`}>
                      {getHealthDelta(test)}
                    </Link>
                  </TableCell>

                  <TableCell>
                    <Tooltip content='delete test'>
                      <DeleteTestModal type='icon' callBack={getData} test={test}/>
                    </Tooltip>
                  </TableCell>
              
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={tests.count}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}




export default TestsTable

