import React, {useState} from 'react';
import LighthouseChart from '../Charts/LighthouseChart'
import YellowlabChart from '../Charts/YellowlabChart'
import { Card, CardBody, Button } from '@windmill/react-ui';



function ScoresCard(props) {

    const [lh, setLh] = useState('block')
    const [yl, setYl] = useState('none')
    const [lhButton, setLhButton] = useState('primary')
    const [ylButton, setYlButton] = useState('outline')


    function getLh(){
        setLh('block')
        setYlButton('outline')
        setLhButton('primary')
        setYl('none')
    }

    function getYl(){
        setLh('none')
        setYlButton('primary')
        setLhButton('outline')
        setYl('block')
    }

    return (
        <>
        <Card className="">
            <CardBody className="">
                <p className="mb-2 font-semibold text-gray-600 dark:text-gray-300">{props.card_title}</p>
                <div className="flex justify-arround mb-3">
                    <div className="m-4">
                        <Button
                            size="small"
                            layout={lhButton}
                            onClick={() => getLh()}
                        >
                            Lighthouse
                        </Button>
                    </div>
                    <div className="m-4">
                        <Button
                            size="small"
                            layout={ylButton}
                            onClick={() => getYl()}
                        >
                            Yellow Lab
                        </Button>
                    </div>
                </div>
                <div style={{ display: lh }} className="mb-8">
                    <LighthouseChart scan={props.scan} />
                </div>
                <div style={{ display: yl }} className="mb-8">
                    <YellowlabChart scan={props.scan} />
                </div>
            </CardBody>
        </Card>
        
        </>

    )
}

export default ScoresCard