import React from 'react';
import { Card, CardBody } from '@windmill/react-ui';



function ConfigCard(props) {


    return (
        <>
        <Card className="mt-4">
            <CardBody className="">
                <p className="mb-2 font-semibold text-gray-600 dark:text-gray-300">{props.card_title}</p>
                <div style={{ maxHeight: '24rem' }} className="p-4 overflow-auto text-sm whitespace-pre-wrap text-gray-600 dark:text-gray-100">
                    <pre><code>{props.configs ? JSON.stringify(props.configs, null, 2) : null}</code></pre>
                </div>
            </CardBody>
        </Card>
        
        </>
    )
}

export default ConfigCard