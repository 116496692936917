import React, { useState } from 'react';
import { Input, Textarea, Button, Label } from '@windmill/react-ui';
import siteDataSlice from "../../store/slices/siteData";
import axios from "axios";
import store from '../../store';
import toast from 'react-hot-toast';
import Spinner from '../Buttons/Spinner'
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'




function SiteModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/site'
  const sites_url = BACKEND_URL + '/v1/ops/site'

  const [site_url, setSiteUrl] = useState('');
  const [page_urls, setPageUrls] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false)


  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      handleAddSite(evt)
    }
  }

  const handleAddSite = () => {

    // cleaning page_urls if present
    var _page_urls = null
    if (page_urls.length > 0){
      _page_urls = []
      if (page_urls.includes(',')){
        var temp_urls = page_urls.split(',')
      }else{
        var temp_urls = page_urls.split('\n')
      }
      for (var i in temp_urls) {
        var _url = temp_urls[i].trim()
        _page_urls.push(_url)
      }
    }
    
    const data = {
      "site_url": site_url,
      "page_urls": _page_urls
    }

    setIsLoading(true);
    if(site_url === ''){
      setIsLoading(false);
      toast.error('please enter a site url');
    } else if (!site_url.startsWith('https://') && !site_url.startsWith('http://')){
      setIsLoading(false);
      toast.error('site must begin with http or https');
    }else{
      axios.post(`${url}`, data)
        .then((res) => {
          if (res.data) {
            axios.get(`${sites_url}`)
              .then((r) => {
                store.dispatch(siteDataSlice.actions.setSiteData(r.data));
                setIsLoading(false);
                setIsModalOpen(false);
                setSiteUrl('');
                toast.success('Site added!');
              }).catch((e) => {
                if (e.response) {
                  toast.error(e.response.data.reason)
                  setIsLoading(false);
                }
              })
          }
        }).catch((e) => {
          if (e.response) {
            toast.error(e.response.data.reason)
            setIsLoading(false);
          }
        })
    }

    
  }



  return (
    <>

      <div>
        <Button onClick={openModal}>
          Add Site
          <span className="ml-2" aria-hidden="true">
            +
          </span>
        </Button>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Add a Site'}>
        <ModalBody>
          <div>
            <Input 
              onKeyDown={handleKeyDown}
              className="rounded-md p-2 mt-1" 
              placeholder='https://example.com'
              value={site_url}
              onChange={e => setSiteUrl(e.target.value)}
              autoFocus={true}
            />
          </div>
          
          <div>
          <div className='mt-8'>
            <span
              className='text-sm underline text-blue-600 cursor-pointer w-auto'
              onClick={() => setShowAdvanced(!showAdvanced)}
            >
              {showAdvanced ? 'Hide' : 'Show'} Advanced
            </span>
          </div>
            {showAdvanced &&
              <div className='mt-4'>
                <Label className="text-sm font-semibold">Add Pages</Label>
                <Textarea className="rounded-md p-2 mt-1" rows="3" placeholder='https://example.com/blog&#10;https://example.com/contact' 
                  name="page_urls"
                  onChange={e => setPageUrls(e.target.value)}/>
              </div>
            }
          </div>
          
        </ModalBody>
        <ModalFooter>

          <div className="hidden sm:block">
            <Button layout="outline" onClick={handleAddSite} type="submit" disabled={isLoading}>
            <Spinner display={isLoading}/>
              Submit
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={handleAddSite} type="submit" disabled={isLoading}>
            <Spinner display={isLoading}/>
              Submit
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default SiteModal


