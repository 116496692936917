import React, {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import PageTitle from '../components/Typography/PageTitle';
import ScansTable from '../components/Tables/ScansTable';
import TestsTable from '../components/Tables/TestsTable';
import SchedulesTable from '../components/Tables/SchedulesTable';
import BackButton from '../components/Buttons/BackButton';
import PageScoresChart from '../components/Charts/PageScoresChart';
import LineChart from '../components/Charts/LineChart';
import PageActionsCard from '../components/Cards/PageActionsCard';
import DeletePageModal from '../components/Modals/DeletePageModal';
import axios from "axios";
import Loader from '../components/Buttons/Loader';
import IDCard from '../components/Cards/IDCard';





function PageDetail(props) {

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const { id } = useParams();
  const getPageUrl = `${BACKEND_URL}/v1/ops/page/${id}`;
  const getTestsUrl = `${BACKEND_URL}/v1/ops/test?lean=true&page_id=${id}`;
  const getScansUrl = `${BACKEND_URL}/v1/ops/scan?lean=true&page_id=${id}`;
  const getSchedulesUrl = `${BACKEND_URL}/v1/ops/schedule?resource_id=${id}&scope=page`;
  const [page, setPage] = useState(null);
  const [scans, setScans] = useState(null);
  const [tests, setTests] = useState(null);
  const [schedules, setSchedules] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      // get site info
      await axios.get(getPageUrl)
      .then((r) => {setPage(r.data)})
      .catch((err) => {
        // window.location.href = '/404';
        console.log(err)
      });
    }
    loadData();
    
  },[getPageUrl])

  async function getPage() {
    await axios.get(getPageUrl).then((r) => setPage(r.data));
  }
  async function getTests() {
    await axios.get(getTestsUrl).then((r) => setTests({...r.data}));
  }
  async function getScans() {
    await axios.get(getScansUrl).then((r) => setScans({...r.data}));
  }
  async function getSchedules() {
    await axios.get(getSchedulesUrl).then((r) => setSchedules({...r.data}));
  }


  const urlLength = () => {
    var text_size = 'text-2xl'
    var len = page.page_url.length
    if (len >= 50 ){
      var text_size = 'text-xl'
    }else if (len > 36 && len < 50){
      var text_size = 'text-xl'
    }else if (len < 50){
      var text_size = 'text-2xl'
    }
    return text_size
  }



  if (page === null) {
    return (
      <Loader item={'Page Data'}/>
    )
  }


  return (
    <>
      <div className='flex justify-between mb-2'>
        <PageTitle>
          <div className="flex">
            <div>
              <div className='flex justify-start'>
                <div>
                  <span className='text-center px-2 py-1 rounded-full text-[11px] bg-gray-200 dark:bg-gray-600 font-semibold dark:text-white text-gray-700'>
                    PAGE
                  </span>
                </div>
                <h1 className='font-semibold text-gray-400 dark:text-gray-500 text-[.9rem] ml-3 mt-1'>
                  {new Date(page.time_created).toLocaleDateString()}
                </h1>
              </div>
              
              <a href={page.page_url} target='_blank'>
                <h1 
                  className={`hover:underline cursor-pointer max-w-lg ${urlLength()}`}
                >
                  {page ? page.page_url : ''}
                </h1>
              </a>
              
              <div className='mt-1'>
                <IDCard resource={'page'} id={page.id}/>
              </div>

              <div className='md:hidden block'>
                <DeletePageModal page={page} type='icon'/>
              </div>

            </div>
          </div>
        </PageTitle>

        <div className='mt-8 md:block hidden'>
          <DeletePageModal page={page} type='button'/>
        </div>

      </div>

      <BackButton link={`/site/${page.site}`} text='back to site'/>
      
      <div className='grid gap-6 mb-8 md:grid-cols-2 mt-4' >
          <div>
            <PageActionsCard 
              page={page} 
              getScans={getScans} 
              getSchedules={getSchedules}
              getTests={getTests}
            />
          </div>
          
            <SchedulesTable 
              object={page}
              scope={'page'}
              schedules={schedules}
              getSchedules={getSchedules}
              url={getSchedulesUrl}
            />
          
          <div>
            <PageScoresChart 
              health_score={page.info.latest_scan.score} 
              test_score={page.info.latest_test.score}
            />
          </div>
          <div><LineChart page={page}/></div>
      </div>
      <div className="grid sm:hidden">
        <ScansTable page={page}/>
        <TestsTable page={page}/>
      </div>
      <div className="sm:block hidden">
        <div className='grid gap-6 mb-8 md:grid-cols-2'>
          <div><ScansTable page={page}/></div>
          <div><TestsTable page={page}/></div>
        </div>
      </div>
      
      
    

    </>
  )
}

export default PageDetail
