import React, {useState, useEffect} from 'react';
import { 
    LifeRingIcon, FileIcon, GridIcon, 
    SignalIcon, TransCubeIcon, HandIcon
} from '../../icons'
import InfoCard from './InfoCard'
import RoundIcon from '../RoundIcon'
import AuditsSlideModalLH from '../Modals/AuditsSlideModalLH';


function LighthouseDeltasCard(props) {

    const [audits, setAudits] = useState()

    const getAudits = async () => {
        if (props.delta){
          if (typeof props.delta.audits === 'string'){
            await fetch(props.delta.audits)
              .then(r => r.json())
              .then(out => setAudits(out))
              .catch((e) => {console.log(e)})
          }else if (typeof props.delta.audits === 'object'){
            setAudits(props.delta.audits)
          }
        }
    }

    useEffect(() => {
        getAudits();
    }, [props?.delta])
    


    return (
        <>
            <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-5 grid-cols-2">

                <AuditsSlideModalLH type='wrapper' cat='SEO (changes)' audits={audits ? audits.seo : null}>
                <InfoCard title="SEO" value={props.delta ? (props.delta.scores.seo_delta > 0 ? '+'+props.delta.scores.seo_delta : props.delta.scores.seo_delta) : null}>
                    <RoundIcon
                    icon={FileIcon}
                    iconColorClass="text-green-500 dark:text-green-100"
                    bgColorClass="bg-green-100 dark:bg-green-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalLH>

                <AuditsSlideModalLH type='wrapper' cat='Accessibility (changes)' audits={audits ? audits.accessibility : null}>
                <InfoCard title="Accessibility" value={props.delta ? (props.delta.scores.accessibility_delta  > 0 ? '+'+props.delta.scores.accessibility_delta : props.delta.scores.accessibility_delta) : null}>
                    <RoundIcon
                    icon={GridIcon}
                    iconColorClass="text-blue-500 dark:text-blue-100"
                    bgColorClass="bg-blue-100 dark:bg-blue-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalLH>

                <AuditsSlideModalLH type='wrapper' cat='Performance (changes)' audits={audits ? audits.performance : null}>
                <InfoCard title="Performance" value={props.delta ? (props.delta.scores.performance_delta > 0 ? '+'+props.delta.scores.performance_delta  : props.delta.scores.performance_delta) : null}>
                    <RoundIcon
                    icon={SignalIcon}
                    iconColorClass="text-teal-500 dark:text-teal-100"
                    bgColorClass="bg-teal-100 dark:bg-teal-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalLH>

                <AuditsSlideModalLH type='wrapper' cat='Best Practices (changes)' audits={audits ? audits.best_practices : null}>
                <InfoCard title="Best Practices" value={props.delta ? (props.delta.scores.best_practices_delta > 0 ? '+'+props.delta.scores.best_practices_delta : props.delta.scores.best_practices_delta) : null}>
                    <RoundIcon
                    icon={LifeRingIcon}
                    iconColorClass="text-orange-500 dark:text-orange-100"
                    bgColorClass="bg-orange-100 dark:bg-orange-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalLH>

                {/* <AuditsSlideModalLH type='wrapper' cat='PWA (changes)' audits={audits ? audits.pwa : null}>
                <InfoCard title="PWA" value={props.delta ? (props.delta.scores.pwa_delta > 0 ? '+'+props.delta.scores.pwa_delta : props.delta.scores.pwa_delta) : null}>
                    <RoundIcon
                    icon={TransCubeIcon}
                    iconColorClass="text-purple-500 dark:text-purple-100"
                    bgColorClass="bg-purple-100 dark:bg-purple-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalLH> */}

                <AuditsSlideModalLH type='wrapper' cat='CRUX (changes)' audits={audits ? audits.crux : null}>
                <InfoCard title="CRUX" value={props.delta ? (props.delta.scores.crux_delta > 0 ? '+'+props.delta.scores.crux_delta : props.delta.scores.crux_delta) : null}>
                    <RoundIcon
                    icon={HandIcon}
                    iconColorClass="text-pink-500 dark:text-pink-100"
                    bgColorClass="bg-pink-100 dark:bg-pink-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalLH>
            </div>
        
        </>
    )
}



export default LighthouseDeltasCard