import React from 'react';
import { CogIcon } from '@heroicons/react/outline'



function Gear(props) {


    return(
        <div className=''>
            <CogIcon className={` ${props.color ? props.color : 'dark:text-gray-400 text-gray-600'} ${props.size ? props.size : 'h-6 w-6'} ${props.margins ? props.margins : ''} animate-spin`}/>
        </div>
    )
}


export default Gear