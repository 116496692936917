import React, {useEffect, useState} from 'react';
import './ImageCompareCard.style.css';
import { Card, CardBody } from '@windmill/react-ui';
import ImageContainer from '../ImageContainer';
import Split from 'react-split'
import axios from 'axios';
import Toggle from '../../Toggle/Toggle';
import Loader from '../../Buttons/Loader';


function ImageCard(props) {

    const images = props.test.images_delta ? props.test.images_delta.images : null
    const [height, setHeight] = useState(null)
    const [width, setWidth] = useState(null)
    const [configs, setConfigs] = useState(null)
    const [wRaw, setWRaw] = useState(null)
    const [hwRatio, setHwRatio] = useState(null)
    const [viewType, setViewType] = useState('img')

    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const getScanUrl = `${BACKEND_URL}/v1/ops/scan/${props.test.pre_scan}`;


    const getImageSize = (url) => {   
        const img = new Image();
        img.src = url;
        img.onload = () => {
            console.log(`width ${img.width} height ${height}`)
            let screenSize = window.innerWidth;
            // var widthRaw = img.width * .565 // at 1440px width
            // var heightRaw = img.height * .565 //
            var widthRaw = img.width * 1.1 // at 720px width
            var heightRaw = img.height * 1.1 //
            setWRaw(widthRaw)

            var hw_ratio = heightRaw / widthRaw
            setHwRatio(hw_ratio)

            if (widthRaw >= 1084 && screenSize < 1440){
                widthRaw = screenSize * .753333
                heightRaw = heightRaw * hw_ratio
            }

            setWidth(widthRaw)
            setHeight(heightRaw)

        };
    }

    

    useEffect(() => {
        const loadData = async () => {
          // get scan info
          await axios.get(getScanUrl)
          .then((r) => {
                if (images){
                    getImageSize(images[0].pre_img.url)
                }
                setConfigs(r.data.configs)
            })
          .catch((err) => {
            console.log(err)
          });
        }
        loadData();
        // eslint-disable-next-line
      },[getScanUrl])


      const changeView = () => {
        if (viewType === 'img'){
            setViewType('diff')
        }else{
            setViewType('img')
        }
      }


    const resizeImages = () => {
        let screenSize = window.innerWidth;
        console.log('resizing...')
        console.log(screenSize)
        console.log(wRaw)

        if (wRaw >= 1084 && screenSize < 1440){
            
            var new_width = screenSize * .753333
            var new_height = height * hwRatio
            
            setHeight(new_height)
            setWidth(new_width)
        }
    };


    useEffect(() => {
        window.addEventListener('resize', resizeImages);
        return () => {
            window.addEventListener('resize', resizeImages);
        };
    }, []);


    if (!images || !props.test.images_delta.average_score){
        return (
            <Card className="mt-4 mb-10">
                    <CardBody className="">
                    <div className='text-center'>
                        <p className='text-red-600 dark:text-red-300 font-semibold text-lg'>Images could not be compared</p>
                    </div>
                </CardBody>
            </Card>
        )
        
    } else if ((configs === null || wRaw === null || hwRatio === null)){
        return <Loader item={'Images'}/>;
    }


    return (
        <>
            <Card className="mt-4 mb-10">
                <CardBody className="">
                    <div className='flex justify-between'>
                        <p className="mb-4 font-semibold text-gray-600 dark:text-gray-300">{props.card_title}</p>
                        
                        <div className='mb-4 flex justify-end'>
                            <div><span className='text-sm font-semibold text-gray-600 dark:text-gray-300'>Show Diffs</span></div>
                            <div className='flex justify-center ml-2'>
                                <div><Toggle onChange={changeView} id='change-view' small checked={viewType === 'diff'}/></div>
                                
                            </div>
                        </div>
                            
                    </div>
                    <div style={{ maxHeight: '37rem', maxWidth: `${width+30}px`,}} className="p-4 overflow-auto m-auto">
                        <Split className='split' minSize={0}>
                                <ImageContainer 
                                    scan_number={1} 
                                    images={images} 
                                    view_type={viewType}
                                    scan_id={props.test.pre_scan} 
                                    width={width} 
                                    height={height}
                                    test={props.test}
                                    configs={configs}
                                />
                                <ImageContainer 
                                    scan_number={2} 
                                    images={images} 
                                    view_type={viewType}
                                    scan_id={props.test.post_scan}
                                    width={width} 
                                    height={height}
                                    test={props.test}
                                    configs={configs}
                                />
                        </Split>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default ImageCard