import React, {useState} from 'react';
import ApiRequestCard from '../components/Cards/ApiRequestCard';
import ApiResponseCard from '../components/Cards/ApiResponseCard';
import PageTitle from '../components/Typography/PageTitle';


function InternalApi(props) {

  const [response, setResponse] = useState(props.response ? props.response : null)

  function apiResponse(r){
    setResponse({...r});
  }

  return (
    <>
      <PageTitle>Internal API</PageTitle>

      <div className="grid gap-6 md:grid-cols-2 grid-cols-1">
            
            <div>
              <ApiRequestCard apiResponse={apiResponse}/>
            </div>

            <div>
              <ApiResponseCard response={response}/>
            </div>

        </div>
    </>
  )
}

export default InternalApi
