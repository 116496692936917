

function GetToken() {

    var token = null;

    // retrieve token without using ./store
    try{
        let payload = JSON.parse(localStorage.getItem('persist:root'))
        let auth = JSON.parse(payload.auth)
        token = auth.token
    } catch {}
   
    return `Bearer ${token}`
}

export default GetToken;