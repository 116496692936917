import React, {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router';
import PageTitle from '../components/Typography/PageTitle';
import ScoresCard from '../components/Cards/ScoresCard';
import ErrorsCard from '../components/Cards/ErrorsCard'
import PageCard from '../components/Cards/PageCard';
import BackButton from '../components/Buttons/BackButton';
import ConfigCard from '../components/Cards/ConfigCard';
import ScanTypesCard from '../components/Cards/ScanTypesCard';
import DeleteScanModal from '../components/Modals/DeleteScanModal';
import Gear from '../components/Buttons/Gear';
import Loader from '../components/Buttons/Loader';
import axios from "axios";
import IDCard from '../components/Cards/IDCard';




function SiteDetail(props) {

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const { id } = useParams();
  const getScanUrl = `${BACKEND_URL}/v1/ops/scan/${id}`;
  const getPageUrl = `${BACKEND_URL}/v1/ops/page/`;
  const [scan, setScan] = useState(null);
  const [page, setPage] = useState(null);

  const navigate = useNavigate();


  useEffect(() => {
    const loadData = async () => {
      // get scan info
      await axios.get(getScanUrl)
      .then(async (r) => {
        setScan(r.data)
        await axios.get(`${getPageUrl}${r.data.page}`)
          .then((r) => {
            setPage(r.data)
          }).catch((err) => {
            console.log(err)
          });
      })
      .catch((err) => {
        console.log(err)
        window.location.href = '/404';
      });
      
    }
    loadData();
    
  },[getScanUrl, getPageUrl])

  

  async function getScan() {
    await axios.get(getScanUrl).then((r) => {setScan(r.data);})
  }


  useEffect(() => {
    const interval = setInterval(() => {
      getScan();
    }, 20000);
    return () => {
      clearInterval(interval);
    };
     // eslint-disable-next-line
  }, []);


  const getScanStatus = () => {
    if (scan.time_completed === null){
      return (<span className='text-gray-700 dark:text-white bg-gray-200 dark:bg-gray-700 inline-flex px-2 text-xs font-medium leading-5 rounded-full'>Working</span>)
    } 
  }

  const urlLength = () => {
    var text_size = 'text-2xl'
    var len = page.page_url.length
    if (len >= 50 ){
      var text_size = 'text-xl'
    }else if (len > 36 && len < 50){
      var text_size = 'text-xl'
    }else if (len < 50){
      var text_size = 'text-2xl'
    }
    return text_size
  }

  function navToSite(url) {
    window.open(
      url, "_blank");
  }



  if (scan === null || page === null) {
    return <Loader item={'Scan Data'}/>
  }


  return (
    <>

      <div className='flex justify-between'>
        <PageTitle>
          <div className="flex">
            <div>
              <div className='flex justify-start'>
                <div>
                  <span className='text-center px-2 py-1 rounded-full text-[11px] bg-yellow-200 dark:bg-yellow-600 font-semibold dark:text-white text-yellow-700'>
                    SCAN
                  </span>
                </div>
                <h1 className='font-semibold text-gray-400 dark:text-gray-500 text-[.9rem] ml-3 mt-1'>
                  {new Date(scan.time_created).toLocaleDateString()}
                </h1>
              </div>
             
              <div onClick={() => navigate(`/page/${page.id}`)}>
                <p className={`hover:underline cursor-pointer max-w-lg ${urlLength()}`}>{page.page_url}</p>
              </div>

              <div className='mt-1'>
                <IDCard resource={'scan'} id={scan.id}/>
              </div>

              <div className='flex justify-start mt-2'>
                <div className='-mt-[.3rem] flex justify-between'>
                  <div className=''>{getScanStatus()}</div>
                  {!scan.time_completed && <div className='mt-2 ml-1'><Gear/></div>}
                  <div className='md:hidden block'>
                    <DeleteScanModal type='icon' scan={scan} callBack={() => {navigate(`/page/${scan.page}`)}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageTitle>
        <div className='mt-8 md:block hidden'>
          <DeleteScanModal type='button' scan={scan} callBack={() => {navigate(`/page/${scan.page}`)}}/>
        </div>
      </div>
      
      <BackButton />

      <div className='mt-4'>
        <ScoresCard scan={scan} card_title="Scores"/>
      </div>
      
      <div className='grid gap-6 mb-8 md:grid-cols-5 grid-cols-1'>
        <div className="md:col-start-1 md:col-end-3">
          <ScanTypesCard types={scan.type} card_title="Scan Types"/>
          <ConfigCard configs={scan.configs} card_title='Scan Configurations' />
          <ErrorsCard errors={scan.logs} card_title='Error Logs'/>
        </div>
        <div className="md:col-start-3 md:col-end-7">
          <PageCard scan={scan} card_title='Page Source'/>
        </div>
      </div>
    </>
  )
}

export default SiteDetail
