import { useEffect } from 'react'
import billingSlice from '../store/slices/billing';
import store from '../store';
import Loader from '../components/Buttons/Loader';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import toast from 'react-hot-toast';



const BillingUpdate = () => {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const navigate = useNavigate()
  
  // get session_id
  const session_id = urlParams.get('session_id')

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const info_url = `${BACKEND_URL}/v1/billing/info`
  const sub_update = `${BACKEND_URL}/v1/billing/subscription/update?${session_id && 'session_id='+session_id}`

  

  // sending session_id to server
  const sendUpdateRequest = async () => {
    await axios.get(sub_update)
      .then(() => {console.log(r.data)})
      .catch((e) => console.log(e))
  }


  // get new billing info
  const getBillingInfo = async () => {
    await axios.get(info_url)
      .then((r) => {
        store.dispatch(
          billingSlice.actions.setBilling(
            r.data
          )
        )
      })
      .catch((e) => {
        console.log(e)
      })
  }


  // run update sequence
  const update = async () => {
    await sendUpdateRequest();
    await getBillingInfo()
      .then(() => {
        navigate('/billing')
      })
      .catch((e) => {
        console.log(e)
        toast.error('contact support')
      })
  }


  // send stripe urlParams to server
  useEffect(() => {
    update();
  },[urlParams])


  return (
    <Loader item={'Billing Info'}/>
  )
    
}
    
export default BillingUpdate;