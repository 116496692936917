import React, {useState, useEffect} from 'react';
import { Card, CardBody, Button } from '@windmill/react-ui';
import { DownloadIcon } from '../../icons';


function IFrameReportCard(props) {

    const [report, setReport] = useState(props.report ? props.report : null)
    const [iframe, setIFrame] = useState(1)
    const [display, setDisplay] = useState(false)
    

    useEffect(() => {
        setDisplay(false)
        setReport(null)
        setReport(props?.report)
        setDisplay(true)
        // eslint-disable-next-line
    }, [props?.report])

    useEffect(() => {
        setDisplay(false)
        setIFrame(props?.iframe)
        setDisplay(true)
        // eslint-disable-next-line
    }, [props?.iframe])


    const download = async () => {
        let url = report.path
        url = url.slice(0, -10)
        fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/pdf',
            },
          }).then(r => r.blob())
            .then((blob) => {
              // Create blob link to download
              const url = window.URL.createObjectURL(
                  new Blob([blob]),
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                  'download',
                  `my_report.pdf`,
              );
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);

          }).catch((e) => console.log(e))
    }


    return (
        <>
        <Card className="mb-10">
            <CardBody className="">
                {report ? 
                    <div>
                        <div className="flex justify-arround">
                            <div className="m-4">
                                    <Button
                                        size="small"
                                        iconRight={DownloadIcon}
                                        onClick={() => download()}
                                        layout='outline'
                                    >
                                        Download
                                    </Button>
                            </div>
                        </div>
                        
                        <div style={{ maxHeight: '28rem' }} className="p-4 text-xs whitespace-pre-wrap text-gray-600 dark:text-gray-100">
                            {/* <meta http-equiv="Pragma" content='no-cache'></meta>
                            <meta http-equiv="Expires" content="-1"></meta>
                            <meta http-equiv="CACHE-CONTROL" content="NO-CACHE"></meta> */}
                            {display && <iframe title="report" key={iframe} src={report.path} width="100%" height="430px"></iframe>}
                        </div>
                        
                    </div>
                : 
                    <div className="text-center">
                        <h1 
                            className='text-lg font-semibold text-gray-600 dark:text-gray-100'
                        >
                            No report yet, try to make one!
                        </h1>
                    </div>
                }
            </CardBody>
        </Card>
        
        </>
    )
}

export default IFrameReportCard