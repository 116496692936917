import React from 'react'
import { Card, CardBody, Badge } from '@windmill/react-ui'



function ScanTypesCard(props) {



    return(
    
        <>
            <Card className="mt-4">
                <CardBody className="">
                    <p className="mb-2 font-semibold text-gray-600 dark:text-gray-300">{props.card_title}</p>
                    <div style={{ maxHeight: '24rem' }} className="p-4">
                        <div className="flex flex-wrap justify-start">
                            { props.types &&

                                props.types.map((type, i) => (

                                    <div className="mt-1 mr-4" key={i}><Badge  type="success">{type}</Badge></div>
                                ))

                            }
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    
    
    )
}

export default ScanTypesCard