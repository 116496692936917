import React, {useState, useEffect} from 'react';
import { 
    TerminalIcon, NewsPaperIcon, 
    BeakerIcon, TemplateIcon, PuzzleIcon,
    ColorSwatchIcon, CodeIcon, CloudDownloadIcon
} from '../../icons'
import InfoCard from './InfoCard'
import RoundIcon from '../RoundIcon'
import AuditsSlideModalYL from '../Modals/AuditsSlideModalYL';

function YellowlabDeltasCad(props) {

    const [audits, setAudits] = useState()

    const getAudits = async () => {
        if (props.delta){
          if (typeof props.delta.audits === 'string'){
            await fetch(props.delta.audits)
              .then(r => r.json())
              .then(out => setAudits(out))
              .catch((e) => {console.log(e)})
          }else if (typeof props.delta.audits === 'object'){
            setAudits(props.delta.audits)
          }
        }
    }

    useEffect(() => {
        getAudits();
    }, [props?.delta])


    return (
        <>
            <div className="grid gap-4 xl:grid-cols-5 md:grid-cols-3 lg:grid-cols-5 grid-cols-2 justify-around">

                <AuditsSlideModalYL type='wrapper' cat='Bad CSS (changes)' audits={audits ? audits.badCSS : null}>
                <InfoCard title="Bad CSS" value={props.delta ? (props.delta.scores.badCSS_delta > 0 ? '+'+props.delta.scores.badCSS_delta : props.delta.scores.badCSS_delta) : null}>
                    <RoundIcon
                    icon={ColorSwatchIcon}
                    iconColorClass="text-green-500 dark:text-green-100"
                    bgColorClass="bg-green-100 dark:bg-green-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalYL>

                <AuditsSlideModalYL type='wrapper' cat='CSS Complexity (changes)' audits={audits ? audits.cssComplexity : null}>
                <InfoCard title="CSS Complexity" value={props.delta? (props.delta.scores.cssComplexity_delta > 0 ? '+'+props.delta.scores.cssComplexity_delta : props.delta.scores.cssComplexity_delta) : null}>
                    <RoundIcon
                    icon={ColorSwatchIcon}
                    iconColorClass="text-teal-500 dark:text-teal-100"
                    bgColorClass="bg-teal-100 dark:bg-teal-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalYL>

                <AuditsSlideModalYL type='wrapper' cat='Bad JS(changes)' audits={audits ? audits.badJavascript : null}>
                <InfoCard title="Bad JS" value={props.delta ? (props.delta.scores.badJavascript_delta > 0 ? '+'+props.delta.scores.badJavascript_delta : props.delta.scores.badJavascript_delta) : null}>
                    <RoundIcon
                    icon={CodeIcon}
                    iconColorClass="text-red-500 dark:text-red-100"
                    bgColorClass="bg-red-100 dark:bg-red-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalYL>

                <AuditsSlideModalYL type='wrapper' cat='jQuery (changes)' audits={audits ? audits.jQuery : null}>
                <InfoCard title="jQuery" value={props.delta ? (props.delta.scores.jQuery_delta > 0 ? '+'+ props.delta.scores.jQuery_delta: props.delta.scores.jQuery_delta) : null}>
                    <RoundIcon
                    icon={PuzzleIcon}
                    iconColorClass="text-pink-500 dark:text-pink-100"
                    bgColorClass="bg-pink-100 dark:bg-pink-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalYL>

                <AuditsSlideModalYL type='wrapper' cat='JS Complexity (changes)' audits={audits ? audits.javascriptComplexity : null}>
                <InfoCard title="JS Complexity" value={props.delta ? (props.delta.scores.javascriptComplexity_delta > 0 ? '+'+props.delta.scores.javascriptComplexity_delta : props.delta.scores.javascriptComplexity_delta) : null}>
                    <RoundIcon
                    icon={CodeIcon}
                    iconColorClass="text-orange-500 dark:text-orange-100"
                    bgColorClass="bg-orange-100 dark:bg-orange-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalYL>

                <AuditsSlideModalYL type='wrapper' cat='DOM Complexity (changes)' audits={audits ? audits.domComplexity : null}>
                <InfoCard title="DOM Complexity)" value={props.delta ? (props.delta.scores.domComplexity_delta > 0 ? '+'+props.delta.scores.domComplexity_delta : props.delta.scores.domComplexity_delta) : null}>
                    <RoundIcon
                    icon={TemplateIcon}
                    iconColorClass="text-purple-500 dark:text-purple-100"
                    bgColorClass="bg-purple-100 dark:bg-purple-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalYL>

                <AuditsSlideModalYL type='wrapper' cat='Fonts (changes)' audits={audits ? audits.fonts : null}>
                <InfoCard title="Fonts" value={props.delta ? (props.delta.scores.fonts_delta > 0 ? '+'+props.delta.scores.fonts_delta : props.delta.scores.fonts_delta) : null}>
                    <RoundIcon
                    icon={NewsPaperIcon}
                    iconColorClass="text-yellow-500 dark:text-yellow-100"
                    bgColorClass="bg-yellow-100 dark:bg-yellow-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalYL>

                <AuditsSlideModalYL type='wrapper' cat='Page Weight (changes)' audits={audits ? audits.pageWeight : null}>
                <InfoCard title="Page Weight" value={props.delta ? (props.delta.scores.pageWeight_delta > 0 ? '+'+props.delta.scores.pageWeight_delta: props.delta.scores.pageWeight_delta) : null}>
                    <RoundIcon
                    icon={BeakerIcon}
                    iconColorClass="text-blue-500 dark:text-blue-100"
                    bgColorClass="bg-blue-100 dark:bg-blue-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalYL>

                <AuditsSlideModalYL type='wrapper' cat='Images' audits={audits ? audits.images : null}>
                <InfoCard title="Images" value={props.delta ? (props.delta.scores.images_delta > 0 ? '+'+props.delta.scores.images_delta : props.delta.scores.images_delta) : null}>
                    <RoundIcon
                    icon={CloudDownloadIcon}
                    iconColorClass="text-orange-500 dark:text-orange-100"
                    bgColorClass="bg-orange-100 dark:bg-orange-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalYL>

                <AuditsSlideModalYL type='wrapper' cat='Server Configs' audits={audits ? audits.serverConfig : null}>
                <InfoCard title="Server Configs" value={props.delta ? (props.delta.scores.serverConfig_delta > 0 ? '+'+props.delta.scores.serverConfig_delta : props.delta.scores.serverConfig_delta) : null}>
                    <RoundIcon
                    icon={TerminalIcon}
                    iconColorClass="text-green-500 dark:text-green-100"
                    bgColorClass="bg-green-100 dark:bg-green-500"
                    className="mr-4"
                    />
                </InfoCard>
                </AuditsSlideModalYL>
            </div>
        
        </>
    )
}



export default YellowlabDeltasCad