import React, {useEffect, useState} from 'react';
import { Copy, Activity, ClockIcon, BeakerIcon } from '../../icons'
import { DocumentIcon } from '@heroicons/react/outline';
import InfoCard from './InfoCard'
import RoundIcon from '../RoundIcon'
import Loader from '../Buttons/Loader';



function SiteStatsCard(props) {

    const [data, setData] = useState(props.data)


    useEffect(() => {
        setData(props?.data)
        // eslint-disable-next-line
    },[props?.data])

    if(data === null){
        return <Loader item={'Stats'}/>
    }


    return (
        <>

            <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-5 grid-cols-2">

                <InfoCard title="Pages" value={data.pages}  progress={data.pages_usage}>
                    <RoundIcon
                    icon={DocumentIcon}
                    iconColorClass="text-blue-500 dark:text-blue-100"
                    bgColorClass="bg-blue-100 dark:bg-blue-500"
                    className="mr-4"
                    />
                </InfoCard>

                <InfoCard title="Scans" value={data.scans} progress={data.scans_usage}>
                    <RoundIcon
                    icon={Activity}
                    // iconColorClass="text-yellow-700 dark:text-yellow-100"
                    // bgColorClass="bg-yellow-200 dark:bg-yellow-600"
                    iconColorClass="text-blue-500 dark:text-blue-100"
                    bgColorClass="bg-blue-100 dark:bg-blue-500"
                    className="mr-4"
                    />
                </InfoCard>

                <InfoCard title="Tests" value={data.tests} progress={data.tests_usage}>
                    <RoundIcon
                    icon={Copy}
                    iconColorClass="text-blue-500 dark:text-blue-100"
                    bgColorClass="bg-blue-100 dark:bg-blue-500"
                    className="mr-4"
                    />
                </InfoCard>

                <InfoCard title="Testcases" value={data.testcases} progress={data.testcases_usage}>
                    <RoundIcon
                    icon={BeakerIcon}
                    // iconColorClass="text-green-500 dark:text-green-100"
                    // bgColorClass="bg-green-100 dark:bg-green-500"
                    iconColorClass="text-blue-500 dark:text-blue-100"
                    bgColorClass="bg-blue-100 dark:bg-blue-500"
                    className="mr-4"
                    />
                </InfoCard>

                <InfoCard title="Schedules" value={data.schedules} progress={data.schedules_usage}>
                    <RoundIcon
                    icon={ClockIcon}
                    // iconColorClass="text-orange-500 dark:text-orange-100"
                    // bgColorClass="bg-orange-100 dark:bg-orange-500"
                    iconColorClass="text-blue-500 dark:text-blue-100"
                    bgColorClass="bg-blue-100 dark:bg-blue-500"
                    className="mr-4"
                    />
                </InfoCard>
            </div>
        
        </>
    )
}



export default SiteStatsCard