import React, {useState, useEffect} from 'react'
import PageTitle from '../components/Typography/PageTitle'
import MigrateCard from '../components/Cards/MigrateCard'
// import IFrameMigrationCard from '../components/Cards/IFrameMigrationCard'
import BackButton from '../components/Buttons/BackButton'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast';
import axios from 'axios'




function Migration() {
    
    const { id } = useParams();
    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const processUrl = `${BACKEND_URL}/v1/ops/process`;

    const [proc, setProcess] = useState(null);

    const loadData = async () => {
        // get report info
        await axios.get(`${processUrl}?site_id=${id}`)
        .then((r) => {
            setProcess(r.data.results[0])
            var thisProc = r.data.results[0]
            if (proc){
                if (!proc.successful && thisProc.successful){
                    toast.success('Migration Complete!')
                }
            }
        })
        .catch((err) => {
            if (!proc){
                console.log(err)
            }
        });
      }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line 
      },[])


    useEffect(() => {
        const interval = setInterval(() => {
            loadData();
        }, 2000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line
      }, []);



    // if (!proc) {return null;}



    return (
        <>
        <PageTitle>Site Migration</PageTitle>
        <BackButton site_id={id}/>
        <div className="grid gap-6 md:grid-cols-2 grid-cols-1">
            
            <div>
                <MigrateCard site={id} process={proc} callBack={loadData}/>
            </div>
            
            <div>
                {/* <IFrameMigrationCard process={proc}/> */}
            </div>

        </div>
        </>
    )
}

export default Migration