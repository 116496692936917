
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BillingResponse } from "../../types";

type State = {
  billing: BillingResponse | null;
};

const initialState: State = { billing: null };

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setBilling(state: State, action: PayloadAction<BillingResponse>) {
      state.billing = action.payload;
    },
    setLogout(state: State) {
      state.billing = null;
    },
    
  },
});

export default billingSlice;

  