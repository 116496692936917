import React, {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import PageTitle from '../components/Typography/PageTitle';
import TestcasesTable from '../components/Tables/TestcasesTable';
import SchedulesTable from '../components/Tables/SchedulesTable';
import PageTable from '../components/Tables/PageTable';
import SiteStatsCard from '../components/Cards/SiteStatsCard';
import axios from "axios";
import SiteActionsButton from '../components/Buttons/SiteActionsButton';
import Loader from '../components/Buttons/Loader';
import IDCard from '../components/Cards/IDCard';
import BackButton from '../components/Buttons/BackButton';






function SiteDetail(props) {

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const { id } = useParams();
  const [data, setData] = useState(null)
  const getStatsUrl = `${BACKEND_URL}/v1/ops/metrics/site?site_id=${id}`;
  const getSiteUrl = `${BACKEND_URL}/v1/ops/site/${id}`;
  const getPagesUrl = `${BACKEND_URL}/v1/ops/page?site_id=${id}`;
  const getSchedulesUrl = `${BACKEND_URL}/v1/ops/schedule?resource_id=${id}&scope=site`;
  const getTestcasesUrl = `${BACKEND_URL}/v1/ops/testcase?site_id=${id}`;
  const [site, setSite] = useState(null);
  const [pages, setPages] = useState(null);
  const [schedules, setSchedules] = useState(null);
  const [testcases, setTestcases] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      // get site info
      await axios.get(getSiteUrl)
      .then((r) => {setSite(r.data)})
      .catch((err) => {
        window.location.href = '/404';
        console.log(err)
      });
    }
    loadData();
  },[getSiteUrl])

  useEffect(() => {
    const getData = () => {
      // get site stats
      axios.get(getStatsUrl)
      .then((r) => {setData(r.data)})
      .catch(err => console.log(err))
    }
    getData();
  }, [getStatsUrl])


  async function getSite() {
    await axios.get(getSiteUrl)
      .then((r) => setSite(r.data))
      .catch(e => console.log(e))
  }
  async function getPages() {
    await axios.get(getPagesUrl)
      .then((r) => setPages(r.data))
      .catch(e => console.log(e))
  }
  async function getSchedules() {
    await axios.get(getSchedulesUrl)
      .then((r) => setSchedules({...r.data}))
      .catch(e => console.log(e))
  }
  async function getTestcases() {
    await axios.get(getTestcasesUrl)
      .then((r) => setTestcases({...r.data}))
      .catch(e => console.log(e))
  }

  const getNewData = async () =>  {
    await getSite()
    await getPages()
    await getTestcases()
    await getSchedules()
    return
  }

  function navToSite(url) {
      window.open(
        url, "_blank");
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getNewData();
    }, 40000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);


  if (site === null) {
    return (
      <Loader item={'Site Data'}/>
    )
  }


  return (
    <>
      <div className='md:flex justify-between mb-2'>
        <PageTitle>
          <div className="flex">
            <div>
              <div className='flex justify-start'>
                <div>
                  <span className='text-center px-2 py-1 rounded-full text-[11px] bg-blue-100 dark:bg-blue-600 font-semibold dark:text-white text-blue-700'>
                    SITE
                  </span>
                </div>
                <h1 className='font-semibold text-gray-400 dark:text-gray-500 text-[.9rem] ml-3 mt-1'>
                  {new Date(site.time_created).toLocaleDateString()}
                </h1>
              </div>
              
              <a href={site.site_url} target='_blank'>
                <h1 
                  className='hover:underline cursor-pointer max-w-lg'
                >
                  {site ? site.site_url : ''}
                </h1>
              </a>

              <div className='mt-1'>
                <IDCard resource={'site'} id={site.id}/>
              </div>
              
            </div>
          </div>
        </PageTitle>

        <div className='md:mt-8 md:mb-0 mt-4 mb-6'>
          <SiteActionsButton site={site} callBack={getNewData}/>
        </div>

      </div>

      <BackButton link={`/`} text='back to home'/>

      <div className='mt-4'>
        <SiteStatsCard data={data}/>
      </div>
      
      <div className='overflow-auto'>
        <PageTable site={site} pages={pages}/>
      </div>

      <div className="grid sm:hidden">
        <TestcasesTable testcases={testcases} site={site}/>
      </div>
      <div className="grid sm:hidden">
        <SchedulesTable 
          schedules={schedules} 
          object={site} 
          callBack={getSchedules} 
          scope={'site'} 
          url={getSchedulesUrl}
        />
      </div>
      <div className="sm:block hidden">
        <div className='grid gap-6 mb-8 md:grid-cols-2'>
          <div><TestcasesTable testcases={testcases} site={site}/></div>
          <div><SchedulesTable scope={'site'} schedules={schedules} object={site} callBack={getSchedules}/></div>
        </div>
      </div>
      
    </>
  )
}

export default SiteDetail
