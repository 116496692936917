import React, { useState, useEffect } from 'react'
import ChartCard from './ChartCard'
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2'
import { Badge } from '@windmill/react-ui'


function PageScoresChart(props) {

    const [healthgood, setHealthGood] = useState(null);
    const [healthbad, setHealthBad] = useState(null);
    const [testgood, setTestGood] = useState(null);
    const [testbad, setTestBad] = useState(null);
    
    const colorOne = '#0694a203'
    const colorTwo = '#1c64f2'
    const borderColor = '#9ca3af'

    useEffect(() => {
        if(props.health_score!==null && props.health_score!=='') {
            setHealthGood(props.health_score);
            setHealthBad(100 - props.health_score);
        }
        if(props.test_score!==null && props.test_score!=='') {
            setTestGood(props.test_score);
            setTestBad(100 - props.test_score);
        }
    },[props])



    const healthData = {
        data: {
          datasets: [
            {
              data: [healthgood, healthbad],
              backgroundColor: [colorTwo, colorOne],
              label: 'Health Score',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 55,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }

      const testData = {
        data: {
          datasets: [
            {
              data: [testgood, testbad],
              backgroundColor: [colorTwo, colorOne],
              label: 'Test Score',
              borderColor: borderColor,
            },
          ],
          labels: [],
        },
        options: {
          responsive: true,
          cutout: 55,
          plugins: {
            tooltip: {
              enabled: false,
              display: false,
            },
          },
        },
        legend: {
          display: false,
        },
      }


      function getScore(score) {
        var value = 'no data'
        if(score!==null && score!==''){
          var score_int = Math.round(parseInt(score))
          value = score_int+'%'
        }
        return (
            <>
              <span className="text-gray-600 dark:text-gray-300">{value}</span>
            </>
        )
      }


      function getScoreStatus(score) {
        var badge = 'neutral'
        var health = 'no data'
        if(score!==null && score!==''){
          var score_int = Math.round(parseInt(score) * 10) / 10
          if (score_int >= 75) {
            health = 'Good';
            badge = 'success';
          }else if (score_int >= 60 && score_int < 75) {
            health = 'Okay';
            badge = 'warning';
          }else {
            health = 'Poor';
            badge = 'danger';
          }
        }
        return <Badge type={badge}>{health}</Badge>;
      }



  return (
    <>
        <ChartCard title="Current Scores" classes={''}>
            <div className='grid gap-6 md:grid-cols-2 grid-cols-1 justify-items-center mt-12 mb-[6.5rem]'>
                <div className="text-center max-w-[10rem] max-h-[10rem] mb-6">
                    <Doughnut {...healthData} />
                    <div className="text-center mx-auto md:text-lg font-semibold" style={{ marginTop: '-55%', marginBottom: '50%',}}>{getScore(healthgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300" style={{ }}>Health - {getScoreStatus(healthgood)}</div>
                </div>
                <div className="text-center max-w-[10rem] max-h-[10rem] mb-6">
                    <Doughnut {...testData} />
                    <div className="text-center mx-auto md:text-lg font-semibold" style={{ marginTop: '-55%', marginBottom: '50%',}}>{getScore(testgood)}</div>
                    <div className="text-center mx-auto text-gray-600 dark:text-gray-300" style={{ }}>Test - {getScoreStatus(testgood)}</div>
                </div>
            </div>
          
        </ChartCard>
    </>
  )
}

export default PageScoresChart

